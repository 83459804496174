import { useState } from "react";
import { useRegisterOrganizationMutation } from "./organizationSlice";
import { useRegisterUserMutation } from "features/user/userSlice";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

function RegisterOrganization() {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = 3;
  const handleNext = () => {
    if (currentStep < steps - 1) {
      setCurrentStep(currentStep + 1);
    }
  };
  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  // Wiring organization and user registration
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [registerUser] = useRegisterUserMutation();
  const [registerOrganization] = useRegisterOrganizationMutation();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const organizationData = {
        company_name: data.organization_name,
        email: data.organization_email,
        address: data.address,
        city: data.city,
        state: data.state,
        zip_code: data.zip_code,
      };
      const organizationResult = await registerOrganization(organizationData);
      const organizationId = organizationResult.data.uuid;
      setValue("company_id", organizationId);
      const userData = {
        company_id: organizationId,
        email: data.user_email,
        password: data.password,
        first_name: data.first_name,
        last_name: data.last_name,
        primary_contact: "",
        secondary_contact: "",
      };
      await registerUser(userData);
      navigate("/");
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <div className='container mt-5'>
      <div className='step-indicator'>
        {Array.from({ length: steps }, (_, index) => (
          <div
            key={index}
            className={`step-circle ${currentStep >= index ? "active" : ""}`}
            onClick={() => setCurrentStep(index)}
          >
            {index + 1}
          </div>
        ))}
        <div className='step-line'></div>
      </div>
      <div className='card'>
        <div className='card-header text-center'>
          {currentStep === 0
            ? "Create Account"
            : currentStep === 1
              ? "Register Organization"
              : "Billing"}
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Step no. 1 */}
            {currentStep === 0 && (
              <div className='step active'>
                <div className='row mb-3'>
                  <div className='col-md-6'>
                    <label htmlFor='firstName' className='form-label required'>
                      First Name
                    </label>

                    <input
                      type='text'
                      className='form-control'
                      id='first_name'
                      {...register("first_name", {
                        required: "First name is required",
                      })}
                    />
                    {errors.first_name && <p>{errors.first_name.message}</p>}
                  </div>
                  <div className='col-md-6'>
                    <label htmlFor='lastName' className='form-label required'>
                      Last Name
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='last_name'
                      {...register("last_name", {
                        required: "Last name is required",
                      })}
                    />
                    {errors.last_name && <p>{errors.last_name.message}</p>}
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-12'>
                    <label htmlFor='user_email' className='form-label required'>
                      Email
                      <small className='form-text text-muted'>
                        {" "}
                        (Username)
                      </small>
                    </label>

                    <input
                      type='email'
                      className='form-control'
                      id='user_email'
                      {...register("user_email", {
                        required: "Email is required",
                      })}
                    />
                    {errors.user_email && <p>{errors.user_email.message}</p>}
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-md-6'>
                    <label htmlFor='password' className='form-label required'>
                      Please enter your password
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      id='password'
                      {...register("password", {
                        required: "Password is required",
                      })}
                    />
                    {errors.password && <p>{errors.password.message}</p>}
                  </div>
                  <div className='col-md-6'>
                    <label
                      htmlFor='newPassword'
                      className='form-label required'
                    >
                      Confirm Password
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      id='newPassword'
                      name='newPassword'
                      required
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Step no. 2 */}
            {currentStep === 1 && (
              <div className='step active'>
                <div className='row mb-3'>
                  <div className='col-md-6'>
                    <label
                      htmlFor='organizationName'
                      className='form-label required'
                    >
                      Organization Name
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='organization_name'
                      {...register("organization_name", {
                        required: "Organization name is required",
                      })}
                    />
                    {errors.company_name && (
                      <p>{errors.company_name.message}</p>
                    )}
                  </div>
                  <div className='col-md-6'>
                    <label
                      htmlFor='organizationEmail'
                      className='form-label required'
                    >
                      Organization Email
                    </label>
                    <input
                      type='email'
                      className='form-control'
                      id='organization_email'
                      {...register("organization_email", {
                        required: "Email is required",
                      })}
                    />
                    {errors.organization_email && (
                      <p>{errors.organization_email.message}</p>
                    )}
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-md-6'>
                    <label htmlFor='address1' className='form-label required'>
                      Address
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='address'
                      {...register("address", {
                        required: "Address is required",
                      })}
                    />
                    {errors.address && <p>{errors.address.message}</p>}
                  </div>

                  <div className='col-md-6'>
                    <label htmlFor='city' className='form-label required'>
                      City
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='city'
                      {...register("city", { required: "City is required" })}
                    />
                    {errors.city && <p>{errors.city.message}</p>}
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-md-6'>
                    <label htmlFor='state' className='form-label required'>
                      State
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='state'
                      {...register("state", { required: "State is required" })}
                    />
                    {errors.state && <p>{errors.state.message}</p>}
                  </div>
                  <div className='col-md-6'>
                    <label htmlFor='postalCode' className='form-label required'>
                      Postal Code
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='zip_code'
                      {...register("zip_code", {
                        required: "Zip code is required",
                      })}
                    />
                    {errors.zip_code && <p>{errors.zip_code.message}</p>}
                  </div>
                </div>
              </div>
            )}
            {/* Step no. 3 */}
            {currentStep === 2 && <div className='step active'> Billing </div>}
          </form>
          <div className='progress mt-4'>
            <div
              className='progress-bar bg-success'
              role='progressbar'
              style={{ width: `${((currentStep + 1) / steps) * 100}%` }}
              aria-valuenow={((currentStep + 1) / steps) * 100}
              aria-valuemin='0'
              aria-valuemax='100'
            ></div>
          </div>
          <div className='d-flex justify-content-between mt-3'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={handlePrev}
              disabled={currentStep === 0}
            >
              Previous
            </button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={
                currentStep < steps - 1 ? handleNext : handleSubmit(onSubmit)
              }
            >
              {currentStep < steps - 1 ? "Next" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RegisterOrganization;
