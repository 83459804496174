import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  useGetVendorClientsQuery,
  useAddClientMutation,
  useUpdateClientMutation,
} from "features/clients/clientSlice";
import { useGetVendorGoalsQuery } from "features/goals/goalsSlice";
import {
  useGetGoalInitiativesQuery,
  useGetVendorInitiativesQuery,
  useGetInitiativeQuery,
} from "features/initiatives/initiativesSlice";
import {
  useAddProjectMutation,
  useGetInitiativeProjectsQuery,
  useUpdateProjectMutation,
  useGetProjectQuery,
} from "features/projects/projectsSlice";
import {
  useAddImpactMutation,
  useUpdateImpactMutation,
  useGetImpactQuery,
  useAddGraphLabelMutation,
  useUpdateGraphLabelMutation,
  useGetGraphLabelQuery,
} from "features/impacts/impactsSlice";
import {
  useAddTaskMutation,
  useUpdateTaskMutation,
  useGetTaskQuery,
} from "features/tasks/tasksSlice";
import { useSelector } from "react-redux";
import { authData } from "features/auth/authSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetClientDetailsQuery } from "features/clients/clientSlice";
import { useGetUserListQuery } from "features/user/userSlice";
import { ErrorHandler, formValidation } from "common";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Country, State } from "country-state-city";
import { CancelDialog } from "common";

const FORM_STAGES = [
  { title: "Not Started", value: "Not-Started" },
  { title: "In Progress", value: "In-Progress" },
  { title: "Completed", value: "Completed" },
];

const FORM_STATUS = [
  { title: "Active", value: "Active" },
  { title: "Inactive", value: "In-Active" },
];

const TASK_STAGES = [
  { title: "Incomplete", value: "Incomplete" },
  { title: "Completed", value: "Completed" },
];

const FormHandler = ({
  formInputs,
  updateGoal,
  addGoal,
  goalData,
  initiativeData,
  addInitiative,
  updateInitiative,
}) => {
  let auth = useSelector(authData).userData;
  const [modalShow, setModalShow] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [countries, setCountries] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [isStates, setIsState] = useState(false);
  const [showSuccessPrompt, setShowSuccessPrompt] = useState(false);
  const navigate = useNavigate();
  let vendorAccounts = [];
  let goalsByAccount = [];
  let userList = [];
  let initiativeByGoal = [];
  let initiativeByAccount = [];
  let projectsByInitiative = [];
  const isClientForm = formInputs.formType === "Client";
  const isImpactForm = formInputs.formType === "Impact";
  const clientDet = useGetClientDetailsQuery(
    auth && auth.organization ? auth.organization : skipToken,
    {
      refetchOnMountOrArgChange: 1,
    },
  );
  let goalByInitId;

  const initialValues = {
    form_title: "",
    form_description: "",
    form_status: "",
    form_stage: "",
    form_owner_customer: null,
    form_client_account: null,
    form_owner_consultant: "",
    form_start_date: null,
    form_end_date: null,
    form_goal: "",
    form_initiative: "",
    form_project: "",
  };
  const labelInitialValues = {
    form_label_x_axis: "",
    form_label_y_axis: "",
  };

  const goalInitialValues = {
    form_goal_team: null,
    form_allocated_budget: null,
    form_spend_budget: null,
  };

  const impactInitialValues = {
    form_title: "",
    form_description: "",
    form_status: "Active",
    form_stage: "Not-Started",
    form_owner_customer: null,
    form_client_account: null,
    form_owner_consultant: "",
    form_start_date: null,
    form_end_date: null,
    form_goal: "",
    form_initiative: "",
    form_overall_target_value: null,
    form_tracking_frequency: "",
    form_target_direction: "Increase",
    form_impact_type: "Goal",
  };

  const clientInitialValues = {
    form_name: "",
    form_account_type: null,
    form_website: null,
    form_invitation_url: "https://www.a-gassociates.com/",
    form_start_date: null,
    form_end_date: null,
    form_address: null,
    form_city: null,
    form_state: null,
    form_country: "US",
    form_zipcode: null,
    form_contract_statement: null,
    form_primary_contact: null,
    form_primary_contact_title: null,
    form_primary_contact_phoneNumber: null,
    form_primary_contact_email: null,
    form_secondary_contact: null,
    form_secondary_contact_title: null,
    form_secondary_contact_phoneNumber: null,
    form_secondary_contact_email: null,
    form_contact: null,
    form_primary_consultant: null,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [goalFormValues, setGoalFormValues] = useState(goalInitialValues);
  const [impactFormValues, setImpactFormValues] = useState(impactInitialValues);
  const [clientFormValues, setClientFormValues] = useState(clientInitialValues);
  const [phoneNumberDisplayValues, setPhoneNumberDisplayValues] = useState("");
  const [labelValues, setLabelValues] = useState(labelInitialValues);
  const [fieldChanged, setFieldChanged] = useState(false);

  const [formErrors, setFormErrors] = useState({});
  const [cancelModel, setCancelModel] = useState(false);
  const CANCEL_DIALOG_TITLE = "Client";
  const CANCEL_DIALOG_MESSAGE = `Are you sure you want to cancel? Any unsaved changes will be lost.`;

  const handleVendorClients = useGetVendorClientsQuery(
    auth && auth.organization ? auth.organization : skipToken,
    {
      refetchOnMountOrArgChange: 1,
    },
  );

  if (handleVendorClients.isSuccess === true) {
    vendorAccounts = handleVendorClients.data.data;
  }
  const handleUserList = useGetUserListQuery({
    refetchOnMountOrArgChange: true,
  });

  if (handleUserList.isSuccess === true) {
    userList = handleUserList.data.data;
  }
  let clientId;
  if (formValues.form_client_account) {
    clientId = formValues.form_client_account;
  } else if (impactFormValues.form_client_account) {
    clientId = impactFormValues.form_client_account;
  }

  const getGoalsByAccount = useGetVendorGoalsQuery(
    clientId ? clientId : skipToken,
    {
      refetchOnMountOrArgChange: 1,
    },
  );

  if (getGoalsByAccount.isSuccess === true) {
    goalsByAccount = getGoalsByAccount.data.data;
  }

  const handleInitiatives = useGetGoalInitiativesQuery(
    formValues.form_goal ? formValues.form_goal : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (handleInitiatives.isSuccess === true) {
    initiativeByGoal = handleInitiatives.data.data;
  }
  const handleInitiativesByAccount = useGetVendorInitiativesQuery(
    impactFormValues.form_impact_type === "Initiative"
      ? impactFormValues.form_client_account
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  if (handleInitiativesByAccount.isSuccess === true) {
    initiativeByAccount = handleInitiativesByAccount.data.data;
  }

  const getGoalByInitiative = useGetInitiativeQuery(
    impactFormValues.form_impact_type === "Initiative"
      ? impactFormValues.form_initiative
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  if (getGoalByInitiative.isSuccess === true) {
    goalByInitId = getGoalByInitiative.data.data.goal;
  }

  const handleProjects = useGetInitiativeProjectsQuery(
    formValues.form_initiative ? formValues.form_initiative : skipToken,
    {
      skipToken,
    },
  );

  if (handleProjects.isSuccess === true) {
    projectsByInitiative = handleProjects.data.data;
  }
  const cancelDialog = () => {
    setCancelModel(true);
  };
  const hideCancelDialog = () => {
    setCancelModel(false);
  };
  const cancelHandle = () => {
    setFormErrors({});
    setFormValues(initialValues);
    setGoalFormValues(goalInitialValues);
    setImpactFormValues(impactInitialValues);
    setLabelValues(labelInitialValues);
    setClientFormValues(clientInitialValues);
    setPhoneNumberDisplayValues("");
    setModalShow(false);
    setCancelModel(false);
  };

  const onHide = () => {
    setFormErrors({});
    setFormValues(initialValues);
    setGoalFormValues(goalInitialValues);
    setImpactFormValues(impactInitialValues);
    setLabelValues(labelInitialValues);
    setClientFormValues(clientInitialValues);
    setPhoneNumberDisplayValues("");
    setModalShow(false);
  };
  const handleAddClick = () => {
    setModalShow(!modalShow);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    const newValue = value === "No_initiative" ? null : value;
    setFormValues({ ...formValues, [id]: newValue });
  };

  const handleGoalFormChanges = (e) => {
    const { id, value } = e.target;
    setGoalFormValues({ ...goalFormValues, [id]: value });
  };

  const handleImpactFormChanges = (e) => {
    const { id, value } = e.target;
    if (id === "form_tracking_frequency") {
      setFieldChanged(true);
    }

    if (impactFormValues.form_impact_type === "Goal") {
      impactFormValues.form_initiative = "";
    }
    if (impactFormValues.form_impact_type === "Initiative") {
      impactFormValues.form_goal = goalByInitId;
    }

    setImpactFormValues({ ...impactFormValues, [id]: value });
    setLabelValues({ ...labelValues, [id]: value });
  };

  const handleClientFormChanges = (e) => {
    const { id, value } = e.target;
    if (
      id === "form_primary_contact_phoneNumber" ||
      id === "form_secondary_contact_phoneNumber"
    ) {
      const numericValue = value.replace(/[^0-9]/g, "");
      const phoneRegex = /^(\d{3})(\d{3})(\d{4})$/;
      if (numericValue.length === 10 && phoneRegex.test(numericValue)) {
        setPhoneNumberDisplayValues((prevState) => ({
          ...prevState,
          [id]: numericValue.replace(phoneRegex, "$1-$2-$3"),
        }));
      } else {
        setPhoneNumberDisplayValues((prevState) => ({
          ...prevState,
          [id]: numericValue,
        }));
      }
    }

    if (id === "form_country") {
      setStatesList(State?.getStatesOfCountry(value));
      setIsState(true);
    }

    setClientFormValues({ ...clientFormValues, [id]: value });
  };

  const [addProject] = useAddProjectMutation({
    refetchOnMountOrArgChange: true,
  });

  const [addTask] = useAddTaskMutation({
    refetchOnMountOrArgChange: true,
  });

  const [addImpact] = useAddImpactMutation({
    refetchOnMountOrArgChange: true,
  });
  const [addGraphLabels] = useAddGraphLabelMutation({
    refetchOnMountOrArgChange: true,
  });

  const [updateGraphLabels] = useUpdateGraphLabelMutation({
    refetchOnMountOrArgChange: true,
  });

  const [addClient] = useAddClientMutation({
    refetchOnMountOrArgChange: true,
  });

  const [updateProject] = useUpdateProjectMutation({
    refetchOnMountOrArgChange: true,
  });

  const [updateTask] = useUpdateTaskMutation({
    refetchOnMountOrArgChange: true,
  });

  const [updateClient] = useUpdateClientMutation({
    refetchOnMountOrArgChange: true,
  });

  const [updateImpact] = useUpdateImpactMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    let body;
    let errors;
    let fin_body = {};
    let form_Values = {
      ...formValues,
      form_vendor_account: parseInt(formValues.form_client_account),
      form_owner_consultant: parseInt(formValues.form_owner_consultant),
      form_start_date: formValues.form_start_date
        ? formValues.form_start_date
        : null,
      form_end_date: formValues.form_end_date ? formValues.form_end_date : null,
    };

    let userDet = {
      form_account: auth.organization,
      form_created_by: auth.id,
    };
    if (formInputs.formType === "Goal") {
      let goal_FormValues = {
        ...goalFormValues,
        form_allocated_budget: goalFormValues.form_allocated_budget
          ? parseFloat(goalFormValues.form_allocated_budget).toFixed(2)
          : goalFormValues.form_allocated_budget,
        form_spend_budget: goalFormValues.form_spend_budget
          ? parseFloat(goalFormValues.form_spend_budget).toFixed(2)
          : goalFormValues.form_spend_budget,
      };
      body = {
        ...userDet,
        ...form_Values,
        ...goal_FormValues,
      };
    }
    if (formInputs.formType === "Initiative") {
      body = {
        ...userDet,
        ...form_Values,
      };
      body["form_spend_budget"] = goalFormValues.form_spend_budget;
    }
    if (formInputs.formType === "Projects") {
      body = {
        ...userDet,
        ...form_Values,
      };
    }
    if (formInputs.formType === "Task") {
      body = {
        ...userDet,
        ...form_Values,
      };
    }

    if (formInputs.formType === "Impact") {
      let impact_formValues = {
        ...impactFormValues,
        form_overall_target_value: impactFormValues.form_overall_target_value
          ? parseFloat(impactFormValues.form_overall_target_value).toFixed(2)
          : impactFormValues.form_overall_target_value,
        form_target_direction: impactFormValues.form_target_direction,
        form_tracking_frequency: impactFormValues.form_tracking_frequency,
        // form_impact_type: impactFormValues.form_impact_type,
        form_vendor_account: parseInt(impactFormValues.form_client_account),
        form_owner_consultant: parseInt(impactFormValues.form_owner_consultant),
        form_start_date: impactFormValues.form_start_date
          ? impactFormValues.form_start_date
          : null,
        form_end_date: impactFormValues.form_end_date
          ? impactFormValues.form_end_date
          : null,
      };
      let label_formValues = {
        ...labelValues,
        form_label_x_axis: labelValues.form_label_x_axis,
        form_label_y_axis: labelValues.form_label_y_axis,
      };
      body = {
        ...userDet,
        ...impact_formValues,
        ...label_formValues,
      };
    }

    if (formInputs.formType === "Client") {
      body = {
        ...clientFormValues,
        form_vendor_account: auth.organization,
      };
    }

    Object.entries(body).forEach((i) => {
      const [key, value] = i;
      fin_body[key.split("form_")[1]] = value;
    });
    if (formInputs.formType === "Client") {
      errors = formValidation(
        formValues,
        goalFormValues,
        impactFormValues,
        labelValues,
        clientFormValues,
        formInputs.formType,
      );
    } else if (formInputs.formType === "Impact") {
      errors = formValidation(
        formValues,
        goalFormValues,
        impactFormValues,
        labelValues,
        clientFormValues,
        formInputs.formType,
      );
    } else {
      errors = formValidation(
        formValues,
        goalFormValues,
        impactFormValues,
        labelValues,
        clientFormValues,
        formInputs.formType,
      );
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      let result;
      if (formInputs.formType === "Goal") {
        if (formInputs.formAction === "Add") {
          try {
            result = await addGoal(fin_body);
            if (result.error) {
              setApiError(result);
            } else {
              toast.success("Goal added successfully.");
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
        if (formInputs.formAction === "Edit") {
          delete fin_body["created_by"];
          delete fin_body["account"];
          fin_body["modified_by"] = auth.id;
          try {
            result = await updateGoal({
              goalId: formInputs.itemDetails.id,
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            } else {
              toast.success("Goal updateed successfully.");
            }
          } catch (error) {
            setApiError("An error occuured");
          }
        }
      }

      if (formInputs.formType === "Initiative") {
        if (formInputs.formAction === "Add") {
          try {
            result = await addInitiative({
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            } else {
              toast.success("Initiative added successfully.");
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
        if (formInputs.formAction === "Edit") {
          delete fin_body["created_by"];
          delete fin_body["account"];
          fin_body["modified_by"] = auth.id;
          try {
            result = await updateInitiative({
              initiativeId: formInputs.itemDetails.id,
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            } else {
              toast.success("Initiative updateed successfully.");
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
      }

      if (formInputs.formType === "Projects") {
        if (formInputs.formAction === "Add") {
          try {
            result = await addProject({
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            } else {
              toast.success("Project added successfully.");
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
        if (formInputs.formAction === "Edit") {
          delete fin_body["created_by"];
          delete fin_body["account"];
          fin_body["modified_by"] = auth.id;
          try {
            result = await updateProject({
              projectId: formInputs.itemDetails.id,
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            } else {
              toast.success("Project updated successfully.");
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
      }

      if (formInputs.formType === "Task") {
        if (formInputs.formAction === "Add") {
          try {
            result = await addTask({
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            } else {
              toast.success("Task added successfully.");
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
        if (formInputs.formAction === "Edit") {
          delete fin_body["created_by"];
          delete fin_body["account"];
          fin_body["modified_by"] = auth.id;
          try {
            result = await updateTask({
              taskId: formInputs.itemDetails.id,
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            } else {
              toast.success("Task updated successfully.");
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
      }

      if (formInputs.formType === "Impact") {
        if (formInputs.formAction === "Add") {
          try {
            fin_body["stage"] = "Not-Started";
            fin_body["status"] = "Active";
            if (fin_body["initiative"] === "") {
              fin_body["initiative"] = null;
            }

            let { label_x_axis, label_y_axis, ...impact_data } = fin_body;
            result = await addImpact({
              body: impact_data,
            });

            if (result.error) {
              setApiError(result);
              return;
            }
            const impact_Id = result?.data?.data?.id;

            if (result.data.status === "success") {
              navigate(`/impacts/${impact_Id}`);
            }

            if (fin_body.label_x_axis === "") {
              label_x_axis = fin_body.tracking_frequency;
            } else label_x_axis = fin_body.label_x_axis;
            const graphLabelsBody = {
              label_x_axis: label_x_axis,
              label_y_axis: fin_body.label_y_axis,
              impact: impact_Id,
              graph_name: "Health Check",
            };
            const graphLabelResult = await addGraphLabels({
              body: graphLabelsBody,
            });
            if (graphLabelResult.error) {
              setApiError(
                "Impact data added, but there was an error adding the graph labels. Please review and try again",
              );
            } else {
              // set("")
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
        if (formInputs.formAction === "Edit") {
          delete fin_body["created_by"];
          delete fin_body["account"];

          fin_body["modified_by"] = auth.id;
          if (fin_body["initiative"] === "No_initiative") {
            fin_body["initiative"] = null;
          }
          let { label_x_axis, label_y_axis, ...impact_data } = fin_body;

          try {
            result = await updateImpact({
              impactId: formInputs.itemDetails.id,
              body: impact_data,
            });

            if (result.error) {
              setApiError(result);
            }
            if (fin_body.label_x_axis === "") {
              label_x_axis = fin_body.tracking_frequency;
            } else label_x_axis = fin_body.label_x_axis;
            const graphLabelsBody = {
              label_x_axis: label_x_axis,
              label_y_axis: fin_body.label_y_axis,
            };
            let graphLabelUpdate = "";
            if (labelValues?.form_label_id) {
              graphLabelUpdate = await updateGraphLabels({
                body: graphLabelsBody,
                graphLabelId: labelValues.form_label_id,
              });
            } else {
              graphLabelUpdate = await addGraphLabels({
                body: {
                  ...graphLabelsBody,
                  impact: formInputs.itemDetails.id,
                  graph_name: "Health Check",
                },
              });
            }
            if (graphLabelUpdate.error) {
              setApiError(
                "Impact data added, but there was an error adding the graph labels. Please review and try again",
              );
            } else {
              // set("")
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
      }

      if (formInputs.formType === "Client") {
        if (formInputs.formAction === "Add") {
          try {
            result = await addClient({
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            } else {
              toast.success("Client added successfully.");
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
        if (formInputs.formAction === "Edit") {
          fin_body["modified_by"] = auth.id;
          try {
            result = await updateClient({
              clientId: formInputs.itemDetails.id,
              body: fin_body,
            });
            if (result.error) {
              setApiError(result);
            } else {
              toast.success("Client updated successfully.");
            }
          } catch (error) {
            setApiError("An error occurred");
          }
        }
      }

      if (result.data?.status === "success") {
        onHide();
      }
    }
  };

  useEffect(() => {
    let timer;
    if (showSuccessPrompt) {
      timer = setTimeout(() => {
        setShowSuccessPrompt(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showSuccessPrompt]);

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  useEffect(() => {
    if (fieldChanged) {
      setLabelValues((prevLabelValues) => ({
        ...prevLabelValues,
        form_label_x_axis: "",
      }));
    }
    setFieldChanged(false);
  }, [fieldChanged]);

  const getProjectData = useGetProjectQuery(
    formInputs.formType === "Projects" &&
      formInputs.itemDetails &&
      formInputs.itemDetails.id
      ? formInputs.itemDetails.id
      : skipToken,
  );

  const getTaskData = useGetTaskQuery(
    formInputs.formType === "Task" &&
      formInputs.itemDetails &&
      formInputs.itemDetails.id
      ? formInputs.itemDetails.id
      : skipToken,
  );

  const getImpactData = useGetImpactQuery(
    formInputs.formType === "Impact" &&
      formInputs.itemDetails &&
      formInputs.itemDetails.id
      ? formInputs.itemDetails.id
      : skipToken,
  );
  const getLabelsData = useGetGraphLabelQuery(
    formInputs.formType === "Impact" &&
      formInputs.itemDetails &&
      formInputs.itemDetails.id
      ? formInputs.itemDetails.id
      : skipToken,
  );

  const getClientData = useGetClientDetailsQuery(
    formInputs.formType === "Client" &&
      formInputs.itemDetails &&
      formInputs.itemDetails.id
      ? formInputs.itemDetails.id
      : skipToken,
  );

  const handleEditClick = async () => {
    setModalShow(modalShow ? false : true);

    if (
      goalData ||
      initiativeData ||
      getProjectData.isSuccess ||
      getTaskData.isSuccess ||
      getImpactData.isSuccess ||
      getLabelsData.isSuccess ||
      getClientData.isSuccess
    ) {
      let item_data, label_data;
      if (formInputs.formType !== "Client") {
        if (formInputs.formType === "Goal") {
          item_data = goalData;
          let goal_value = {
            form_goal_team: item_data.goal_team,
            form_allocated_budget: item_data.allocated_budget,
            form_spend_budget: item_data.spend_budget,
          };
          setGoalFormValues(goal_value);
        }
        if (formInputs.formType === "Initiative") {
          item_data = initiativeData;
          setGoalFormValues((prev) => ({
            ...prev,
            form_spend_budget: initiativeData?.spend_budget,
          }));
        }
        if (formInputs.formType === "Projects") {
          item_data = getProjectData.data.data;
        }
        if (formInputs.formType === "Impact") {
          item_data = getImpactData.data.data;
          let impact_type;
          if (item_data.initiative === null) {
            impact_type = "Goal";
          } else {
            impact_type = "Initiative";
          }
          let impact_values = {
            form_title: item_data.title,
            form_description: item_data.description,
            form_start_date: item_data.start_date,
            form_end_date: item_data.end_date,
            form_overall_target_value: item_data.overall_target_value,
            form_tracking_frequency: item_data.tracking_frequency,
            form_target_direction: item_data.target_direction,
            form_owner_consultant: item_data.owner_consultant,
            form_client_account: item_data.vendor_account,
            form_initiative: item_data.initiative,
            form_goal: item_data.goal,
            form_impact_type: impact_type,
          };

          let label_values;
          setImpactFormValues(impact_values);
          if (getLabelsData.data) {
            label_data = getLabelsData.data.data;
            label_values = {
              form_label_x_axis: label_data[0].label_x_axis || "",
              form_label_y_axis: label_data[0].label_y_axis || "",
              form_label_id: label_data[0].id,
            };
          } else {
            label_values = {
              form_label_x_axis: "",
              form_label_y_axis: "",
              form_label_id: null,
            };
          }

          setLabelValues(label_values);
        }
        if (formInputs.formType === "Task") {
          item_data = getTaskData.data.data;
        }
        let values = {
          form_title: item_data.title,
          form_description: item_data.description,
          form_status: item_data.status,
          form_stage: item_data.stage,
          form_owner_customer: item_data.owner_customer,
          form_client_account: item_data.vendor_account,
          form_owner_consultant: item_data.owner_consultant,
          form_start_date: item_data.start_date,
          form_end_date: item_data.end_date,
          form_goal: item_data.goal,
          form_initiative: item_data.initiative,
          form_project: item_data.project,
        };
        if (values.form_initiative === null) {
          values.form_initiative = "No_initiative";
        }
        setFormValues(values);
      } else {
        item_data = getClientData.data.data;
        if (item_data?.state) {
          setStatesList(State.getStatesOfCountry(item_data.country));
          setIsState(true);
        }
        let client_value = {
          form_name: item_data.name,
          form_account_type: item_data.account_type,
          form_website: item_data.website,
          form_phoneNumber: item_data.phoneNumber,
          form_invitation_url: item_data.invitation_url,
          form_address: item_data.address,
          form_city: item_data.city,
          form_state: item_data.state,
          form_country: item_data.country,
          form_zipcode: item_data.zipcode,
          form_email: item_data.email,
          form_primary_contact: item_data.primary_contact,
          form_primary_consultant: item_data.primary_consultant,
          form_start_date: item_data.start_date,
          form_end_date: item_data.end_date,
          form_contract_statement: item_data.contract_statement,
          form_primary_contact_title: item_data.primary_contact_title,
          form_primary_contact_email: item_data.primary_contact_email,
          form_primary_contact_phoneNumber:
            item_data.primary_contact_phoneNumber,
          form_secondary_contact: item_data.secondary_contact,
          form_secondary_contact_title: item_data.secondary_contact_title,
          form_secondary_contact_email: item_data.secondary_contact_email,
          form_secondary_contact_phoneNumber:
            item_data.secondary_contact_phoneNumber,
        };
        setClientFormValues(client_value);
        if (client_value.form_primary_contact_phoneNumber) {
          let id = "form_primary_contact_phoneNumber";
          const phoneValue =
            client_value.form_primary_contact_phoneNumber.replace(
              /^(\d{3})(\d{3})(\d{4})$/,
              "$1-$2-$3",
            );
          setPhoneNumberDisplayValues((prevState) => ({
            ...prevState,
            [id]: phoneValue,
          }));
        }
        if (client_value.form_secondary_contact_phoneNumber) {
          let id = "form_secondary_contact_phoneNumber";
          const phoneValue =
            client_value.form_secondary_contact_phoneNumber.replace(
              /^(\d{3})(\d{3})(\d{4})$/,
              "$1-$2-$3",
            );
          setPhoneNumberDisplayValues((prevState) => ({
            ...prevState,
            [id]: phoneValue,
          }));
        }
      }
    }
  };

  const onClose = () => {
    setApiError(null);
  };

  useEffect(() => {
    setStatesList(State?.getStatesOfCountry("US"));
    setIsState(true);
  }, [formErrors]);

  const formContent = () => {
    return (
      <>
        <form className='row mb-2' id='commonForm'>
          {!isClientForm && !isImpactForm && (
            <>
              <div className='form-group col-12 mb-3'>
                <input
                  type='text'
                  id='form_title'
                  placeholder='Title'
                  value={formValues.form_title}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Title'
                  onChange={handleChange}
                  className={`form-control ${
                    formErrors.form_title ? "is-invalid" : ""
                  }`}
                />
                {formErrors?.form_title && (
                  <div className='invalid-feedback'>
                    {formErrors.form_title}
                  </div>
                )}
              </div>
              <div className='form-group col-12 mb-3'>
                <textarea
                  className={`form-control ${
                    formErrors.form_description ? "is-invalid" : ""
                  }`}
                  id='form_description'
                  rows='3'
                  placeholder='Description'
                  value={formValues.form_description}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Description'
                  onChange={handleChange}
                ></textarea>
                {formErrors?.form_description && (
                  <div className='invalid-feedback'>
                    {formErrors.form_description}
                  </div>
                )}
              </div>
              <div className='col-6 mb-3'>
                <Form.Group controlId='duedate'></Form.Group>
                <input
                  type='date'
                  className={`form-control ${
                    formErrors.form_start_date ? "is-invalid" : ""
                  }`}
                  placeholder='Select Start Date'
                  id='form_start_date'
                  value={formValues.form_start_date}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Start Date'
                  onChange={handleChange}
                  onFocus={() => {
                    let start_date = document.getElementById("form_start_date");
                    start_date.type = "date";
                  }}
                  onBlur={() => {
                    let start_date = document.getElementById("form_start_date");
                    start_date.type = "date";
                  }}
                />
                {formErrors?.form_start_date && (
                  <div className='invalid-feedback'>
                    {formErrors.form_start_date}
                  </div>
                )}
              </div>
              <div className='col-6 mb-3'>
                <input
                  type='date'
                  className={`form-control ${
                    formErrors.form_end_date ? "is-invalid" : ""
                  }`}
                  id='form_end_date'
                  value={formValues.form_end_date}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='End Date'
                  onChange={handleChange}
                  min={
                    formValues.form_start_date
                      ? formValues.form_start_date
                      : null
                  }
                  placeholder='Select End Date'
                  onFocus={() => {
                    let start_date = document.getElementById("form_end_date");
                    start_date.type = "date";
                  }}
                  onBlur={() => {
                    let start_date = document.getElementById("form_end_date");
                    start_date.type = "date";
                  }}
                />

                {formErrors?.form_end_date && (
                  <div className='invalid-feedback'>
                    {formErrors.form_end_date}
                  </div>
                )}
              </div>
              <div className='col-6 mb-3'>
                <select
                  id='form_stage'
                  value={formValues.form_stage}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Stage'
                  onChange={handleChange}
                  className={`form-select ${
                    formErrors.form_stage ? "is-invalid" : ""
                  }`}
                  required
                >
                  <option selected value=''>
                    {"Select " + formInputs.formType + " Stage"}
                  </option>
                  {formInputs.formType !== "Task"
                    ? FORM_STAGES.map((goal) => (
                        <option value={goal.value}>{goal.title}</option>
                      ))
                    : null}
                  {formInputs.formType === "Task"
                    ? TASK_STAGES.map((goal) => (
                        <option value={goal.value}>{goal.title}</option>
                      ))
                    : null}
                </select>
                {formErrors?.form_stage && (
                  <div className='invalid-feedback'>
                    {formErrors.form_stage}
                  </div>
                )}
              </div>
              <div className='col-6 mb-3'>
                <select
                  className={`form-select ${
                    formErrors.form_status ? "is-invalid" : ""
                  }`}
                  id='form_status'
                  value={formValues.form_status}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Status'
                  onChange={handleChange}
                  required
                >
                  <option selected value=''>
                    {"Select " + formInputs.formType + " Status"}
                  </option>
                  {FORM_STATUS.map((goal) => (
                    <option value={goal.value}>{goal.title}</option>
                  ))}
                </select>
                {formErrors?.form_status && (
                  <div className='invalid-feedback'>
                    {formErrors.form_status}
                  </div>
                )}
              </div>
              <div className='col-6 mb-3'>
                <select
                  className={`form-select ${
                    formErrors.form_client_account ? "is-invalid" : ""
                  }`}
                  id='form_client_account'
                  value={formValues.form_client_account}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Client Account'
                  onChange={handleChange}
                >
                  <option selected value=''>
                    Select Client Account
                  </option>
                  {vendorAccounts.length > 0 &&
                    vendorAccounts.map((vac) => (
                      <option value={vac.id}>{vac.name}</option>
                    ))}
                </select>
                {formErrors?.form_client_account && (
                  <div className='invalid-feedback'>
                    {formErrors.form_client_account}
                  </div>
                )}
              </div>
              <div className='col-6 mb-3'>
                <select
                  className={`form-select ${
                    formErrors.form_owner_consultant ? "is-invalid" : ""
                  }`}
                  id='form_owner_consultant'
                  value={formValues.form_owner_consultant}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Owner Consultant'
                  onChange={handleChange}
                  required
                >
                  <option selected value=''>
                    Select Owner Consultant
                  </option>
                  {userList.length > 0 &&
                    userList.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.first_name} {user.last_name}
                        {user.id === auth.id ? " (Current)" : ""}
                      </option>
                    ))}
                </select>
                {formErrors?.form_owner_consultant && (
                  <div className='invalid-feedback'>
                    {formErrors.form_owner_consultant}
                  </div>
                )}
              </div>
              {goalFormContents}
              <div
                className='col-6 mb-3'
                hidden={
                  !["Initiative", "Projects", "Impact", "Task"].includes(
                    formInputs.formType,
                  )
                }
              >
                <select
                  className={`form-select ${
                    formErrors.form_goal ? "is-invalid" : ""
                  }`}
                  id='form_goal'
                  disabled={!formValues.form_client_account}
                  value={formValues.form_goal}
                  onChange={handleChange}
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Goal'
                >
                  <option selected value=''>
                    Select Goal
                  </option>
                  {goalsByAccount &&
                    goalsByAccount.map((goal) => (
                      <option className='' value={goal.id}>
                        <small>{goal.title}</small>
                      </option>
                    ))}
                </select>
                {formErrors?.form_goal && (
                  <div className='invalid-feedback'>{formErrors.form_goal}</div>
                )}
              </div>
              <div
                className='col-6 mb-3'
                hidden={
                  !["Projects", "Impact", "Task"].includes(formInputs.formType)
                }
              >
                <select
                  className='form-select'
                  id='form_initiative'
                  disabled={!formValues.form_goal}
                  value={formValues.form_initiative}
                  onChange={handleChange}
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Initiative'
                >
                  <option value='' selected>
                    Select Initiative
                  </option>
                  {initiativeByGoal.map((init) => (
                    <option value={init.id}>{init.title}</option>
                  ))}
                  {/* <option value='No_initiative'>No Initiative</option> */}
                </select>
              </div>
              <div
                className='col-6 mb-3'
                hidden={!["Task"].includes(formInputs.formType)}
              >
                <select
                  className='form-select'
                  disabled={
                    !formValues.form_goal || !formValues.form_initiative
                  }
                  id='form_project'
                  value={formValues.form_project}
                  onChange={handleChange}
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Project'
                >
                  <option value='' selected>
                    Select Projects
                  </option>
                  {projectsByInitiative.map((project) => (
                    <option value={project.id}>{project.title}</option>
                  ))}
                </select>
              </div>
            </>
          )}
          {isClientForm && clientFormContents}
          {isImpactForm && impactFormContents}
          <div className='col-12 mt-3 d-flex justify-content-end'>
            <div className='col-6 d-inline-flex ps-3 pe-1'>
              <Button
                className='w-50 me-2'
                variant={"outline-secondary"}
                onClick={() => {
                  cancelDialog();
                }}
              >
                Cancel
              </Button>
              <Button
                className='w-50'
                variant={"primary"}
                onClick={(event) => handleSubmit(event)}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
        {cancelModel ? (
          <div className='cancel-modal'>
            <CancelDialog
              dialogTitle={CANCEL_DIALOG_TITLE}
              descriptionText={CANCEL_DIALOG_MESSAGE}
              handleCancel={() => cancelHandle()}
              hide={() => hideCancelDialog()}
            />
          </div>
        ) : null}
      </>
    );
  };

  const goalFormContents = (
    <>
      <div
        className='col-6 mb-3'
        hidden={!["Goal"].includes(formInputs.formType)}
      >
        <div className='input-group'>
          <div className='input-group-prepend'>
            <div className='input-group-text'>$</div>
          </div>
          <input
            type='number'
            className={`form-control ${
              formErrors.form_allocated_budget ? "is-invalid" : ""
            }`}
            id='form_allocated_budget'
            placeholder='Allocated Budget'
            value={goalFormValues.form_allocated_budget}
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Allocated Budget'
            onChange={handleGoalFormChanges}
          />

          {formErrors?.form_allocated_budget && (
            <div className='invalid-feedback'>
              {formErrors.form_allocated_budget}
            </div>
          )}
        </div>
      </div>
      <div
        className='col-6 mb-3'
        hidden={!["Goal", "Initiative"].includes(formInputs.formType)}
      >
        <div className='input-group'>
          <div className='input-group-prepend'>
            <div className='input-group-text'>$</div>
          </div>
          <input
            type='number'
            className={`form-control ${
              formErrors.form_spend_budget ? "is-invalid" : ""
            }`}
            id='form_spend_budget'
            placeholder='Spend Budget'
            value={goalFormValues.form_spend_budget}
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='The field will be auto populated based on the spend budgets of associated initiatives'
            onChange={handleGoalFormChanges}
            disabled={formInputs.formType === "Goal" ? true : false}
          />
          {formErrors?.form_spend_budget && (
            <div className='invalid-feedback'>
              {formErrors.form_spend_budget}
            </div>
          )}
        </div>
      </div>
    </>
  );

  const impactFormContents = (
    <>
      <div className='text-divider'>Relationship </div>

      <div className='col-6 mb-3'>
        <select
          className={`form-select ${
            formErrors.form_client_account ? "is-invalid" : ""
          }`}
          id='form_client_account'
          value={impactFormValues.form_client_account}
          onChange={handleImpactFormChanges}
          data-toggle='tooltip'
          data-placement='top'
          title='Client Account'
        >
          <option selected value=''>
            Select Client Account
          </option>
          {vendorAccounts.length > 0 &&
            vendorAccounts.map((vac) => (
              <option value={vac.id}>{vac.name}</option>
            ))}
        </select>
        {formErrors?.form_client_account && (
          <div className='invalid-feedback'>
            {formErrors.form_client_account}
          </div>
        )}
      </div>
      <div className='col-6 mb-3'>
        <select
          className={`form-select ${
            formErrors.form_owner_consultant ? "is-invalid" : ""
          }`}
          id='form_owner_consultant'
          value={impactFormValues.form_owner_consultant}
          onChange={handleImpactFormChanges}
          required
          data-toggle='tooltip'
          data-placement='top'
          title='Owner Consultant'
        >
          <option selected value=''>
            Select Owner Consultant
          </option>
          {userList.length > 0 &&
            userList.map((user) => (
              <option key={user.id} value={user.id}>
                {user.first_name} {user.last_name}
                {user.id === auth.id ? " (Current)" : ""}
              </option>
            ))}
        </select>
        {formErrors?.form_owner_consultant && (
          <div className='invalid-feedback'>
            {formErrors.form_owner_consultant}
          </div>
        )}
      </div>
      <div className='form-group col-6 mb-3'>
        <label>Impact Type</label>
        <div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              name='impactType'
              id='goal'
              value='Goal'
              checked={impactFormValues.form_impact_type === "Goal"}
              onChange={() =>
                handleImpactFormChanges({
                  target: { id: "form_impact_type", value: "Goal" },
                })
              }
            />
            <label className='form-check-label' htmlFor='goal'>
              Goal <span className='recommended'>(*Recommended)</span>
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              name='impactType'
              id='initiative'
              value='Initiative'
              checked={impactFormValues.form_impact_type === "Initiative"}
              onChange={() =>
                handleImpactFormChanges({
                  target: { id: "form_impact_type", value: "Initiative" },
                })
              }
            />
            <label className='form-check-label' htmlFor='initiative'>
              Initiative
            </label>
          </div>
        </div>
      </div>
      {impactFormValues.form_impact_type === "Goal" ? (
        <div className='col-6 mb-3'>
          <select
            className={`form-select ${formErrors.form_goal ? "is-invalid" : ""}`}
            id='form_goal'
            disabled={!impactFormValues.form_client_account}
            value={impactFormValues.form_goal}
            onChange={handleImpactFormChanges}
            data-toggle='tooltip'
            data-placement='top'
            title='Goal'
          >
            <option selected value=''>
              Select Goal
            </option>
            {goalsByAccount &&
              goalsByAccount.map((goal) => (
                <option className='' value={goal.id}>
                  <small>{goal.title}</small>
                </option>
              ))}
          </select>
          {formErrors?.form_goal && (
            <div className='invalid-feedback'>{formErrors.form_goal}</div>
          )}
        </div>
      ) : (
        <div className='col-6 mb-3'>
          <select
            className={`form-select ${formErrors.form_initiative ? "is-invalid" : ""}`}
            id='form_initiative'
            disabled={!impactFormValues.form_client_account}
            value={impactFormValues.form_initiative}
            onChange={handleImpactFormChanges}
            data-toggle='tooltip'
            data-placement='top'
            title='Initiative'
          >
            <option value='' selected>
              Select Initiative
            </option>
            {initiativeByAccount.map((init) => (
              <option value={init.id}>{init.title}</option>
            ))}
          </select>
          {formErrors?.form_initiative && (
            <div className='invalid-feedback'>{formErrors.form_initiative}</div>
          )}
        </div>
      )}
      <div className='text-divider'>Information </div>

      <div className='form-group col-12 mb-3'>
        <input
          type='text'
          id='form_title'
          placeholder='Title'
          value={impactFormValues.form_title}
          onChange={handleImpactFormChanges}
          className={`form-control ${
            formErrors.form_title ? "is-invalid" : ""
          }`}
          data-toggle='tooltip'
          data-placement='top'
          title='Title'
        />
        {formErrors?.form_title && (
          <div className='invalid-feedback'>{formErrors.form_title}</div>
        )}
      </div>
      <div className='form-group col-12 mb-3'>
        <textarea
          className={`form-control ${
            formErrors.form_description ? "is-invalid" : ""
          }`}
          id='form_description'
          rows='3'
          placeholder='Description'
          value={impactFormValues.form_description}
          onChange={handleImpactFormChanges}
          data-toggle='tooltip'
          data-placement='top'
          title='Description'
        ></textarea>
        {formErrors?.form_description && (
          <div className='invalid-feedback'>{formErrors.form_description}</div>
        )}
      </div>
      <div className='col-6 mb-3'>
        <Form.Group controlId='duedate'></Form.Group>
        <input
          type='date'
          className={`form-control ${
            formErrors.form_start_date ? "is-invalid" : ""
          }`}
          placeholder='Select Start Date'
          id='form_start_date'
          value={impactFormValues.form_start_date}
          onChange={handleImpactFormChanges}
          onFocus={() => {
            let start_date = document.getElementById("form_start_date");
            start_date.type = "date";
          }}
          onBlur={() => {
            let start_date = document.getElementById("form_start_date");
            start_date.type = "date";
          }}
          data-toggle='tooltip'
          data-placement='top'
          title='Start Data'
        />
        {formErrors?.form_start_date && (
          <div className='invalid-feedback'>{formErrors.form_start_date}</div>
        )}
      </div>
      <div className='col-6 mb-3'>
        <input
          type='date'
          className={`form-control ${
            formErrors.form_end_date ? "is-invalid" : ""
          }`}
          id='form_end_date'
          value={impactFormValues.form_end_date}
          onChange={handleImpactFormChanges}
          min={formValues.form_start_date ? formValues.form_start_date : null}
          placeholder='Select End Date'
          onFocus={() => {
            let start_date = document.getElementById("form_end_date");
            start_date.type = "date";
          }}
          onBlur={() => {
            let start_date = document.getElementById("form_end_date");
            start_date.type = "date";
          }}
          data-toggle='tooltip'
          data-placement='top'
          title='End Date'
        />

        {formErrors?.form_end_date && (
          <div className='invalid-feedback'>{formErrors.form_end_date}</div>
        )}
      </div>
      <div className='text-divider'>Data Tracking </div>

      <div className='col-6 mb-3'>
        <div className='input-group'>
          <input
            type='number'
            className={`form-control ${
              formErrors.form_overall_target_value ? "is-invalid" : ""
            }`}
            id='form_overall_target_value'
            placeholder='Overall Target Value'
            onChange={handleImpactFormChanges}
            value={impactFormValues.form_overall_target_value}
            data-toggle='tooltip'
            data-placement='top'
            title='Overall Target'
          />
          {formErrors?.form_overall_target_value && (
            <div className='invalid-feedback'>
              {formErrors.form_overall_target_value}
            </div>
          )}
        </div>
      </div>
      <div className='form-group col-6 mb-3'>
        <label>Target Direction</label>
        <div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              name='targetDirection'
              id='increase'
              value='Increase'
              checked={impactFormValues.form_target_direction === "Increase"}
              onChange={() =>
                handleImpactFormChanges({
                  target: { id: "form_target_direction", value: "Increase" },
                })
              }
              data-toggle='tooltip'
              data-placement='top'
              title='Target Direction'
            />
            <label className='form-check-label' htmlFor='increase'>
              Increase
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              name='targetDirection'
              id='decrease'
              value='Decrease'
              checked={impactFormValues.form_target_direction === "Decrease"}
              onChange={() =>
                handleImpactFormChanges({
                  target: { id: "form_target_direction", value: "Decrease" },
                })
              }
            />
            <label className='form-check-label' htmlFor='decrease'>
              Decrease
            </label>
          </div>
        </div>
        {formErrors?.targetDirection && (
          <div className='invalid-feedback'>{formErrors.targetDirection}</div>
        )}
      </div>
      <div
        className='col-6 mb-3'
        hidden={!["Impact"].includes(formInputs.formType)}
      >
        <textarea
          className={`form-control ${
            formErrors.form_label_x_axis ? "is-invalid" : ""
          }`}
          rows='1'
          placeholder='Label for X axis'
          id='form_label_x_axis'
          value={labelValues.form_label_x_axis}
          onChange={handleImpactFormChanges}
          data-toggle='tooltip'
          data-placement='top'
          title='Label for X axis'
        ></textarea>
        {formErrors?.form_label_x_axis && (
          <div className='invalid-feedback'>{formErrors.form_label_x_axis}</div>
        )}
      </div>

      <div
        className='form-group col-6 mb-3'
        hidden={!["Impact"].includes(formInputs.formType)}
      >
        <textarea
          className={`form-control ${
            formErrors.form_label_y_axis ? "is-invalid" : ""
          }`}
          rows='1'
          placeholder='Label for Y axis'
          id='form_label_y_axis'
          value={labelValues.form_label_y_axis}
          onChange={handleImpactFormChanges}
          required
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title=' Y-Axis indicates what the data represents or measures in the graph'
        ></textarea>
        {formErrors?.form_label_y_axis && (
          <div className='invalid-feedback'>{formErrors.form_label_y_axis}</div>
        )}
      </div>

      <div
        className='form-group col-6 mb-3'
        hidden={!["Impact"].includes(formInputs.formType)}
      >
        <select
          className={`form-select ${
            formErrors.form_tracking_frequency ? "is-invalid" : ""
          }`}
          id='form_tracking_frequency'
          value={impactFormValues.form_tracking_frequency}
          onChange={handleImpactFormChanges}
          data-toggle='tooltip'
          data-placement='top'
          title='Tracking Frequency'
        >
          <option value='' selected>
            Select Tracking Frequency
          </option>
          <option value='Monthly'>Monthly</option>
          <option value='Quarterly'>Quarterly</option>
          <option value='Yearly'>Yearly</option>
        </select>
        {formErrors?.form_tracking_frequency && (
          <div className='invalid-feedback'>
            {formErrors.form_tracking_frequency}
          </div>
        )}
      </div>
    </>
  );

  const clientFormContents = (
    <>
      <div className='text-divider'>General Information </div>

      {/* random */}
      <div className='col-6 mb-3'>
        <div className='d-flex align items-center'>
          <div className='w-100'>
            <input
              type='text'
              className={`form-control ${formErrors.form_name ? "is-invalid" : ""}`}
              id='form_name'
              placeholder='Client Name'
              value={clientFormValues.form_name}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Client Name'
              onChange={handleClientFormChanges}
              required
            />
            {formErrors?.form_name && (
              <div className='invalid-feedback'>{formErrors.form_name}</div>
            )}
          </div>
          <span className='text-danger ms-2'>*</span>
        </div>
      </div>
      <div className='col-3 mb-3'>
        <div className='d-flex align items-center'>
          <div className='w-100'>
            <input
              type='date'
              className={`form-control ${
                formErrors.form_start_date ? "is-invalid" : ""
              }`}
              id='form_start_date'
              placeholder='Start Date'
              value={clientFormValues.form_start_date}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Start Date'
              onChange={handleClientFormChanges}
              required
            />
            {formErrors?.form_start_date && (
              <div className='invalid-feedback'>
                {formErrors.form_start_date}
              </div>
            )}
          </div>
          <span className='text-danger ms-1'>*</span>
        </div>
      </div>
      <div className='col-3 mb-3'>
        <div className='d-flex align items-center'>
          <div className='w-100'>
            <input
              type='date'
              className={`form-control ${
                formErrors.form_end_date ? "is-invalid" : ""
              }`}
              id='form_end_date'
              placeholder='End Date'
              value={clientFormValues.form_end_date}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='End Date'
              onChange={handleClientFormChanges}
              required
            />
            {formErrors?.form_end_date && (
              <div className='invalid-feedback'>{formErrors.form_end_date}</div>
            )}
          </div>
          <span className='text-danger ms-1'>*</span>
        </div>
      </div>
      <div className='col-6 mb-3'>
        <div className='d-flex align items-center'>
          <div className='w-100'>
            <select
              className={`form-select ${
                formErrors.form_account_type ? "is-invalid" : ""
              }`}
              id='form_account_type'
              value={clientFormValues.form_account_type}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Account Type'
              onChange={handleClientFormChanges}
              required
            >
              <option selected value=''>
                Select Account Type
              </option>
              <option value='Consultant'>Consultant</option>
              <option value='Federal Government'>Federal Government</option>
              <option value='State/Local Government'>
                State/Local Government
              </option>
              <option value='Nonprofit'>Nonprofit</option>
              <option value='For-profit'>For-profit</option>
              <option value='Others'>Others</option>
            </select>
            {formErrors?.form_account_type && (
              <div className='invalid-feedback'>
                {formErrors.form_account_type}
              </div>
            )}
          </div>
          <span className='text-danger ms-1'>*</span>
        </div>
      </div>
      <div className='col-6 mb-3'>
        <div className='d-flex align items-center'>
          <div className='w-100'>
            <select
              className={`form-select ${
                formErrors.form_primary_consultant ? "is-invalid" : ""
              }`}
              id='form_primary_consultant'
              value={clientFormValues.form_primary_consultant}
              onChange={handleClientFormChanges}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Primary Consultant'
              required
            >
              <option selected value=''>
                Primary Consultant
              </option>
              {userList.length > 0 &&
                userList.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.first_name} {user.last_name}
                    {user.id === auth.id ? " (Current)" : ""}
                  </option>
                ))}
            </select>
            {formErrors?.form_primary_consultant && (
              <div className='invalid-feedback'>
                {formErrors.form_primary_consultant}
              </div>
            )}
          </div>
          <span className='text-danger'>*</span>
        </div>
      </div>
      <div className='col-6 mb-3'>
        <div className='d-flex align items-center'>
          <div className='w-100'>
            <input
              type='text'
              className={`form-control ${formErrors.form_address ? "is-invalid" : ""}`}
              id='form_address'
              placeholder='Address'
              value={clientFormValues.form_address}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Address'
              onChange={handleClientFormChanges}
              required
            />
            {formErrors?.form_address && (
              <div className='invalid-feedback'>{formErrors.form_address}</div>
            )}
          </div>
          <span className='text-danger ms-1'>*</span>
        </div>
      </div>
      <div className='col-3 mb-3'>
        <div className='d-flex align items-center'>
          <div className='w-100'>
            <select
              className={`form-select ${
                formErrors.form_country ? "is-invalid" : ""
              }`}
              id='form_country'
              value={clientFormValues.form_country}
              onChange={handleClientFormChanges}
              required
              data-toggle='tooltip'
              data-placement='top'
              title='Country'
            >
              <option selected value=''>
                Select Country
              </option>
              {countries &&
                countries?.map((country) => (
                  <option
                    key={country.isoCode}
                    value={country.isoCode}
                    selected={country.name === "United States"}
                  >
                    {country.name}
                  </option>
                ))}
            </select>
            {formErrors?.form_country && (
              <div className='invalid-feedback'>{formErrors.form_country}</div>
            )}
          </div>
          <span className='text-danger ms-1'>*</span>
        </div>
      </div>
      <div className='col-3 mb-3'>
        <div className='d-flex align items-center'>
          <div className='w-100'>
            <select
              className={`form-select ${formErrors.form_state ? "is-invalid" : ""} selectpicker`}
              id='form_state'
              value={clientFormValues.form_state}
              onChange={handleClientFormChanges}
              required
              data-toggle='tooltip'
              data-placement='top'
              title='States'
              data-live-search='true'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              disabled={isStates ? false : true}
            >
              <option selected value=''>
                Select State
              </option>
              {statesList &&
                statesList?.map((state) => (
                  <option value={state.isoCode} key={state.isoCode}>
                    {state.name}
                  </option>
                ))}
            </select>
            {formErrors?.form_state && (
              <div className='invalid-feedback'>{formErrors.form_state}</div>
            )}
          </div>
          <span className='text-danger ms-1'>*</span>
        </div>
      </div>

      <div className='col-3 mb-3'>
        <div className='d-flex align items-center'>
          <div className='w-100'>
            <input
              type='text'
              className={`form-control ${formErrors.form_city ? "is-invalid" : ""}`}
              id='form_city'
              placeholder='City'
              value={clientFormValues.form_city}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='City'
              onChange={handleClientFormChanges}
              required
            />
            {formErrors?.form_city && (
              <div className='invalid-feedback'>{formErrors.form_city}</div>
            )}
          </div>
          <span className='text-danger ms-1'>*</span>
        </div>
      </div>

      <div className='col-3 mb-3'>
        <div className='d-flex align items-center'>
          <div className='w-100'>
            <input
              type='text'
              className={`form-control ${
                formErrors.form_zipcode ? "is-invalid" : ""
              }`}
              id='form_zipcode'
              placeholder='Zipcode'
              value={clientFormValues.form_zipcode}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Zipcode'
              onChange={handleClientFormChanges}
            />
            {formErrors?.form_zipcode && (
              <div className='invalid-feedback'>{formErrors.form_zipcode}</div>
            )}
          </div>
          <span className='text-danger ms-1'>*</span>
        </div>
      </div>

      <div className='col-6 mb-3'>
        <input
          type='text'
          className={`form-control ${
            formErrors.form_website ? "is-invalid" : ""
          }`}
          id='form_website'
          placeholder='Website'
          value={clientFormValues.form_website}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Website'
          onChange={handleClientFormChanges}
        />
        {formErrors?.form_website && (
          <div className='invalid-feedback'>{formErrors.form_website}</div>
        )}
      </div>
      <div className='text-divider'>Contract Purpose (Focus Statement) </div>
      <div className='col-12 mb-3'>
        <textarea
          className={`form-control contract-statement${
            formErrors.form_contract_statement ? "is-invalid" : ""
          }`}
          id='form_contract_statement'
          placeholder='A statement outlining the purpose of this contract and what the contract aims to achieve.'
          value={clientFormValues.form_contract_statement}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Contract Purpose (Focus Statement)'
          onChange={handleClientFormChanges}
          rows='5'
        />
        {formErrors?.form_contract_statement && (
          <div className='invalid-feedback'>
            {formErrors.form_contract_statement}
          </div>
        )}
      </div>
      <div className='text-divider'>Contact Information </div>
      <div className='col-6 mb-3'>
        <div className='d-flex align items-center'>
          <div className='w-100'>
            <input
              type='text'
              className={`form-control ${
                formErrors.form_primary_contact ? "is-invalid" : ""
              }`}
              id='form_primary_contact'
              value={clientFormValues.form_primary_contact}
              placeholder='Primary Contact'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Primary Contact'
              onChange={handleClientFormChanges}
              required
            />
            {formErrors?.form_primary_contact && (
              <div className='invalid-feedback'>
                {formErrors.form_primary_contact}
              </div>
            )}
          </div>
          <span className='text-danger ms-1'>*</span>
        </div>
      </div>
      <div className='col-6 mb-3'>
        <div className='d-flex align items-center'>
          <div className='w-100'>
            <input
              type='text'
              className={`form-control ${
                formErrors.form_primary_contact_title ? "is-invalid" : ""
              }`}
              id='form_primary_contact_title'
              placeholder='Role Title'
              value={clientFormValues.form_primary_contact_title}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Role Title'
              onChange={handleClientFormChanges}
              required
            />
            {formErrors?.form_primary_contact_title && (
              <div className='invalid-feedback'>
                {formErrors.form_primary_contact_title}
              </div>
            )}
          </div>
          <span className='text-danger ms-1'>*</span>
        </div>
      </div>
      <div className='col-6 mb-5'>
        <div className='d-flex align items-center'>
          <div className='w-100'>
            <input
              type='text'
              className={`form-control ${
                formErrors.form_primary_contact_email ? "is-invalid" : ""
              }`}
              id='form_primary_contact_email'
              placeholder='Email'
              value={clientFormValues.form_primary_contact_email}
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Email'
              onChange={handleClientFormChanges}
              required
            />
            {formErrors?.form_primary_contact_email && (
              <div className='invalid-feedback'>
                {formErrors.form_primary_contact_email}
              </div>
            )}
          </div>
          <span className='text-danger ms-1'>*</span>
        </div>
      </div>
      <div className='col-6 mb-3'>
        <input
          type='tel'
          className={`form-control ${
            formErrors.form_primary_contact_phoneNumber ? "is-invalid" : ""
          }`}
          id='form_primary_contact_phoneNumber'
          placeholder='Phone Number'
          value={phoneNumberDisplayValues.form_primary_contact_phoneNumber}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Phone Number'
          onChange={handleClientFormChanges}
          style={{
            ...(formErrors.phoneNumber
              ? {
                  borderColor: "#c44b6c",
                  backgroundImage: "none",
                }
              : {}),
          }}
          required
        />
      </div>
      <div className='col-6 mb-3'>
        <input
          type='text'
          className={`form-control ${
            formErrors.form_secondary_contact ? "is-invalid" : ""
          }`}
          id='form_secondary_contact'
          placeholder='Secondary Contact'
          value={clientFormValues.form_secondary_contact}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Secondary Contact'
          onChange={handleClientFormChanges}
        />
        {formErrors?.form_secondary_contact && (
          <div className='invalid-feedback'>
            {formErrors.form_secondary_contact}
          </div>
        )}
      </div>
      <div className='col-6 mb-3'>
        <input
          type='text'
          className={`form-control ${
            formErrors.form_secondary_contact_title ? "is-invalid" : ""
          }`}
          id='form_secondary_contact_title'
          placeholder='Role Title'
          value={clientFormValues.form_secondary_contact_title}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Role Title'
          onChange={handleClientFormChanges}
        />
        {formErrors?.form_secondary_contact_title && (
          <div className='invalid-feedback'>
            {formErrors.form_secondary_contact_title}
          </div>
        )}
      </div>
      <div className='col-6 mb-5'>
        <input
          type='text'
          className={`form-control ${
            formErrors.form_secondary_contact_email ? "is-invalid" : ""
          }`}
          id='form_secondary_contact_email'
          placeholder='Email'
          value={clientFormValues.form_secondary_contact_email}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Email'
          onChange={handleClientFormChanges}
        />
        {formErrors?.form_secondary_contact_email && (
          <div className='invalid-feedback'>
            {formErrors.form_secondary_contact_email}
          </div>
        )}
      </div>
      <div className='col-6 mb-5'>
        <input
          type='tel'
          className={`form-control ${
            formErrors.form_secondary_contact_phoneNumber ? "is-invalid" : ""
          }`}
          id='form_secondary_contact_phoneNumber'
          placeholder='Phone Number'
          value={phoneNumberDisplayValues.form_secondary_contact_phoneNumber}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Phone Number'
          onChange={handleClientFormChanges}
          style={{
            ...(formErrors.phoneNumber
              ? {
                  borderColor: "#c44b6c",
                  backgroundImage: "none",
                }
              : {}),
          }}
        />
        {formErrors?.form_secondary_contact_phoneNumber && (
          <div className='invalid-feedback'>
            {formErrors.form_secondary_contact_phoneNumber}
          </div>
        )}
      </div>
    </>
  );

  return (
    <>
      {showSuccessPrompt && (
        <div
          className='toast show position-fixed text-center top-0 start-50 translate-middle-x'
          role='alert'
          aria-live='assertive'
          aria-atomic='true'
          style={{ zIndex: 1100, maxWidth: "300px" }}
        >
          Client added successfully!
        </div>
      )}
      {formInputs.formAction === "Add" ? (
        <Button onClick={handleAddClick} className='btn-sm my-1'>
          <i className='bi bi-plus'></i>
        </Button>
      ) : null}
      {formInputs.formAction === "Edit" ? (
        <i
          className='bi bi-pencil-square'
          data-bs-toggle='tooltip'
          data-bs-placment='top'
          onClick={handleEditClick}
        ></i>
      ) : null}
      {apiError ? <ErrorHandler error={apiError} onClose={onClose} /> : ""}
      <Modal
        show={modalShow}
        onHide={onHide}
        size='lg'
        backdrop='static'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {clientDet && clientDet.data && clientDet.data.data.name
              ? clientDet.data.data.name + " - "
              : null}{" "}
            {formInputs.formAction + " " + formInputs.formType}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{formContent(formInputs)}</Modal.Body>
      </Modal>
    </>
  );
};

export default FormHandler;
