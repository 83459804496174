import { useState } from "react";
import { ContactsList } from "features/contacts";
import { useNavigate, useParams } from "react-router-dom";
import { useGetImpactQuery } from "features/impacts/impactsSlice";
import MultiTypeChart from "common/charts/MultiTypeChart";
//import FocusScoreChart from "features/focus-score/FocusScoreChart";
import { store } from "app/store";
import { ImpactBreadCrumb, ImpactDataPoints } from "features/impacts";
import Attachment from "common/attachments/Attachment";
import {
  useGetImpactAttachmentsQuery,
  useAddImpactAttachmentsMutation,
  useDeleteAttachmentMutation,
} from "common/attachments/attachmentSlice";
import FormHandler from "common/formHandler/FormHandler";
import {
  useGetCommentsQuery,
  useAddCommentsMutation,
} from "common/comments/commentSlice";
import { Comment } from "common";
import {
  useDeleteImpactMutation,
  useUpdateImpactMutation,
} from "features/impacts/impactsSlice";
import { DeleteDialog } from "common";
import { useGetHierarchyByImpactQuery } from "features/goals/goalsSlice";
import { Hierarchy, Dates } from "common";
import { FocusScore } from "features/focus-score";
import { GetClassName } from "common/helpers/GetClassName";

function Impact() {
  let impactData = [];
  let userId;
  let attachmentsInfo = [];
  let impactHierarchy = [];
  let formType = "Impact";

  //Giving the userId as 0 if user is not logged in.It is temporary.
  if (store.getState().auth.userData === null) userId = 0;
  else userId = store.getState().auth.userData.id;

  const navigate = useNavigate();

  const { id } = useParams(); // get 'id' from path param
  const impactId = id; // assign id to impactId
  const [inventoryId, setInventoryId] = useState(1);
  let impactComments = [];
  let type = "impact";
  let parentId = impactId;

  const [model, setModel] = useState(false);

  const [selectedImpactDetails, setSelectedImpactDetails] = useState({
    impactId: "",
    impactTitle: "",
  });

  const hideDialog = () => {
    setModel(false);
    navigate("/impacts");
  };

  const handleChange = (inventoryId) => {
    setInventoryId(inventoryId);
  };

  const { data: impactDetails, isSuccess } = useGetImpactQuery(impactId, {
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess && impactDetails.data) {
    impactData = impactDetails.data;
  }

  const DELETE_DIALOG_TITLE = "Impact: Delete";
  const DELETE_DIALOG_MESSAGE = `Are you sure you want to delete "${selectedImpactDetails.title}" impact?`;

  const impactAttachmentsData = useGetImpactAttachmentsQuery(impactId, {
    refetchOnMountOrArgChange: true,
  });

  if (impactAttachmentsData.isSuccess) {
    attachmentsInfo = impactAttachmentsData.data.attachments;
  }

  const [addImpactAttachemnts] = useAddImpactAttachmentsMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleAddAttachment = async (formData) => {
    formData.append("impact_id", impactId);
    formData.append("user_id", userId);
    await addImpactAttachemnts(formData);
  };

  const [deleteImpactAttachemnts] = useDeleteAttachmentMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleDeleteAttachment = async (attachmentId) => {
    await deleteImpactAttachemnts(attachmentId);
  };

  const { data: impactHierarchyData, isSuccess: isImpactHierarchySuccess } =
    useGetHierarchyByImpactQuery(impactId, {
      refetchOnMountOrArgChange: true,
    });

  if (isImpactHierarchySuccess && impactHierarchyData.data) {
    impactHierarchy = impactHierarchyData.data;
  }

  const renderInventoryContent = () => {
    switch (inventoryId) {
      case 1:
        return <Hierarchy hierarchy={impactHierarchy} />;
      case 2:
        return (
          <Dates
            created={impactData.created_on_date}
            started={impactData.start_date}
            ended={impactData.end_date}
            lastModified={impactData.modified_on_date}
          />
        );
      case 3:
        return <ImpactDataPoints impactId={impactId} impactData={impactData} />;
      case 4:
        return <ContactsList />;
      default:
        return (
          <Attachment
            attachmentsInfo={attachmentsInfo}
            handleAddAttachment={handleAddAttachment}
            handleDeleteAttachment={handleDeleteAttachment}
          />
        );
    }
  };

  const renderHealthCheck = (impactId) => {
    return <MultiTypeChart impactId={impactId} impactData={impactData} />;
  };

  const renderFocusScore = (impactId, targetDirection) => {
    // return <FocusScoreChart user_Id={userId} impact_Id={impactId} />;
    return (
      <FocusScore
        userid={impactData.owner_consultant}
        impactid={impactId}
        formType={formType}
        targetDirection={targetDirection}
      />
    );
  };

  const deleteImpactDialog = (impactId, impactTitle) => {
    setSelectedImpactDetails({ impactId: impactId, impactTitle: impactTitle });
    return setModel(true);
  };

  const [updateImpactStage] = useUpdateImpactMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleImpactStage = async (selectedStage) => {
    let impact = {
      stage: selectedStage,
    };
    try {
      await updateImpactStage({
        impactId: impactId,
        body: impact,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [deleteImpact] = useDeleteImpactMutation();
  const getchevronClass = (stage) => {
    switch (stage) {
      case "Not-Started":
        return "#fff";
      case "In-Progress":
        return "#000";
      case "Completed":
        return "#000";
      default:
        return "#000";
    }
  };

  const renderImpactDetail = (title, description, impactId, stage) => {
    return (
      <div className='card p-4'>
        <div className='card-body d-flex flex-column'>
          <div className='row'>
            <div className='col-1'>
              <i className='bi bi-fire icon-title'></i>
            </div>
            <div className='col'>
              <h1 className='impact-title'>{title}</h1>
            </div>
          </div>
          <div className='impact-status'>
            <div class='btn-group dropdown'>
              <button
                className={`btn btn-${GetClassName(stage)} btn-sm dropdown-toggle`}
                type='button'
                id='dropdownButton'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <span className='fw-bold'>{stage}</span>
                <i
                  className='bi bi-chevron-down'
                  style={{ color: getchevronClass(stage) }}
                ></i>
              </button>
              <ul className='dropdown-menu' aria-labelledby='dropdownButton'>
                <li>
                  <button
                    type='button'
                    className='dropdown-item'
                    onClick={() => handleImpactStage("Not-Started")}
                  >
                    Not-Started
                  </button>
                </li>
                <li>
                  <button
                    type='button'
                    className='dropdown-item'
                    onClick={() => handleImpactStage("In-Progress")}
                  >
                    In-Progress
                  </button>
                </li>
                <li>
                  <button
                    type='button'
                    className='dropdown-item'
                    onClick={() => handleImpactStage("Completed")}
                  >
                    Completed
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <FormHandler
                formInputs={{
                  formType: "Impact",
                  formAction: "Edit",
                  itemDetails: {
                    id: impactId,
                  },
                }}
              />
              <span className='action-item'>
                <i
                  className='bi bi-trash-fill'
                  data-bs-placement='top'
                  title='Delete'
                  onClick={() => deleteImpactDialog(impactId, title)}
                ></i>
              </span>
            </div>
          </div>
          <p className='impact-description'>{description}</p>
          <div className='impact-icon-group'>
            <button
              className='btn-basic'
              onClick={() => handleChange(1)}
              data-toggle='tooltip'
              data-placement='top'
              title='Hierarchy'
            >
              <i
                className={
                  "bi bi-diagram-3 " + (inventoryId === 1 ? "active" : "")
                }
              ></i>
            </button>
            <button
              className='btn-basic'
              onClick={() => handleChange(2)}
              data-toggle='tooltip'
              data-placement='top'
              title='Calendar'
            >
              <i
                className={
                  "bi bi-calendar-date " + (inventoryId === 2 ? "active" : "")
                }
              ></i>
            </button>
            <button
              className='btn-basic'
              onClick={() => handleChange(3)}
              data-toggle='tooltip'
              data-placement='top'
              title='Data Points'
            >
              <i
                className={
                  "bi bi-bar-chart-steps " + (inventoryId === 3 ? "active" : "")
                }
              ></i>
            </button>
            <button
              className='btn-basic'
              onClick={() => handleChange(4)}
              data-toggle='tooltip'
              data-placement='top'
              title='Contact'
            >
              <i
                className={
                  "bi bi-person-square " + (inventoryId === 4 ? "active" : "")
                }
              ></i>
            </button>
            <button
              className='btn-basic'
              onClick={() => handleChange(5)}
              data-toggle='tooltip'
              data-placement='top'
              title='Attachment'
            >
              <i
                className={
                  "bi bi-paperclip " + (inventoryId === 5 ? "active" : "")
                }
              ></i>
            </button>
          </div>
          <div id='inventory-content' className='mt-4'>
            {renderInventoryContent()}
          </div>
        </div>
      </div>
    );
  };

  const { data: impactCommentData, isSuccess: isCommentSuccess } =
    useGetCommentsQuery(
      { type, parentId },
      {
        refetchOnMountOrArgChange: true,
      },
    );

  if (isCommentSuccess && impactCommentData.comments) {
    impactComments = impactCommentData.comments;
  }
  const [addImpactComments] = useAddCommentsMutation({
    refetchOnMountOrArgChange: true,
  });
  const handleCommentSubmit = async (formData) => {
    formData.append("comment_type_id", parentId);
    formData.append("user_id", userId);
    formData.append("type", type);
    await addImpactComments(formData);
  };

  return (
    <>
      <div className='container g-4'>
        <div className='row'>
          <ImpactBreadCrumb impactId={impactId} />
        </div>
        <div id='impact-detail' className='row'>
          <div className='col-4'>
            {renderImpactDetail(
              impactData.title,
              impactData.description,
              impactData.id,
              impactData.stage,
            )}
          </div>
          <div className='col'>
            <div className='row mb-4'>
              <div className='col'>
                <div className='card'>
                  <div className='card-header fw-bold'>
                    Health Check - Target vs Actual
                  </div>
                  <div className='card-body'>{renderHealthCheck(impactId)}</div>
                </div>
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col'>
                <div className='card'>
                  <div className='card-header'>Focus Scores</div>
                  <div className='card-body'>
                    {renderFocusScore(impactId, impactData.target_direction)}
                  </div>
                </div>
              </div>
            </div>
            <Comment
              comments={impactComments}
              handleCommentSubmit={handleCommentSubmit}
            />
          </div>
        </div>
      </div>
      {model === true ? (
        <DeleteDialog
          dialogTitle={DELETE_DIALOG_TITLE}
          descriptionText={DELETE_DIALOG_MESSAGE}
          hide={() => hideDialog()}
          handleDelete={() => deleteImpact(impactId)}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Impact;
