import cardLogo from "features/ui/svgs/logo-hz-white.svg";
import { useState } from "react";
import { useUserOnboardingMutation } from "features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import { ErrorHandler } from "common";
import { useToken } from "../../common/hooks";

const initialForm = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

function Onboarding() {
  const [formData, setFormData] = useState(initialForm);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [validatioError, setValidationError] = useState("");
  const [isOnboardingError, setIsOnboardingError] = useState(false);
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [userOnboarding] = useUserOnboardingMutation();
  const { tokenData, isValid } = useToken();

  const handleOnboarding = async (e) => {
    e.preventDefault();

    if (!formData.first_name || !formData.last_last || !formData.password)
      return setValidationError("Fill All The Required Fields.");

    if (formData?.password !== formData?.confirmPassword) {
      setValidationError("Password and confirm Password are not equal.");
    }
    try {
      const result = await userOnboarding({
        first_name: formData?.first_name,
        last_name: formData?.last_name,
        email: tokenData?.email?.toLowerCase(),
        company_id: tokenData?.invited_by?.company_id,
        password: formData?.password,
        confirmPassword: formData?.confirmPassword,
      });

      if (result.error) {
        setIsOnboardingError(true);
        setApiError(result);
      }
      navigate("/");
    } catch (error) {
      setIsOnboardingError(true);
      setApiError("An error occurred");
    }
  };

  const onClose = () => {
    setApiError(null);
  };

  return (
    <>
      <div id='login-box' className='card border-0'>
        <div className='card-header bg-tertiary d-flex align-items-center justify-content-center'>
          <img src={cardLogo} alt='Focus Logo' className='card-logo' />
        </div>
        <div className='card-body p-4'>
          <div className='text-center w-75 m-auto'>
            <h1 className='h5 card-title'>User Onboarding</h1>
            {/* <p className='text-muted mb-4'>
              Enter your email address and password to login to your account.
            </p> */}

            {validatioError ? (
              <div
                className='alert alert-danger alert-dismissible fade show '
                role='alert'
              >
                {validatioError}
              </div>
            ) : null}
          </div>

          {isValid && tokenData?.token ? (
            <form>
              <div className='mb-4'>
                <label htmlFor='firstName' className='form-label'>
                  First Name
                </label>
                <input
                  placeholder='Enter First Name'
                  name='firstName'
                  autoComplete='firstName'
                  type='text'
                  id='firstName'
                  className='form-control'
                  value={formData?.first_name}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      first_name: e.target.value,
                    }))
                  }
                />
              </div>

              <div className='mb-4'>
                <label htmlFor='lastName' className='form-label'>
                  Last Name
                </label>
                <input
                  placeholder='Enter your lastname'
                  name='lastName'
                  autoComplete='lastName'
                  type='text'
                  id='lastName'
                  className='form-control'
                  value={formData?.last_last}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      last_name: e.target.value,
                    }))
                  }
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='email' className='form-label'>
                  Email
                </label>
                <input
                  placeholder='Enter your email'
                  name='email'
                  autoComplete='email'
                  type='email'
                  id='email'
                  className='form-control'
                  value={tokenData?.email?.toLowerCase() || ""}
                  disabled
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='password' className='form-label'>
                  Password
                </label>

                <div className='mb-0 input-group'>
                  <input
                    placeholder='Enter your password'
                    name='password'
                    autoComplete='password'
                    type={passwordShown ? "text" : "password"}
                    id='password'
                    className='form-control'
                    value={formData?.password}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                  />
                  <div
                    className='input-group-text input-group-password'
                    data-password='false'
                  >
                    <span
                      onClick={() => setPasswordShown(!passwordShown)}
                      className={
                        passwordShown ? "bi bi-eye" : "bi bi-eye-slash"
                      }
                    ></span>
                  </div>
                </div>
              </div>
              <div className='mb-3'>
                <label htmlFor='confirm password' className='form-label'>
                  Confirm Password
                </label>

                <div className='mb-0 input-group'>
                  <input
                    placeholder='Enter your confirm password'
                    name='confirm password'
                    autoComplete='confirm password'
                    type={confirmPasswordShown ? "text" : "password"}
                    id='confirm password'
                    className='form-control'
                    value={formData?.confirmPassword}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        confirmPassword: e.target.value,
                      }))
                    }
                  />
                  <div
                    className='input-group-text input-group-password'
                    data-password='false'
                  >
                    <span
                      onClick={() =>
                        setConfirmPasswordShown(!confirmPasswordShown)
                      }
                      className={
                        confirmPasswordShown ? "bi bi-eye" : "bi bi-eye-slash"
                      }
                    ></span>
                  </div>
                </div>
              </div>

              <div className='mb-3 mb-0 text-center'>
                <button
                  type='submit'
                  className='btn btn-primary text-white'
                  onClick={handleOnboarding}
                >
                  Join The Team
                </button>
              </div>
            </form>
          ) : (
            <div
              className='alert alert-danger alert-dismissible fade show '
              role='alert'
            >
              Invalid Invitation token.
            </div>
          )}
        </div>
      </div>
      {isOnboardingError === true ? (
        <div className='position-fixed pt-5 top-0 start-50 translate-middle'>
          <div
            className='alert alert-warning alert-dismissible fade show '
            role='alert'
          >
            Please enter valid token and credentials.
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='alert'
              aria-label='Close'
            ></button>
          </div>
        </div>
      ) : null}
      {apiError ? <ErrorHandler error={apiError} onClose={onClose} /> : null}
    </>
  );
}

export default Onboarding;
