import { RegisterOrganization } from "features/register-organization";
function Signup() {
  return (
    <>
      <RegisterOrganization />
    </>
  );
}

export default Signup;
