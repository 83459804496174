import { useIsInvitesTokenMutation } from "features/auth/authApiSlice";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

export const useToken = () => {
  const [tokenData, setTokenData] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const params = useParams();
  const [isInvitesToken] = useIsInvitesTokenMutation();

  useEffect(() => {
    const validateToken = async (token) => {
      try {
        const checkToken = await isInvitesToken(token);
        if (checkToken?.data) {
          setTokenData(checkToken.data);
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } catch (error) {
        setIsValid(false);
      }
    };

    const tokenFromUrl = params?.token;
    if (tokenFromUrl) {
      validateToken(tokenFromUrl);
    }
  }, [params?.token, isInvitesToken]);

  return { tokenData, isValid };
};
