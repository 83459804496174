import { useState, useMemo } from "react";
import { Link } from "react-router-dom";

function Task({
  tasks,
  completedSection,
  updateTask,
  numIncompleteTask,
  breadcrumb,
}) {
  // Filter tasks using useMemo to avoid recalculations
  const incompleteTasks = useMemo(
    () =>
      tasks
        .filter((task) => task.stage.toUpperCase() !== "COMPLETED")
        .slice(0, numIncompleteTask),
    [tasks, numIncompleteTask],
  );

  const completedTasks = useMemo(
    () => tasks.filter((task) => task.stage.toUpperCase() === "COMPLETED"),
    [tasks],
  );

  const [checkedTasks, setCheckedTasks] = useState(
    completedTasks.map((task) => task.id),
  );

  const formatDate = (date) => {
    const dateObj = new Date(date);
    dateObj.setDate(dateObj.getDate() + 1);
    return `${(dateObj.getMonth() + 1).toString().padStart(2, "0")}/${dateObj
      .getDate()
      .toString()
      .padStart(2, "0")}/${dateObj.getFullYear()}`;
  };

  const toggleTaskStatus = async (task) => {
    const newStage =
      task.stage.toUpperCase() === "COMPLETED" ? "Incomplete" : "Completed";
    const updatedTask = { ...task, stage: newStage };
    await updateTask({ taskId: task.id, body: updatedTask });
  };

  const handleCheckChange = (task) => {
    const isChecked = checkedTasks.includes(task.id);
    setCheckedTasks((prev) =>
      isChecked ? prev.filter((id) => id !== task.id) : [...prev, task.id],
    );
    toggleTaskStatus(task);
  };
  //  Task Item
  const TaskItem = ({ task, isChecked }) => (
    <div className='list-group-item d-flex align-items-start pb-2'>
      <div className='flex-grow-1'>
        <div className='d-flex justify-content-between align-items-start list-checkrow'>
          <div className='form-wrap'>
            <input
              className='form-check-input'
              type='checkbox'
              id={task.id}
              checked={isChecked}
              onChange={() => handleCheckChange(task)}
            />
            <label className='form-check-label' htmlFor={task.id}>
              <Link
                className='flex-grow-1 list-task mx-2 small'
                to={`/tasks/${task.id}`}
                state={{ taskId: task.id, breadcrumb }}
              >
                {task.title.length > 25
                  ? `${task.title.slice(0, 25)}...`
                  : task.title}
              </Link>
              <p className='flex-grow-1 list-task mx-2 small text-muted'>
                Date: {formatDate(task.end_date)}
              </p>
            </label>
          </div>
          {breadcrumb !== "dashboard" && (
            <div className='flex-shrink-0'>
              <p className='owner_consultant mx-2 small text-muted'>
                {task.owner_consultant_name}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  if (tasks.length === 0) {
    return (
      <div className='mx-2 small text-muted'>
        <p>No tasks have been assigned to this project.</p>
      </div>
    );
  }

  return (
    <>
      <div className='mb-4'>
        {breadcrumb === "project" && (
          <h6 className='IncompletedTask'>Incomplete</h6>
        )}
        <div className='list-group list-group-flush'>
          {incompleteTasks.length > 0 ? (
            incompleteTasks.map((task) => (
              <TaskItem key={task.id} task={task} isChecked={false} />
            ))
          ) : (
            <p> No incomplete tasks.</p>
          )}
        </div>
      </div>

      {completedSection && (
        <div className='mb-4'>
          {breadcrumb === "project" && (
            <h2 className='completedTask'>Completed</h2>
          )}
          <div className='list-group list-group-flush'>
            {completedTasks.length > 0 ? (
              completedTasks.map((task) => (
                <TaskItem key={task.id} task={task} isChecked={true} />
              ))
            ) : (
              <p>No completed tastks.</p>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Task;
