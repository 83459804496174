import Avatar from "react-avatar";
import { Modal } from "react-bootstrap";
import { useGetOrganizationQuery } from "features/organization/organizationsSlice";

function UserModal({ show, onHide, user }) {
  let companyData = [];

  const handleOrganization = useGetOrganizationQuery(user.company_id, {
    refetchOnMountOrArgChange: true,
  });

  if (handleOrganization.isSuccess === true) {
    companyData = handleOrganization.data;
  }

  const linkedInUrl = "https://www.linkedin.com/in/johndoe";
  const skypeUrl = "skype:john.doe?chat";

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <button
          onClick={onHide}
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            border: "none",
            background: "none",
          }}
        >
          <i className='bi bi-x-lg'></i>
        </button>
        <div className='text-center mb-4'>
          <Avatar
            name={`${user.first_name} ${user.last_name}`}
            size='100'
            round={true}
          />
        </div>

        <div className='user-name'>
          <span>
            {user.first_name} {user.last_name}
          </span>
        </div>
        <div className='user-name'>
          <span>{companyData.company_name}</span>
        </div>
        <div className='user-email'>
          <span>
            <strong>Email:</strong> {user.email}
          </span>
        </div>
        <div className='user-contact-permission'>
          <span>
            <strong>Contact:</strong> {user.primary_contact}
          </span>
        </div>
        <div className='user-contact-permission'>
          <span>
            <strong>Permissions:</strong> Admin
          </span>
        </div>
        <div className='social-icon-personal'>
          <a href={linkedInUrl} target='_blank'>
            <i className='bi bi-linkedin active'></i>
          </a>
          <a href={skypeUrl} target='_blank'>
            <i className='bi bi-skype active'></i>
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UserModal;
