import { apiSlice } from "app/api";

const clientSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getVendorClients: build.query({
      query: (vendoraccountid) => `accounts/clients/${vendoraccountid}`,
      providesTags: ["Account"],
    }),
    getConsultantClients: build.query({
      query: (consultantId) => `accounts/consultantclients/${consultantId}`,
      providesTags: ["Account"],
    }),
    getClientDetails: build.query({
      query: (clientId) => `accounts/${clientId}`,
      providesTags: ["Account"],
    }),
    updateClient: build.mutation({
      query: ({ clientId, ...body }) => ({
        url: `/accounts/${clientId}`,
        method: "PATCH",
        ...body,
      }),
      invalidatesTags: ["Account"],
    }),
    deleteClient: build.mutation({
			query: (clientId) => ({
				url: `/accounts/${clientId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Account"],
		}),
    addClient: build.mutation({
			query: ({ ...body }) => ({
				url: `/accounts/`,
				method: "POST",
				...body,
			}),
			invalidatesTags: ["Account"],
		}),
  }),
  overrideExisting: false,
});

export const {
  useGetVendorClientsQuery,
  useGetConsultantClientsQuery,
  useGetClientDetailsQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useAddClientMutation,
} = clientSlice;
