import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { store } from "app/store";
import {
  useGetImpactHistoryQuery,
  useGetImpactHistoryByConsultantQuery,
} from "features/impacts/impactsSlice";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
);
// CV /TV
// Example with a Target Value (TV) of 100
//	   Score of 0: Range of == 0%
//     Score of 1: Range of >0% and <= 20% of target value
//         Example: AV = 13  (13 / 100 = .13 or 13% of target value)
//     Score of 2: Range of >20% and <= 40% of target value
//         Example: AV = 37  (37 / 100 = .37 or 37% of target value)
//     Score of 3: Range of >40% and <=60% of target value
//         Example: AV = 52  (52 / 100 = .52 or 52% of target value)
//     Score of 4: Range of >60% and <= 80% of target value
//         Example: AV = 65  (65 / 100 = .65 or 65% of target value)
//     Score of 5: Range of >80% and <= 100% of target value
//         Example: AV = 92  (92 / 100 = .92 or 92% of target value)

const FocusScore = ({ userid, impactid, formType, targetDirection }) => {
  let userId = userid;
  if (!userId) {
    userId = store.getState().auth.userData.id;
  }

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Chart Model
  let scoreData = {
    labels: [], // will be populated later
    datasets: [
      {
        data: [], // will be populated later
        borderColor: "#1f2b5c",
        backgroundColor: "#9ac8ea",
        fill: true,
      },
    ],
    borderWidth: 1,
  };

  //Giving the userId as 0 if user is not logged in.It is temporary.
  //   if (store.getState().auth.userData === null) userId = 0;
  //   else userId = store.getState().auth.userData.id;

  const {
    data: impactHistoryData,
    isError: isImpactHistoryError,
    isSuccess: isImpactHistorySuccess,
  } = useGetImpactHistoryQuery(impactid, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: impactHistoryByConsultantData,
    isError: isImpactHistoryByConsultantError,
    isSuccess: isImpactHistoryByConsultantSuccess,
  } = useGetImpactHistoryByConsultantQuery(userId, {
    refetchOnMountOrArgChange: true,
  });

  const getScore = (difference) => {
    let score = 0;
    if (difference > 0 && difference <= 0.1) {
      score = 1;
    } else if (difference > 0.1 && difference <= 0.2) {
      score = 2;
    } else if (difference > 0.2 && difference <= 0.3) {
      score = 3;
    } else if (difference > 0.3 && difference <= 0.4) {
      score = 4;
    } else if (difference > 0.4 && difference <= 0.5) {
      score = 5;
    } else if (difference > 0.5 && difference <= 0.6) {
      score = 6;
    } else if (difference > 0.6 && difference <= 0.7) {
      score = 7;
    } else if (difference > 0.7 && difference <= 0.8) {
      score = 8;
    } else if (difference > 0.8 && difference <= 0.9) {
      score = 9;
    } else if (difference > 0.9) {
      score = 10;
    }

    return score;
  };
  //score for taregt direction as decrease
  const getScoreForDecrease = (difference) => {
    let score = 0;

    if (difference > 0 && difference <= 0.5) {
      score = 10;
    } else if (difference > 0.5 && difference <= 1) {
      score = 9;
    } else if (difference > 1 && difference <= 1.5) {
      score = 8;
    } else if (difference > 1.5 && difference <= 2) {
      score = 7;
    } else if (difference > 2.5 && difference <= 2.5) {
      score = 6;
    } else if (difference > 2.5 && difference <= 3) {
      score = 5;
    } else if (difference > 0.6 && difference <= 0.7) {
      score = 4;
    } else if (difference > 3 && difference <= 3.5) {
      score = 3;
    } else if (difference > 3.5 && difference <= 4) {
      score = 2;
    } else if (difference > 4 && difference <= 4.5) {
      score = 1;
    }

    return score;
  };

  // Calculate average of array data helper function
  const finalValues = (array) => {
    let lastElement = array[array.length - 1];
    return lastElement;
  };
  const average = (array) => {
    let sum = 0;
    array.map((value) => (sum += value));
    return sum / array.length;
  };

  //initial data points to compensate for missing months data
  const initDataPoints = () => {
    let data = [];
    const now = new Date();
    now.setDate(1);
    now.setHours(0, 0, 0, 0);

    let currentMonth = now.getMonth() + 1;

    for (let i = 1; i <= 3; i++) {
      now.setMonth(currentMonth - i);

      const epoch = now.getTime();
      data.push({
        id: epoch,
        overallCumulativeValue: [],
        targetValues: [],
        score: 0,
        month: now.getMonth() + 1,
        year: now.getFullYear(),
      });
    }

    return data;
  };
  const calculateScoreDataPointsForImpact = (data) => {
    let points = initDataPoints();
    data.forEach((item) => {
      // TODO Fix backend to include timestamp
      const date = new Date(item.modified_on_date + " 00:00");

      // because of the format of the date + 1 needs to be added to the month
      // see: https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off#:~:text=Javascript%27s%20Date%20class%20doesn%27t,%3A00%20GMT%2D0400).
      let firstOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);

      // used to group data points by there month and year
      const epoch = firstOfTheMonth.getTime();

      points.forEach(function (point) {
        if (point.id === epoch) {
          point.overallCumulativeValue.push(
            parseFloat(item.overall_cumulative_value),
          );
          point.targetValues.push(parseFloat(item.overall_target_value));
        }
      });
    });

    // Sort data in accending order
    points.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));

    // only show 3 months of data
    while (points.length > 3) {
      points.shift();
    }

    points.forEach(function (point) {
      let currentAverage;
      let targetAverage;

      currentAverage = finalValues(point.overallCumulativeValue);
      targetAverage = finalValues(point.targetValues);
      scoreData.labels.push(labels[point.month - 1]);
      if (targetDirection === "Increase") {
        scoreData.datasets[0].data.push(
          getScore(currentAverage / targetAverage),
        );
      } else {
        scoreData.datasets[0].data.push(
          getScoreForDecrease(currentAverage / targetAverage),
        );
      }
    });
  };

  // Parse and calculate Focus Score and generate chart data format
  const calculateScoreDataPoints = (data) => {
    let points = initDataPoints().map((point) => ({
      ...point,
      increaseOverallCumulativeValue: [],
      increaseTargetValues: [],
      decreaseOverallCumulativeValue: [],
      decreaseTargetValues: [],
    }));
    const lastEntries = {};
    data.forEach((item) => {
      const impactId = item.impact_id;
      const date = new Date(item.modified_on_date + " 00:00");

      let firstOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);

      // used to group data points by there month and year
      const epoch = firstOfTheMonth.getTime();
      const key = `${impactId}_${epoch}`;
      lastEntries[key] = {
        impactId: impactId,
        epoch: epoch,
        overallCumulativeValue: parseFloat(item.overall_cumulative_value),
        targetValues: parseFloat(item.overall_target_value),
        targetDirection: item.target_direction,
      };
    });
    Object.keys(lastEntries).forEach((key) => {
      const lastEntry = lastEntries[key];
      const month = new Date(parseInt(lastEntry.epoch)).getMonth() + 1;
      let point = points.find((p) => p.month === month);
      if (!point) {
        point = {
          month: month,
          increaseOverallCumulativeValue: [],
          increaseTargetValues: [],
          decreaseOverallCumulativeValue: [],
          decreaseTargetValues: [],
        };
        points.push(point);
      }
      if (lastEntry.targetDirection === "Increase") {
        point.increaseOverallCumulativeValue.push(
          lastEntry.overallCumulativeValue,
        );
        point.increaseTargetValues.push(lastEntry.targetValues);
      } else if (lastEntry.targetDirection === "Decrease") {
        point.decreaseOverallCumulativeValue.push(
          lastEntry.overallCumulativeValue,
        );
        point.decreaseTargetValues.push(lastEntry.targetValues);
      }
    });
    points.forEach((point) => {
      let increaseScore = 0;
      let decreaseScore = 0;
      if (
        point.increaseOverallCumulativeValue &&
        point.increaseOverallCumulativeValue.length > 0 &&
        point.increaseTargetValues.length > 0
      ) {
        const increaseCurrentAverage = average(
          point.increaseOverallCumulativeValue,
        );
        const increaseTargetAverage = average(point.increaseTargetValues);
        increaseScore = getScore(
          increaseCurrentAverage / increaseTargetAverage,
        );
      }

      if (
        point.decreaseOverallCumulativeValue &&
        point.decreaseOverallCumulativeValue.length > 0 &&
        point.decreaseTargetValues.length > 0
      ) {
        const decreaseCurrentAverage = average(
          point.decreaseOverallCumulativeValue,
        );
        const decreaseTargetAverage = average(point.decreaseTargetValues);

        decreaseScore = getScoreForDecrease(
          decreaseCurrentAverage / decreaseTargetAverage,
        );
      }
      let finalScore;
      if (increaseScore > 0 && decreaseScore > 0) {
        finalScore = (increaseScore + decreaseScore) / 2;
      } else if (increaseScore > 0) {
        finalScore = increaseScore;
      } else if (decreaseScore > 0) {
        finalScore = decreaseScore;
      }
      if (finalScore !== undefined) {
        scoreData.labels.push(labels[point.month - 1]);
        scoreData.datasets[0].data.push(finalScore);
      }
    });
    // Sort data in accending order
    points.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));

    // only show 3 months of data
    while (points.length > 3) {
      points.shift();
    }
  };
  if (formType === "Dashboard") {
    if (isImpactHistoryByConsultantSuccess) {
      calculateScoreDataPoints(impactHistoryByConsultantData.data);
    }
    if (isImpactHistoryByConsultantError) {
      calculateScoreDataPoints([]);
    }
  }

  if (formType === "Impact") {
    if (isImpactHistorySuccess) {
      console.log("impacct historyy......", impactHistoryData?.data);
      calculateScoreDataPointsForImpact(impactHistoryData.data);
    }

    if (isImpactHistoryError) {
      calculateScoreDataPointsForImpact([]);
    }
  }

  return (
    <>
      <div className='card equal-height'>
        <div className='card-body'>
          <div className='row'>
            <Line
              options={{
                scales: {
                  y: {
                    suggestedMin: 1,
                    suggestedMax: 10,
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
              data={scoreData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FocusScore;
