import { apiSlice } from "app/api";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserList: builder.query({
      query: () => `/userservice/users`,
      providesTags: ["User"],
    }),
    getAllUsers: builder.query({
      query: () => `/users`,
      providesTags: ["User"],
    }),
    getUsers: builder.query({
      query: () => `/users`,
    }),
    registerUser: builder.mutation({
      query: (userData) => ({
        url: `/users/`,
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    inviteUser: builder.mutation({
      query: (inviteUserInfo) => ({
        url: `/userservice/invitation/`,
        method: "POST",
        body: inviteUserInfo,
      }),
    }),
  }),
});

export const { useGetUserListQuery, useGetAllUsersQuery, useGetUsersQuery, useRegisterUserMutation, useInviteUserMutation } = userApiSlice;

