import { useState, useMemo, useCallback, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  useGetImpactQuery,
  useGetImpactDataPointsByImpactIdQuery,
  useAddImpactDataPointMutation,
} from "features/impacts/impactsSlice";
import { useUpdateImpactMutation } from "features/impacts/impactsSlice";
import EditTable from "./EditTable";
import AddTable from "./AddTable";
import { createColumnHelper } from "@tanstack/react-table";
import { monthOptions } from "common/utils/utils";
import { toast } from "react-toastify";

const AddEditModal = ({ impactId, show, handleClose }) => {
  const { data: impactDetails, isSuccess: isImpactSuccess } =
    useGetImpactQuery(impactId);
  const {
    data: impactDataPointsDetails,
    isSuccess: isImpactDataPointsSuccess,
  } = useGetImpactDataPointsByImpactIdQuery(impactId);

  const impactData = isImpactSuccess ? impactDetails.data : {};
  const datapointlist = isImpactDataPointsSuccess
    ? impactDataPointsDetails?.data
    : [];

  const [updateImpact] = useUpdateImpactMutation({
    refetchOnMountOrArgChange: true,
  });

  const [newDataPoints, setNewDataPoints] = useState([]);
  const [body, setBody] = useState({
    title: "",
    year: null,
    target_value: "",
    actual_value: "",
    impact: impactId,
  });
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [onSave, setOnSave] = useState(false);
  const [fiscalYearStart, setFiscalYearStart] = useState("");
  const [feedback, setFeedback] = useState({ error: "", successMessage: "" });
  const confirmationRef = useRef(null);

  const [addDataPoint] = useAddImpactDataPointMutation();

  const handleCloseModal = () => {
    setNewDataPoints([]);
    setEditingRowIndex(null);
    setFeedback({ error: "", successMessage: "" });
    handleClose();
  };
  
  const calculateRemainingValue = () => {
    const existingTargetSum = datapointlist.reduce(
      (acc, point) => acc + parseFloat(point.target_value || 0),
      0,
    );
    return impactData.overall_target_value - existingTargetSum;
  };

  const handleSave = async () => {
    
    if (newDataPoints.length > 0) {
      if (!body.target_value) {
        setFeedback({
          error: "The 'Target Value' field is required.",
        });
        return;
      }
      if (!body.actual_value) {
        setFeedback({
          error: "The 'Actual Value' field is required.",
        });

        setTimeout(() => setFeedback({ error: "" }), 3000);

        return;
      }
      if (body.actual_value<0 || body.target_value<0) {
        setFeedback({
          error: "The 'Target/Actual Value' cannot be negative.",
        });
        return;
      }
      const existingTargetSum = datapointlist.reduce(
        (acc, point) => acc + parseFloat(point.target_value || 0),
        0
      );
    
      const totalTargetSum = existingTargetSum + parseFloat(body.target_value);
    
      if (totalTargetSum > impactData.overall_target_value) {
        setFeedback({
          error: `The sum of target values for the data points exceeds the overall target value for Impact. Please enter a valid value.`,
        });
        setTimeout(() => setFeedback({ error: "" }), 3000);
        return;
      }
      try {
        const response = await addDataPoint({ body });
        if (response?.data?.status === "success") {
          setNewDataPoints([]);
          setFeedback({
            error: "",
            successMessage: "The data point has been added successfully.",
          });
          toast.success("The data point has been added successfully.");

          setTimeout(() => setFeedback({ successMessage: "" }), 3000);
        } else {
          toast.error(
            response?.error?.data?.message || "Failed to add data point",
          );
          const message = response?.error?.data?.message;
          if (message.includes("already exists")) {
            setFeedback({
              error: response?.error?.data?.message,
            });
          } else {
            setNewDataPoints([]);
          }
        }
      } catch (error) {
        toast.error(error?.message || "Failed to add data point");
        setFeedback({
          error: error?.message || "Failed to add the data point.",
        });
      }
    }

    if (fiscalYearStart) {
      let impact = {
        fiscal_year_start: fiscalYearStart,
      };

      try {
        await updateImpact({
          impactId: impactId,
          body: impact,
        });
        setFiscalYearStart("");
      } catch (error) {
        console.log(error);
      }
    }

    if (editingRowIndex !== null) {
      setOnSave(true);
    }

    setEditingRowIndex(null);
  };

  const handleCancelClick = () => {
    confirmationRef.current.style.display = "block";
    document
      .querySelectorAll("#saveButton, #cancelButton, #closeButton")
      .forEach((button) => {
        button.setAttribute("disabled", true);
      });
  };

  const handleConfirmCancel = () => {
    confirmationRef.current.style.display = "none";
    document
      .querySelectorAll("#saveButton, #cancelButton, #closeButton")
      .forEach((button) => {
        button.removeAttribute("disabled");
      });
    handleCloseModal();
  };

  const handleDismissCancel = () => {
    confirmationRef.current.style.display = "none";
    document
      .querySelectorAll("#saveButton, #cancelButton, #closeButton")
      .forEach((button) => {
        button.removeAttribute("disabled");
      });
  };

  const columnHelper = createColumnHelper();
  const trackingFrequency = impactData.tracking_frequency || "Quarterly";

  const columns = useMemo(() => {
    const baseColumns = [
      columnHelper.accessor("progress_check", { header: () => <span></span> }),
      columnHelper.accessor("title", {
        header: () => <span>{trackingFrequency}</span>,
      }),
      columnHelper.accessor("target_value", {
        header: () => (
          <span>
            Target
            <i
              className='bi bi-question-circle question-icon ms-1'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Define your target value here. This value is customizable and helps you work towards your overall target.'
              style={{ cursor: "pointer" }}
            ></i>
          </span>
        ),
      }),
      columnHelper.accessor("actual_value", { header: "Actual" }),
      columnHelper.accessor("cumulative_value", { header: "Cumulative" }),
      columnHelper.accessor("created_on_date", { header: "Date Reported" }),
    ];

    if (trackingFrequency !== "Yearly") {
      baseColumns.splice(
        2,
        0,
        columnHelper.accessor("year", { header: "Year" }),
      );
    }

    return baseColumns;
  }, [columnHelper, trackingFrequency]);

  const addNewRow = useCallback(() => {
    if (editingRowIndex !== null) return;
    if (newDataPoints?.length >= 1) return;
    let currentYear;
    currentYear = new Date().getFullYear();

    const quarterlyTitles = ["Q1", "Q2", "Q3", "Q4"];
    let nextTitle;

    if (trackingFrequency === "Quarterly") {
      const nextQuarterIndex =
        (datapointlist.length + newDataPoints.length) % quarterlyTitles.length;
      let additionalYears = 0;
      for (let i = 4; i <= datapointlist.length; i += 4) {
        additionalYears += 1;
      }
      currentYear = currentYear + additionalYears;
      nextTitle = quarterlyTitles[nextQuarterIndex];
    } else if (trackingFrequency === "Monthly") {
      let additionalYears = 0;
      const nextMonthIndex =
        (datapointlist.length + newDataPoints.length) % monthOptions.length;
      for (let i = 12; i <= datapointlist.length; i += 12) {
        additionalYears += 1;
      }
      currentYear = currentYear + additionalYears;
      nextTitle = monthOptions[nextMonthIndex];
    } else if (trackingFrequency === "Yearly") {
      const nextYearIndex =
        (datapointlist.length + newDataPoints.length);
      nextTitle = currentYear + nextYearIndex;
    }

    // Add the new row with the calculated title
    setNewDataPoints((prev) => [
      ...prev,
      {
        title: nextTitle,
        target_value: "",
        actual_value: "",
        cumulative_value: "",
        created_on_date: "",
        year: currentYear,
      },
    ]);

    // Update the body state with the new title
    setBody((prevBody) => ({
      ...prevBody,
      title: nextTitle,
      year: currentYear,
      target_value: "",
      actual_value: "",
    }));
  }, [datapointlist.length, newDataPoints, editingRowIndex, trackingFrequency]);

  return (
    <>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Body className="data-point">
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <Modal.Title>Add/Edit Data</Modal.Title>
            <Button
              id='closeButton'
              variant='close'
              onClick={handleCloseModal}
              aria-label='Close'
            ></Button>
          </div>

          <div className='d-flex align-items-center mb-3'>
            <div className='d-flex align-items-center me-4'>
              <Form.Label className='mb-0'>Overall Target:</Form.Label>
              <span className='ms-2'>{impactData.overall_target_value}</span>
            </div>

            <div className='d-flex align-items-center me-4'>
              <Form.Label className='mb-0'>Target Direction:</Form.Label>
              <span className='ms-2'>{impactData.target_direction}</span>
            </div>

            <div className='d-flex align-items-center'>
              <Form.Label className='mb-0'>Start of Fiscal Year:</Form.Label>
              <Form.Select
                className='ms-2'
                style={{ width: "auto" }}
                value={fiscalYearStart || impactData.fiscal_year_start || ""}
                onChange={(e) => setFiscalYearStart(e.target.value)}
              >
                <option>Select a month</option>
                {monthOptions.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>

          <EditTable
            defaultData={datapointlist}
            columns={columns}
            editingRowIndex={editingRowIndex}
            setEditingRowIndex={setEditingRowIndex}
            trackingFrequency={trackingFrequency}
            onSave={onSave}
            setOnSave={setOnSave}
            fiscalYearStart={fiscalYearStart}
            setFeedback={setFeedback}
            targetDirection={impactData.target_direction}
            newDataPoints={newDataPoints}
            overallTargetValue={impactData.overall_target_value}
          />
          <AddTable
            defaultData={newDataPoints}
            columns={columns}
            body={body}
            trackingFrequency={trackingFrequency}
            setBody={setBody}
            calculateRemainingValue={calculateRemainingValue}
          />

          <div
            className='d-flex justify-content-start mt-3'
            onClick={addNewRow}
          >
            <i
              className='bi bi-plus-circle-fill'
              style={{ color: "#0d6efd", fontSize: "20px" }}
            ></i>
            <span className='ms-2 mt-1'>Add a line</span>
          </div>

          <div className='d-flex justify-content-end mt-4'>
            {feedback.error && (
              <div className='text-danger me-auto mb-2'>{feedback.error}</div>
            )}
            {feedback.successMessage && (
              <div className='text-success me-auto mb-2'>
                {feedback.successMessage}
              </div>
            )}
            <Button
              id='cancelButton'
              variant='outline-secondary'
              onClick={handleCancelClick}
              className='me-2'
            >
              Cancel
            </Button>
            <Button id='saveButton' variant='primary' onClick={handleSave}>
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <div
        ref={confirmationRef}
        style={{
          display: "none",
          position: "fixed",
          zIndex: 9999,
          background: "white",
          padding: "20px",
          border: "1px solid gray",
          borderRadius: "8px",
          top: "10%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <p>
          Are you sure you want to cancel? Any unsaved changes will be lost.
        </p>
        <div className='d-flex justify-content-end  mt-4 pt-33'>
          <Button
            variant='outline-secondary'
            onClick={handleDismissCancel}
            className='me-2'
          >
            Cancel
          </Button>
          <Button variant='danger' onClick={handleConfirmCancel}>
            Confirm
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddEditModal;
