import moment from 'moment';

function Dates({ created, started, ended, lastModified }) {
  const formatDate = (dateString) => {
    return moment(dateString).format('MM/DD/YYYY');
  };

  return (
    <>
      <p className='inventory-item'>Created: {formatDate(created)}</p>
      <p className='inventory-item'>Started: {formatDate(started)}</p>
      <p className='inventory-item'>Ended: {formatDate(ended)}</p>
      <p className='inventory-item'>
        Last Modified: {formatDate(lastModified)}
      </p>
    </>
  );
}

export default Dates;
