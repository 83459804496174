import { useLocation } from "react-router-dom";
import {
  InitiativeDetail,
  InitiativeBarGraph,
  InitBreadCrumb,
} from "features/initiatives";
import { ProjectByTypeSnapshot } from "features/projects";
import { Task } from "common";
import {
  useUpdateTaskMutation,
  useGetInitiativeTasksQuery,
} from "features/tasks/tasksSlice";
import {
  useGetCommentsQuery,
  useAddCommentsMutation,
} from "common/comments/commentSlice";
import { Comment } from "common";
import { store } from "app/store";
import { skipToken } from "@reduxjs/toolkit/query";

function Initiative() {
  let tasks = [];
  const userId = store.getState().auth.userData.id;

  const location = useLocation();
  const { initiativeId } = (location && location.state) || {};
  const numIncompleteTask = 3;

  const [updateTask] = useUpdateTaskMutation({
    refetchOnMountOrArgChange: true,
  });

  const { data, isSuccess } = useGetInitiativeTasksQuery(
    initiativeId ? initiativeId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (isSuccess) {
    tasks = data.data;
  }

  let initComments = [];
  let type = "initiative";
  let parentId = initiativeId;
  const { data: initCommentData, isSuccess: isCommentSuccess } =
    useGetCommentsQuery(initiativeId ? { type, parentId } : skipToken, {
      refetchOnMountOrArgChange: true,
    });

  if (isCommentSuccess && initCommentData.comments) {
    initComments = initCommentData.comments;
  }
  const [addInitComments] = useAddCommentsMutation({
    refetchOnMountOrArgChange: true,
  });
  const handleCommentSubmit = async (formData) => {
    formData.append("comment_type_id", parentId);
    formData.append("user_id", userId);
    formData.append("type", type);
    await addInitComments(formData);
  };

  return (
    <>
      <div className='container g-4'>
        <div className='row'>
          <nav aria-label='breadcrumb'>
            <InitBreadCrumb />
          </nav>
        </div>
        <div id='initiative-detail' className='row'>
          <InitiativeDetail />

          <div className='col'>
            <div className='row mb-4'>
              <div className='col-4 d-flex'>
                <ProjectByTypeSnapshot
                  type='INITIATIVE'
                  goalOrInitOrClientId={initiativeId}
                />
              </div>
              <div className='col d-flex'>
                <div id='initiative-tasks' className='card equal-height'>
                  <div className='card-header d-flex justify-content-between'>
                    <span>Tasks</span>
                    <div className='btn-group' role='group'>
                      <div className='dropdown'>
                        <button
                          className='option-btn'
                          data-bs-toggle='dropdown'
                          type='button'
                        >
                          <i
                            className='bi bi-three-dots ms-2'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Options'
                          ></i>
                        </button>
                        <ul className='dropdown-menu options'>
                          <li>
                            <a className='dropdown-item' href='/'>
                              Filter
                            </a>
                          </li>
                          <li>
                            <a className='dropdown-item' href='/'>
                              Taskboard
                            </a>
                          </li>
                          <hr className='dropdown-divider'></hr>
                          <li>
                            <a className='dropdown-item' href='/'>
                              Style
                            </a>
                          </li>
                          <li>
                            <a className='dropdown-item' href='/'>
                              Reset
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    <Task
                      tasks={tasks}
                      completedSection={false}
                      updateTask={updateTask}
                      numIncompleteTask={numIncompleteTask}
                      breadcrumb='initiative'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-4'>
              <InitiativeBarGraph />
            </div>
            <Comment
              comments={initComments}
              handleCommentSubmit={handleCommentSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Initiative;
