import { apiSlice } from "app/api";

export const decisionPointApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDecisionPointByGoal: builder.query({
      query: (goalId) => `/initiatives/decisionpointlist/?goal_id=${goalId}`,
      providesTags: ["DecisionPoint"],
    }),
      getDecisionPoint: builder.query({
        query: (decisionPointId) => `/initiatives/decisionpoint/${decisionPointId}`,
        providesTags: ["DecisionPoint"],
      }),
    addDecisionPoint: builder.mutation({
      query: (goal) => ({
        url: `/initiatives/decisionpoint/`,
        method: "POST",
        body: goal,
      }),
      invalidatesTags: ["DecisionPoint"],
    }),
    updateDecisionPoint: builder.mutation({
      query: ({ decisionPointId, ...body }) => ({
        url: `/initiatives/decisionpoint/${decisionPointId}`,
        method: "PATCH",
        ...body,
      }),
      invalidatesTags: ["DecisionPoint"],
    }),
    deleteDecisionPoint: builder.mutation({
      query: (decisionPointId) => ({
        url: `/initiatives/decisionpoint/${decisionPointId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DecisionPoint"],
    }),
  }),
});

export const {
  useGetDecisionPointByGoalQuery,
  useAddDecisionPointMutation,
  useGetDecisionPointQuery,
  useUpdateDecisionPointMutation,
  useDeleteDecisionPointMutation,
} = decisionPointApiSlice;
