import { apiSlice } from "app/api";

const organizationSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		registerOrganization: builder.mutation({
			query: (organizationData) => {
				return {
					url: `/company/`,
					method: "POST",
					body: organizationData,
				};
			},
			invalidatesTags: ["Organization"],

		}),
		
	}),
	overrideExisting: false,
});

export const { useRegisterOrganizationMutation } = organizationSlice;
