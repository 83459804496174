import UserList from "features/user/UserList";

function Users() {
  return (
    <>
      <UserList />
    </>
  );
}

export default Users;
