import { Table } from "common";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { InitiativeForm } from "features/initiatives";
import {
  useGetConsultantInitiativesQuery,
  useDeleteInitiativeMutation,
  useGetAccountInitiativesQuery,
} from "features/initiatives/initiativesSlice";
import { store } from "app/store";
import { createColumnHelper } from "@tanstack/react-table";
import { useGetUserListQuery } from "features/user/userSlice";
import { formatDate } from "common/utils";
import { toast } from "react-toastify";

function Initiatives() {
  let userList = [];
  let initListByConsultant = [];
  let initListByAccount = [];
  let consultantId;
  let accountId;
  const [model, setModel] = useState(false);
  const [initiativeId, setInitiativeId] = useState();
  const [deleteInitiativeName, setDeleteInitiativeName] = useState("");
  const [viewAll, setviewAll] = useState("MINE");
  const DELETE_DIALOG_TITLE = "Initiatives";
  const DELETE_DIALOG_MESSAGE = `Are you sure you want to delete "${deleteInitiativeName}" this initiative?`;

  if (store.getState().auth.userData)
    consultantId = store.getState().auth.userData.id;
  if (store.getState().auth.userData === null) accountId = 0;
  else accountId = store.getState().auth.userData.organization;

  const {
    data: initiativeByConsultantInfo,
    isSuccess: isInitiativeByConsultantSuccess,
  } = useGetConsultantInitiativesQuery(consultantId, {
    refetchOnMountOrArgChange: true,
  });

  if (isInitiativeByConsultantSuccess) {
    initListByConsultant = initiativeByConsultantInfo.data;
  }

  const {
    data: initiativeByAccountInfo,
    isSuccess: isInitiativeByAccountSuccess,
  } = useGetAccountInitiativesQuery(accountId, {
    refetchOnMountOrArgChange: true,
  });
  if (isInitiativeByAccountSuccess) {
    initListByAccount = initiativeByAccountInfo.data;
  }

  const [deleteInitiative] = useDeleteInitiativeMutation();

  const handleUserList = useGetUserListQuery({
    refetchOnMountOrArgChange: true,
  });

  if (handleUserList.isSuccess === true) {
    userList = handleUserList.data.data;
  }

  const deleteDialog = (initiativeId, initiativeName) => {
    setInitiativeId(initiativeId);
    setDeleteInitiativeName(initiativeName);
    return setModel(true);
  };

  const hideDialog = () => {
    setModel(false);
  };

  const deleteHandle = async () => {
    if (initiativeId) {
      const deleted = await deleteInitiative(initiativeId);
      if (deleted?.data?.status === "success")
        toast.success("Deleted successfully.");
      setInitiativeId(null);
      setDeleteInitiativeName("");
    }

    setModel(false);
  };

  const formatOwnerConsultant = (id) => {
    if (id) {
      const consultant = userList.find((user) => user.id === id);
      return consultant
        ? `${consultant.first_name} ${consultant.last_name}`
        : "";
    }
    return "";
  };

  let defaultData = [];

  if (viewAll === "ALL") {
    defaultData = initListByAccount || [];
  }
  if (viewAll === "MINE") {
    defaultData = initListByConsultant || [];
  }
  const columnHelper = createColumnHelper();

  const columnsAll = [
    columnHelper.accessor((row) => row.title, {
      id: "title",
      cell: (info) => (
        <Link
          to={`/initiatives/${info.row.original.id}`}
          state={{ initiativeId: `${info.row.original.id}` }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor((row) => row.stage, {
      id: "stage",
      cell: (info) => (
        <>
          {info.row.original.stage === "Not-Started" ? (
            <span className='badge text-bg-danger'>
              {info.row.original.stage}
            </span>
          ) : info.row.original.stage === "In-Progress" ? (
            <span className='badge text-bg-warning'>
              {info.row.original.stage}
            </span>
          ) : (
            <span className='badge text-bg-success'>
              {info.row.original.stage}
            </span>
          )}
        </>
      ),
      header: () => <span>Stage</span>,
    }),
    columnHelper.accessor((row) => row.end_date, {
      id: "end_date",
      cell: (info) => formatDate(info.getValue()),
      header: () => <span>End Date</span>,
    }),

    columnHelper.accessor((row) => row.owner_consultant, {
      id: "owner_consultant",
      cell: (info) => formatOwnerConsultant(info.getValue()),
      header: () => <span>Owner Consultant</span>,
      show: viewAll === "ALL",
    }),

    columnHelper.accessor((row) => row.goal_title, {
      id: "goal_title",
      cell: (info) => (
        <Link
          to={`/goals/${info.row.original.goal}`}
          state={{ goalId: info.row.original.goal }}
          style={{ textDecoration: "none" }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Belongs To</span>,
    }),

    {
      id: "actions",
      cell: (info) => (
        <div>
          <span className='action-item'>
            <InitiativeForm
              formInputs={{
                formType: "Initiative",
                formAction: "Edit",
                itemDetails: {
                  id: info.row.original.id,
                },
              }}
            />
          </span>
          <span className='action-item'>
            <i
              className='bi bi-trash-fill'
              data-bs-placment='center'
              title='Delete'
              onClick={() =>
                deleteDialog(info.row.original.id, info.row.original.title)
              }
            ></i>
          </span>
        </div>
      ),
    },
  ];

  const columnsMine = [
    columnHelper.accessor((row) => row.title, {
      id: "title",
      cell: (info) => (
        <Link
          to={`/initiatives/${info.row.original.id}`}
          state={{ initiativeId: `${info.row.original.id}` }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor((row) => row.stage, {
      id: "stage",
      cell: (info) => (
        <>
          {info.row.original.stage === "Not-Started" ? (
            <span className='badge text-bg-danger'>
              {info.row.original.stage}
            </span>
          ) : info.row.original.stage === "In-Progress" ? (
            <span className='badge text-bg-warning'>
              {info.row.original.stage}
            </span>
          ) : (
            <span className='badge text-bg-success'>
              {info.row.original.stage}
            </span>
          )}
        </>
      ),
      header: () => <span>Stage</span>,
    }),
    columnHelper.accessor((row) => row.end_date, {
      id: "end_date",
      cell: (info) => formatDate(info.getValue()),
      header: () => <span>End Date</span>,
    }),

    columnHelper.accessor((row) => row.goal_title, {
      id: "goal_title",
      cell: (info) => (
        <Link
          to={`/goals/${info.row.original.goal}`}
          state={{ goalId: info.row.original.goal }}
          style={{ textDecoration: "none" }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Belongs To</span>,
    }),

    {
      id: "actions",
      cell: (info) => (
        <div>
          <span className='action-item'>
            <InitiativeForm
              formInputs={{
                formType: "Initiative",
                formAction: "Edit",
                itemDetails: {
                  id: info.row.original.id,
                },
              }}
            />
          </span>
          <span className='action-item'>
            <i
              className='bi bi-trash-fill'
              data-bs-placment='center'
              title='Delete'
              onClick={() =>
                deleteDialog(info.row.original.id, info.row.original.title)
              }
            ></i>
          </span>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <h1 style={{ margin: "0" }}>Initiatives</h1>
        </div>
        <div className='col-1 mt-2'>
          <Form>
            <Form.Check
              type='radio'
              id='custom-switch'
              label='View all'
              checked={viewAll === "ALL"}
              onChange={() => {
                setviewAll("ALL");
              }}
            />
          </Form>
        </div>
        <div className='col-2 mt-2'>
          <Form>
            <Form.Check
              type='radio'
              id='custom-switch'
              label='View mine'
              checked={viewAll === "MINE"}
              onChange={() => {
                setviewAll("MINE");
              }}
            />
          </Form>
        </div>
        <div className='col text-end pr-5'>
          <InitiativeForm
            formInputs={{
              formType: "Initiative",
              formAction: "Add",
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          {viewAll === "ALL" ? (
            <Table
              defaultData={defaultData}
              columns={columnsAll}
              DELETE_DIALOG_TITLE={DELETE_DIALOG_TITLE}
              DELETE_DIALOG_MESSAGE={DELETE_DIALOG_MESSAGE}
              model={model}
              hideDialog={hideDialog}
              onDelete={deleteHandle}
            />
          ) : (
            <Table
              defaultData={defaultData}
              columns={columnsMine}
              DELETE_DIALOG_TITLE={DELETE_DIALOG_TITLE}
              DELETE_DIALOG_MESSAGE={DELETE_DIALOG_MESSAGE}
              model={model}
              hideDialog={hideDialog}
              onDelete={deleteHandle}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Initiatives;
