import { Link } from "react-router-dom";
import { logOut } from "features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { store } from "app/store";
import Avatar from "react-avatar";
import { useGetUserListQuery } from "features/user/userSlice";
import { useLogoutUserMutation } from "features/auth/authApiSlice";

function UserProfileTool() {
  let consultantId;
  let organizationId;// This is the company_uuid of the logged-in user.
  let userList = [];
  let consultant = null;
  let isLoading = true;

  if (store.getState().auth.userData)
    consultantId = store.getState().auth.userData.id;

  const handleUserList = useGetUserListQuery({
    refetchOnMountOrArgChange: true,
  });

  if (handleUserList.isSuccess && handleUserList.data) {
    userList = handleUserList.data.data;
    isLoading = false;
  }

  if (consultantId) {
    consultant = userList.find((user) => user.id === consultantId);
  }

  const formatConsultant = (consultant) => {
    return consultant ? `${consultant.first_name} ${consultant.last_name}` : "";
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [handleUserLogout] = useLogoutUserMutation();
  const handleLogout = () => {
    handleUserLogout({}).then((res) => {
      dispatch(logOut());
      navigate("/login");
    });
  };
  return (
    <>
      <div className='dropdown'>
        {!isLoading && consultantId && consultant && (
          <button
            id='profile-tool'
            className='btn-basic'
            data-bs-toggle='dropdown'
          >
            <Avatar
              name={formatConsultant(consultant)}
              size='40'
              color='#5784bb'
              textSizeRatio={1.75}
              textMarginRatio={0.2}
              round={true}
            />
          </button>
        )}
        <ul className='dropdown-menu small-dropdown py-1'>
          <li>
            <Link to='profile' className='dropdown-item'>
              View Profile
            </Link>
          </li>
          <hr className='dropdown-divider my-1 mx-2' />
          <li>
          <Link
              to={`/organization/${organizationId}`}
              state={{ organizationId: organizationId }}
              className='dropdown-item'
            >
              View Organization
            </Link>
          </li>
          <hr className='dropdown-divider my-1 mx-2' />
          <li>
            <Link
              to='#'
              onClick={() => handleLogout()}
              className='dropdown-item'
            >
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default UserProfileTool;
