import { skipToken } from "@reduxjs/toolkit/query";
import { Snapshot, parseResults } from "common";
import { useGetVendorGoalsQuery } from "./goalsSlice";

export const GOALTYPE = {
  VENDOR: "VENDOR",
};

export const GoalByTypeSnapshot = ({ type, clientId }) => {
  let functionType;
  if (type === GOALTYPE.VENDOR) {
    functionType = useGetVendorGoalsQuery;
  } else {
    // type missing
  }
  let content = {
    labels: ["NO DATA"],
    data: [0.00001],
    quanity: 0,
  };

  // const { goalId } = (location && location.state) || {};
  const { data, isLoading, isError, isSuccess } = functionType(
    clientId ? clientId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (isLoading) {
    content = { data: [0.1], labels: ["Loading"] };
  } else if (isSuccess && !isError) {
    let results = parseResults(data.data);
    content.labels = results.map((a) => a.key);
    content.data = results.map((a) => a.value);
    content.quanity = content.data.reduce((a, b) => a + b, 0);
  }

  return (
    <>
      <Snapshot
        title='Goals'
        quanity={content.quanity}
        data={content.data}
        labels={content.labels}
      />
    </>
  );
};
