import { Modal, Button, Form } from "react-bootstrap";
import { useState } from "react";

function InviteUser({ show, handleClose, handleInvite }) {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const name = "Chris G";
  const company_name = "A-G";
  const invited_by_id = "642ac7c1-f86a-41bf-87f2-fd55a843f82f";
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };
  const handleSubmit = () => {
    handleInvite({ email, name, company_name, invited_by_id, role });
    handleClose();
    setEmail('');
    setRole('');
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Invite User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId='formEmail'>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              value={email}
              onChange={handleEmailChange}
            />
          </Form.Group>
          <Form.Group controlId='formRole'>
            <Form.Label>Role</Form.Label>
            <Form.Control as='select' value={role} onChange={handleRoleChange}>
              <option value='admin'>Admin</option>
              <option value='user'>User</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='primary' onClick={handleSubmit}>
          Invite
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InviteUser;
