import { useState } from "react";
import img from "./ChrisProfileIcon.jpg";
import UserModal from "features/user/UserModal";
import { useGetUsersQuery, useInviteUserMutation } from "./userSlice";
import InviteUser from "./InviteUser";
import { ErrorHandler } from "common";
import { Button } from "react-bootstrap";

function UserList() {
  let users = [];
  const [showModal, setShowModal] = useState(false);
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [inviteuser] = useInviteUserMutation();
  const handleShowModal = () => setShowInviteUserModal(true);
  const handleCloseModal = () => setShowInviteUserModal(false);

  //Currently fetching all users. Needs to be changed to fetching specific users of the company
  const handleUsersList = useGetUsersQuery({
    refetchOnMountOrArgChange: true,
  });

  if (handleUsersList.isSuccess === true) {
    users = handleUsersList.data;
  }
  const [searchUser, setSearchUser] = useState("");
  const handleSearchChange = (e) => {
    setSearchUser(e.target.value);
  };
  const filteredUserList = users.filter((user) =>
    `${user.first_name} ${user.last_name}`
      .toLowerCase()
      .includes(searchUser.toLowerCase()),
  );

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };
  const handleInvite = async (data) => {
    try {
      const result = await inviteuser(data);
      if (result.error) {
        setApiError(result);
      }
    } catch {
      setApiError("An error occurred");
    }
  };
  const onClose = () => {
    setApiError(null);
  };

  return (
    <>
      <div className='container'>
        <div className='row mb-4'>
          <div className='col-md-10'>
            <input
              type='text'
              className='form-control search-bar'
              placeholder='Search users in your company...'
              value={searchUser}
              onChange={handleSearchChange}
            />
          </div>
          <div className='col-md-2'>
            <Button className='btn-sm my-2' onClick={handleShowModal}>
              Invite Users
            </Button>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            {handleUsersList.isSuccess && filteredUserList.length > 0 ? (
              filteredUserList.map((user) => (
                <div
                  key={user.id}
                  className='user-card'
                  onClick={() => handleUserClick(user)}
                >
                  <div className='user-info'>
                    <img src={img} alt='User' className='user-img' />
                    <div>
                      <h5 className='mb-1'>
                        {user.first_name} {user.last_name}
                      </h5>
                      <p className='mb-0'>Admin</p>
                    </div>
                  </div>
                  <div>
                    <span className='action-item'>
                      <i
                        className='bi bi-pencil-square'
                        data-bs-toggle='tooltip'
                        data-bs-placment='top'
                        title='Edit'
                      ></i>
                    </span>
                    <span className='action-item'>
                      <i
                        className='bi bi-trash-fill'
                        data-bs-placment='center'
                        title='Delete'
                      ></i>
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className='row'>
                <div className='col-md-12'> No other users found</div>
              </div>
            )}
            {apiError ? (
              <ErrorHandler error={apiError} onClose={onClose} />
            ) : null}
            <InviteUser
              show={showInviteUserModal}
              handleClose={handleCloseModal}
              handleInvite={handleInvite}
            />
          </div>
        </div>
      </div>
      {showModal && (
        <UserModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setSelectedUser(null);
          }}
          user={selectedUser}
        />
      )}
    </>
  );
}

export default UserList;
