import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip);

const Snapshot = (props) => {
  const data = {
    labels: props.labels,
    datasets: [
      {
        data: props.data,
        backgroundColor: function (context) {
          const label = context.chart.data.labels[context.dataIndex];
          if (label === "Not-Started") return "#dc9396";
          else if (label === "In-Progress") return "#e8d495";
          else if (label === "Completed") return "#a9c8c0";
          else if (label === "Incomplete") return "#dc93a6";
          else return "#F8F8F8";
        },
        borderColor: ["rgba(255, 255, 255, 1)"],
        borderWidth: props.data === 0 ? 0 : 2,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <>
      <div className='card equal-height' onClick={props.onClick}>
        <div className='card-body'>
          <span className='type-title'>{props.title}</span>
          <div className='row'>
            <div className='col-6'>
              <Pie
                data={data}
                height={120}
                width={120}
                options={options}
              />
            </div>
            <div className='col-6 d-flex flex-column align-items-center'>
              <span className='total-title'>Quantity</span>
              <p className='total-body'>
                {props.quanity < 10
                  ? ("0" + props.quanity).slice(-2)
                  : props.quanity}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Snapshot;
