import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useIsTokenValidQuery } from "features/auth/authApiSlice";
import { logOut, authData } from "features/auth/authSlice";

const RouteHandler = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(authData);

  const {
    data: isValidToken,
    isError,
    isLoading,
  } = useIsTokenValidQuery(token, {
    skip: !token,
  });

  useEffect(() => {
    if (!isLoading && (!token || isError || !isValidToken)) {
      dispatch(logOut());
    }
  }, [token, isError, isValidToken, isLoading, dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!token || isError || !isValidToken) {
    return <Navigate to='/login' replace />;
  }

  return <Outlet />;
};

export default RouteHandler;
