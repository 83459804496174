// Created a generic focus score calaculation Method.

const getScore = (difference) => {
  if (difference > 0) {
    return Math.min(Math.ceil(difference * 10), 10);
  }
  return 0;
};

const getScoreForDecrease = (difference) => {
  const ranges = [
    { min: 0, max: 0.5, score: 10 },
    { min: 0.5, max: 1, score: 9 },
    { min: 1, max: 1.5, score: 8 },
    { min: 1.5, max: 2, score: 7 },
    { min: 2, max: 2.5, score: 6 },
    { min: 2.5, max: 3, score: 5 },
    { min: 3, max: 3.5, score: 4 },
    { min: 3.5, max: 4, score: 3 },
    { min: 4, max: 4.5, score: 2 },
  ];

  for (const { min, max, score } of ranges) {
    if (difference > min && difference <= max) {
      return score;
    }
  }

  return 0; // Default score if no range matches
};

const average = (array) => {
  let sum = 0;
  array.map((value) => (sum += value));
  return sum / array.length;
};

const initDataPoints = () => {
  const data = [];
  const now = new Date();
  now.setDate(1);
  now.setHours(0, 0, 0, 0);

  for (let i = 1; i <= 3; i++) {
    const date = new Date(now);
    date.setMonth(now.getMonth() - i);

    data.push({
      id: date.getTime(),
      overallCumulativeValue: [],
      targetValues: [],
      score: 0,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    });
  }
  return data;
};

export const calculateScoreDataPoints = ({ data, noRecord }) => {
  let scoreData = 0;
  let points = initDataPoints().map((point) => ({
    ...point,
    increaseOverallCumulativeValue: [],
    increaseTargetValues: [],
    decreaseOverallCumulativeValue: [],
    decreaseTargetValues: [],
  }));

  const lastEntries = {};
  data?.forEach((item) => {
    const impactId = item.impact_id;
    const date = new Date(item.modified_on_date + " 00:00");

    let firstOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    // used to group data points by there month and year
    const epoch = firstOfTheMonth.getTime();
    const key = `${impactId}_${epoch}`;
    lastEntries[key] = {
      impactId: impactId,
      epoch: epoch,
      overallCumulativeValue: parseFloat(item.overall_cumulative_value),
      targetValues: parseFloat(item.overall_target_value),
      targetDirection: item.target_direction,
    };
  });
  Object.keys(lastEntries)?.forEach((key) => {
    const lastEntry = lastEntries[key];
    const month = new Date(parseInt(lastEntry.epoch)).getMonth() + 1;
    let point = points.find((p) => p.month === month);
    if (!point) {
      point = {
        month: month,
        increaseOverallCumulativeValue: [],
        increaseTargetValues: [],
        decreaseOverallCumulativeValue: [],
        decreaseTargetValues: [],
      };
      points.push(point);
    }
    if (lastEntry.targetDirection === "Increase") {
      point.increaseOverallCumulativeValue.push(
        lastEntry.overallCumulativeValue,
      );
      point.increaseTargetValues.push(lastEntry.targetValues);
    } else if (lastEntry.targetDirection === "Decrease") {
      point.decreaseOverallCumulativeValue.push(
        lastEntry.overallCumulativeValue,
      );
      point.decreaseTargetValues.push(lastEntry.targetValues);
    }
  });
  points?.forEach((point) => {
    let increaseScore = 0;
    let decreaseScore = 0;
    if (
      point.increaseOverallCumulativeValue &&
      point.increaseOverallCumulativeValue.length > 0 &&
      point.increaseTargetValues.length > 0
    ) {
      const increaseCurrentAverage = average(
        point.increaseOverallCumulativeValue,
      );
      const increaseTargetAverage = average(point.increaseTargetValues);
      increaseScore = getScore(increaseCurrentAverage / increaseTargetAverage);
    }

    if (
      point.decreaseOverallCumulativeValue &&
      point.decreaseOverallCumulativeValue.length > 0 &&
      point.decreaseTargetValues.length > 0
    ) {
      const decreaseCurrentAverage = average(
        point.decreaseOverallCumulativeValue,
      );
      const decreaseTargetAverage = average(point.decreaseTargetValues);

      decreaseScore = getScoreForDecrease(
        decreaseCurrentAverage / decreaseTargetAverage,
      );
    }
    let finalScore;
    if (increaseScore > 0 && decreaseScore > 0) {
      finalScore = (increaseScore + decreaseScore) / 2;
    } else if (increaseScore > 0) {
      finalScore = increaseScore;
    } else if (decreaseScore > 0) {
      finalScore = decreaseScore;
    }
    if (finalScore !== undefined) {
      scoreData = finalScore;
    }
  });
  // Sort data in accending order and get the most recent month data.
  points.sort((a, b) => parseFloat(a.id) - parseFloat(b.id)).slice(noRecord);
  return scoreData;
};

export const calculateOverallFocusScore = ({ data }) => {
  let totalIncreaseScore = 0;
  let totalDecreaseScore = 0;
  let increaseCount = 0;
  let decreaseCount = 0;

  data?.forEach((item) => {
    const overallCumulativeValue = parseFloat(item.overall_cumulative_value);
    const targetValue = parseFloat(item.overall_target_value);
    const targetDirection = item.target_direction;

    if (targetDirection === "Increase") {
      const score = getScore(overallCumulativeValue / targetValue);
      totalIncreaseScore += score;
      increaseCount += 1;
    } else if (targetDirection === "Decrease") {
      const score = getScoreForDecrease(overallCumulativeValue / targetValue);
      totalDecreaseScore += score;
      decreaseCount += 1;
    }
  });

  let finalIncreaseScore =
    increaseCount > 0 ? totalIncreaseScore / increaseCount : 0;
  let finalDecreaseScore =
    decreaseCount > 0 ? totalDecreaseScore / decreaseCount : 0;

  let overallFocusScore = 0;

  if (finalIncreaseScore > 0 && finalDecreaseScore > 0) {
    overallFocusScore = (finalIncreaseScore + finalDecreaseScore) / 2;
  } else if (finalIncreaseScore > 0) {
    overallFocusScore = finalIncreaseScore;
  } else if (finalDecreaseScore > 0) {
    overallFocusScore = finalDecreaseScore;
  }

  return overallFocusScore;
};
