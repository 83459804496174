import { useState, useEffect } from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { yearOptions, monthOptions } from "common/utils";

function AddTable({ defaultData, columns, setBody, trackingFrequency, calculateRemainingValue }) {
  const [data, setData] = useState(() => [...defaultData]);

  useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      onCellEdit: (rowIndex, columnId, value) => {
        setData((oldData) =>
          oldData.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...row,
                [columnId]: value,
              };
            }
            return row;
          }),
        );

        setBody((prevBody) => ({
          ...prevBody,
          [columnId]: value,
        }));
      },
    },
  });

  const handleCellChange = (rowIndex, columnId, value) => {
    table.options.meta?.onCellEdit(rowIndex, columnId, value);
  };


  return (
    <div className='row'>
      <div className='col-12'>
        <table className='custom-impact-table'>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={
                      cell.column.id === "progress_check"
                        ? { width: "50px" }
                        : {}
                    }
                  >
                    {cell.column.id === "title" &&
                    trackingFrequency === "Yearly" ? (
                      <select
                        value={cell.getValue()}
                        onChange={(e) =>
                          handleCellChange(
                            row.index,
                            cell.column.id,
                            e.target.value,
                          )
                        }
                        className='editable-cell'
                        style={{
                          width: "100%",
                          border: "none",
                          padding: "5px",
                          boxSizing: "border-box",
                        }}
                      >
                        {yearOptions.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    ) : cell.column.id === "title" &&
                      trackingFrequency === "Monthly" ? (
                      <select
                        value={cell.getValue()}
                        onChange={(e) =>
                          handleCellChange(
                            row.index,
                            cell.column.id,
                            e.target.value,
                          )
                        }
                        className='editable-cell'
                        style={{
                          width: "100%",
                          border: "none",
                          padding: "5px",
                          boxSizing: "border-box",
                        }}
                      >
                        {monthOptions.map((month) => (
                          <option key={month} value={month}>
                            {month}
                          </option>
                        ))}
                      </select>
                    ) : cell.column.id === "year" ? (
                      <select
                        value={cell.getValue()}
                        onChange={(e) =>
                          handleCellChange(
                            row.index,
                            cell.column.id,
                            e.target.value,
                          )
                        }
                        className='editable-cell'
                        style={{
                          width: "100%",
                          border: "none",
                          padding: "5px",
                          boxSizing: "border-box",
                        }}
                      >
                        {yearOptions.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    ) : cell.column.id === "target_value" ? (
                      <input
                        type='number'
                        value={cell.getValue()}
                        title={
                          calculateRemainingValue() <= 0
                            ? "Overall target met, no remaining value required."
                            : `${calculateRemainingValue()} to reach overall target.`
                        }
                        onChange={(e) =>
                          handleCellChange(
                            row.index,
                            cell.column.id,
                            e.target.value,
                          )
                        }
                        className='editable-cell'
                        style={{
                          width: "100%",
                          border: "none",
                          padding: "5px",
                          boxSizing: "border-box",
                        }}
                      />
                    ) : cell.column.id === "actual_value" ? (
                      <input
                        type='number'
                        value={cell.getValue()}
                        onChange={(e) =>
                          handleCellChange(
                            row.index,
                            cell.column.id,
                            e.target.value,
                          )
                        }
                        className='editable-cell'
                        style={{
                          width: "100%",
                          border: "none",
                          padding: "5px",
                          boxSizing: "border-box",
                        }}
                      />
                    ) : cell.column.id === "cumulative_value" ? (
                      <div
                        className='non-editable-cell'
                        style={{ padding: "5px" }}
                      >
                        {cell.getValue()}
                      </div>
                    ) : cell.column.id === "created_on_date" ? (
                      <div
                        className='non-editable-cell'
                        style={{ padding: "5px" }}
                      >
                        {cell.getValue()}
                      </div>
                    ) : cell.column.id === "progress_check" ? (
                      <div
                        className='non-editable-cell'
                        style={{ padding: "5px" }}
                      >
                        {cell.getValue()}
                      </div>
                    ) : (
                      <input
                        value={cell.getValue()}
                        onChange={(e) =>
                          handleCellChange(
                            row.index,
                            cell.column.id,
                            e.target.value,
                          )
                        }
                        className='editable-cell'
                        style={{
                          width: "100%",
                          border: "none",
                          padding: "5px",
                          boxSizing: "border-box",
                        }}
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AddTable;
