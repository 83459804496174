import { GoalDetail } from "features/goals";
import { useGetGoalsQuery } from "features/goals/goalsSlice";
import { store } from "app/store";
import { useLocation } from "react-router-dom";
import { InitiativeByTypeSnapshot } from "features/initiatives";
import { ProjectByTypeSnapshot } from "features/projects";
import { TaskByTypeSnapshot } from "features/tasks";
import { InitiativeDropDown } from "features/impacts";
import { Comment } from "common";
import { Link } from "react-router-dom";
import { useGetGoalImpactsQuery } from "features/impacts/impactsSlice";
import {
  useGetCommentsQuery,
  useAddCommentsMutation,
} from "common/comments/commentSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { PROJECTTYPE } from "features/projects/ProjectByTypeSnapshot";
import { INITIATIVETYPE } from "features/initiatives/InitiativeByTypeSnapshot";
import { TASKTYPE } from "features/tasks/TaskByTypeSnapshot";

function Goal() {
  const location = useLocation();
  const userId = store.getState().auth.userData.id;
  const { goalId } = (location && location.state) || {};
  let goalInfoForBreadCrumb = [];
  let goalComments = [];
  let type = "goal";
  let parentId = goalId;

  const { data: goalDataForBreadCrumb, isSuccess: isgoalSuccess } =
    useGetGoalsQuery(goalId ? goalId : skipToken, {
      refetchOnMountOrArgChange: true,
    });

  if (isgoalSuccess) {
    goalInfoForBreadCrumb = goalDataForBreadCrumb.data;
  }

  const { data: goalCommentData, isSuccess: isCommentSuccess } =
    useGetCommentsQuery(
      { type, parentId },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  if (isCommentSuccess && goalCommentData.comments) {
    goalComments = goalCommentData.comments;
  }
  const [addGoalComments] = useAddCommentsMutation({
    refetchOnMountOrArgChange: true,
  });
  const handleCommentSubmit = async (formData) => {
    formData.append("comment_type_id", parentId);
    formData.append("user_id", userId);
    formData.append("type", type);
    await addGoalComments(formData);
  };
  let impactByGoalInfo = [];

  const { data: impactListData, isSuccess: isImpactListSuccess } =
    useGetGoalImpactsQuery(goalId ? goalId : skipToken, {
      refetchOnMountOrArgChange: true,
    });

  if (isImpactListSuccess) {
    impactByGoalInfo = impactListData.data;
  }

  const renderCrumbs = () => {
    return (
      <>
        <div className='row'>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to={`/`}>Dashboard </Link>
              </li>
              <li
                className='breadcrumb-item'
                key={goalInfoForBreadCrumb.vendor_account}
              >
                <Link
                  to={`/clients/${goalInfoForBreadCrumb.vendor_account}`}
                  state={{
                    clientId: `${goalInfoForBreadCrumb.vendor_account}`,
                  }}
                >
                  Client Detail{" "}
                </Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                Goal Detail
              </li>
            </ol>
          </nav>
        </div>
      </>
    );
  };

  return (
    <>
      <div className='container g-4'>
        {renderCrumbs()}
        <div id='goal-detail' className='row'>
          <GoalDetail goalId={goalId} />
          <div className='col'>
            <div className='row mb-4'>
              <div className='col'>
                {" "}
                <InitiativeByTypeSnapshot
                  type={INITIATIVETYPE.GOAL}
                  id={goalId}
                />
              </div>
              <div className='col'>
                <ProjectByTypeSnapshot type={PROJECTTYPE.GOAL}  goalOrInitOrClientId={goalId} />
              </div>
              <div className='col'>
                <TaskByTypeSnapshot
                  type={TASKTYPE.GOAL}
                  goalOrClientId={goalId}
                />
              </div>
            </div>
            <InitiativeDropDown impactsInfo={impactByGoalInfo} />

            <Comment
              comments={goalComments}
              handleCommentSubmit={handleCommentSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Goal;
