import { useGetDecisionPointByGoalQuery } from "features/decision-point/decisionPointSlice";
import { Button } from "react-bootstrap";
import { AddDecisionPoint, EditDecisionPoint } from "features/decision-point";
import { useState } from "react";
import { convertDate } from "common/utils";
const DecisionPoint = ({ goalId, goalTitle }) => {
  let decisionPointList = [];
  let decisionPointcount;

  const [decisionPointId, setDecisionPointId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const handleShow = () => {
    setShowModal(true);
  };
  const handleClose = () => setShowModal(false);
  const handleEditModalShow = (id) => {
    setShowEditModal(true);
    setDecisionPointId(id);
  };
  const handleEditModalClose = () => {
    setShowEditModal(false);
    setDecisionPointId(null);
  };

  const { data, isSuccess } = useGetDecisionPointByGoalQuery(goalId, {
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess && data?.data) {
    decisionPointList = data.data;
    decisionPointcount = data.data.length;
  }

  return (
    <div>
      {isSuccess && decisionPointcount > 0 ? (
        <>
          <div className='row d-flex justify-content-between mw-100'>
            <div className='col-8 fw-bold sm text-start'>Decision Point</div>
            <div className='col-4 fw-bold text-end p-0'>Date</div>
          </div>
          {decisionPointList.map((decisionPoint, idx) => (
            <>
              <div
                className='row d-flex justify-content-between mw-100'
                key={idx}
              >
                <div className='col-6 inventory-item p-0'>
                  <Button
                    variant='link'
                    onClick={() => handleEditModalShow(decisionPoint.id)}
                    title={decisionPoint.title}
                  >
                    {decisionPoint.title.length > 15
                      ? `${decisionPoint.title.slice(0, 15)}...`
                      : decisionPoint.title}
                  </Button>
                </div>
                <div className='col-6 inventory-item text-end mt-2 p-0'>
                  {convertDate(decisionPoint.milestone_date)}
                </div>
              </div>
            </>
          ))}
          <EditDecisionPoint
            decisionPointId={decisionPointId}
            goalId={goalId}
            goalTitle={goalTitle}
            show={showEditModal}
            handleClose={handleEditModalClose}
          />
          <br></br>
          <div className='mt-2'>
            <Button
              variant='primary'
              className='btn-sm me-2'
              onClick={handleShow}
            >
              <i className='bi bi-plus-circle-fill'></i>
              <span className='ms-2'>Add Decision Point</span>
            </Button>
          </div>
          <AddDecisionPoint
            show={showModal}
            handleClose={handleClose}
            goalId={goalId}
            goalTitle={goalTitle}
          />
        </>
      ) : (
        <>
          <p>No decision point available</p>
          <div className='mt-2'>
            <Button
              variant='primary'
              className='btn-sm me-2'
              onClick={handleShow}
            >
              <i className='bi bi-plus-circle-fill'></i>
              <span className='ms-2'>Add Decision Point</span>
            </Button>
          </div>{" "}
          <AddDecisionPoint
            show={showModal}
            handleClose={handleClose}
            goalId={goalId}
            goalTitle={goalTitle}
          />
        </>
      )}
    </div>
  );
};

export default DecisionPoint;
