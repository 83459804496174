import { skipToken } from "@reduxjs/toolkit/query";
import { Snapshot, parseResults } from "common";

import {
  useGetGoalInitiativesQuery,
  useGetVendorInitiativesQuery,
} from "./initiativesSlice";

export const INITIATIVETYPE = {
  GOAL: "GOAL",
  VENDOR: "VENDOR",
};

export const InitiativeByTypeSnapshot = ({ type, id }) => {
  let functionType;
  if (type === INITIATIVETYPE.GOAL) {
    functionType = useGetGoalInitiativesQuery;
  } else if (type === INITIATIVETYPE.VENDOR) {
    functionType = useGetVendorInitiativesQuery;
  } else {
    // missing type
  }

  let content = {
    labels: ["NO DATA"],
    data: [0.00001],
    quanity: 0,
  };

  const { data, isLoading, isError, isSuccess } = functionType(
    id ? id : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (isLoading) {
    content = { data: [0.1], labels: ["Loading"] };
  } else if (isSuccess && !isError) {
    let results = parseResults(data.data);
    content.labels = results.map((a) => a.key);
    content.data = results.map((a) => a.value);
    content.quanity = content.data.reduce((a, b) => a + b, 0);
  }

  return (
    <>
      <Snapshot
        title='Initiatives'
        quanity={content.quanity}
        data={content.data}
        labels={content.labels}
      />
    </>
  );
};
