function ValidationDecisionPoint(inputData) {
  const errors = {};
  const currentDate = new Date();
  const milestoneDate = new Date(inputData.milestone_date);

  if (!inputData.milestone_date) {
    errors.milestone_date = "Evaluation date is required";
  }
  if (!inputData.title) {
    errors.title = "Title is required";
  }
  if (!inputData.summary) {
    errors.summary = "Define decision Point is required";
  }
  if (inputData.title && inputData.title.length > 50) {
    errors.title = "Title should not be more than 50 characters";
  }
  if (inputData.summary && inputData.summary.length > 500) {
    errors.summary = "Define decision point should not be more than 500 characters";
  }
  if (milestoneDate < currentDate) {
    errors.milestone_date = "Please select a future date";
  }
  return errors;
}

export default ValidationDecisionPoint;