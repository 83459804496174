import { apiSlice } from "app/api";

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body) => {
        return {
          url: "/userservice/login/",
          method: "post",
          body,
        };
      },
    }),
    logoutUser: builder.mutation({
      query: (body) => {
        return {
          url: "/userservice/logout/",
          method: "post",
          body,
        };
      },
    }),
    isTokenValid: builder.query({
      query: () => `/userservice/token/`,
    }),
    userOnboarding: builder.mutation({
      query: (body) => {
        return {
          url: "users/onboard",
          method: "post",
          body,
        };
      },
    }),
    isInvitesToken: builder.mutation({
      query: (token) => {
        return {
          url: `invites/token/${token}`,
          method: "get",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useIsTokenValidQuery,
  useUserOnboardingMutation,
  useIsInvitesTokenMutation,
} = authApiSlice;
