import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const envUrls = {
  production: "https://focus-django-prod-ec168923d0d9.herokuapp.com",
  stage: "https://focus-django-stage-a3f09bb2c393.herokuapp.com",
  test: "https://focus-django-test-979def683208.herokuapp.com", // Default URL
};

const getEnvironmentUrl = () => {
  return process.env.NODE_ENV === "production"
    ? envUrls[process.env.REACT_APP_API_ENV] || envUrls.test
    : envUrls.test;
};

export const baseUrl = getEnvironmentUrl();
export const apiBaseUrl = baseUrl + "/api/v1/";

const baseQuery = fetchBaseQuery({
  baseUrl: apiBaseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `Token ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery: baseQuery,
  tagTypes: [
    "Goal",
    "Impact",
    "Initiative",
    "Project",
    "Tasks",
    "Comment",
    "Attachment",
    "Account",
    "User",
    "Organization",
    "Social",
    "Company",
    "DecisionPoint",
  ],
  endpoints: () => ({}),
});
