import { Link } from "react-router-dom";

// pass in the news item object from newsList.js map
function NewsItem({ title, comment, date }) {
  const dateObj = new Date(date);
  dateObj.setDate(dateObj.getDate() + 1);

  const formattedDate = `${(dateObj.getMonth() + 1).toString().padStart(2, "0")}/${dateObj.getDate().toString().padStart(2, "0")}/${dateObj.getFullYear()}`;

  return (
    <>
      <div className='list-group-item d-flex align-items-start'>
        <div className='flex-grow-1'>
          <div className='d-flex justify-content-between align-items-start'>
            <Link
              to={"news/#"}
              className='news-title d-block mb-0 text-primary text-decoration-none'
            >
              {title}
            </Link>
            <p className='text-muted small news-date-time'>{formattedDate}</p>
          </div>
          <p className='text-muted small'>{comment}</p>
        </div>
      </div>
    </>
  );
}

export default NewsItem;
