import { apiSlice } from "app/api";

const projectsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getProject: build.query({
      query: (projectId) => `projects/${projectId}`,
      providesTags: ["Project"],
    }),
    getConsultantProjects: build.query({
      query: (consultantId) => `projects/project_consultant/${consultantId}`,
      providesTags: ["Project"],
    }),
    getCustomerProjects: build.query({
      query: (customerId) => `projects/project_customer/${customerId}`,
      providesTags: ["Project"],
    }),
    getAccountProjects: build.query({
      query: (accountId) => `projects/project_account/${accountId}`,
      providesTags: ["Project"],
    }),
    getVendorProjects: build.query({
      query: (vendorAccountId) => `projects/project_vendor/${vendorAccountId}`,
      providesTags: ["Project"],
    }),
    getGoalProjects: build.query({
      query: (goalId) => `projects/project_goal/${goalId}`,
      providesTags: ["Project"],
    }),
    getInitiativeProjects: build.query({
      query: (initiativeId) => `projects/project_initiative/${initiativeId}`,
      providesTags: ["Project"],
    }),
    getHierarchyByProject: build.query({
      query: (projectId) => `/goals/hierarchybyproject/${projectId}`,
      providesTags: ["Project"],
    }),
    deleteProject: build.mutation({
      query: (projectId) => {
        return {
          url: `/projects/${projectId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Project"],
    }),
    addProject: build.mutation({
      query: ({ ...body }) => ({
        url: `/projects/`,
        method: "POST",
        ...body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateProject: build.mutation({
      query: ({ projectId, ...body }) => ({
        url: `/projects/${projectId}`,
        method: "PATCH",
        ...body,
      }),
      invalidatesTags: ["Project"],
    }),
    getProjectFilter: build.mutation({
      query: ({ search = "" }) => {
        const params = new URLSearchParams();
        if (search) params.append("search", search);
        return {
          url: `/projects?${params.toString()}`,
          method: "GET",
        };
      },
      invalidatesTags: ["Project"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProjectQuery,
  useGetConsultantProjectsQuery,
  useGetCustomerProjectsQuery,
  useGetAccountProjectsQuery,
  useGetVendorProjectsQuery,
  useGetGoalProjectsQuery,
  useGetInitiativeProjectsQuery,
  useGetHierarchyByProjectQuery,
  useDeleteProjectMutation,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useGetProjectFilterMutation,
} = projectsSlice;
