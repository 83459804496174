import { useGetDecisionPointByGoalQuery } from "features/decision-point/decisionPointSlice";

const Timeline = ({ goalId, startDate, endDate }) => {
  const { data: decisionPointsData, isLoading } =
    useGetDecisionPointByGoalQuery(goalId, {
      refetchOnMountOrArgChange: true,
    });

  if (isLoading) return <div>Loading decision points...</div>;
  if (!decisionPointsData?.data?.length)
    return <div>No decision points available for this goal.</div>;
  if (!startDate || !endDate)
    return (
      <div>
        No start date or end date available for this client. Please provide
        necessary information.
      </div>
    );

  const addOneDay = (dateStr) => {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + 1);
    return date;
  };

  startDate = addOneDay(startDate);
  endDate = addOneDay(endDate);

  const start = new Date(startDate);
  const originalEnd = new Date(endDate);

  const decisionPointDates = decisionPointsData.data.map(
    (point) => new Date(point.milestone_date),
  );
  const maxMilestoneDate = new Date(
    Math.max(...decisionPointDates.map((date) => date.getTime())),
  );
  const effectiveEnd =
    maxMilestoneDate > originalEnd ? maxMilestoneDate : originalEnd;

  const currentDate = new Date();
  const totalDuration = Math.ceil(
    (effectiveEnd - start) / (1000 * 60 * 60 * 24),
  );

  const marginPercent = 5;
  const effectiveRange = 100 - 2 * marginPercent;

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const calculatePosition = (date) => {
    const checkpointDate = new Date(date);
    const daysFromStart = Math.ceil(
      (checkpointDate - start) / (1000 * 60 * 60 * 24),
    );
    return `${marginPercent + (daysFromStart / totalDuration) * effectiveRange}%`;
  };

  const nearestDecisionPoint = decisionPointsData.data.reduce(
    (nearest, point) => {
      const milestoneDate = new Date(point.milestone_date);
      const daysDifference = Math.abs(
        (milestoneDate - currentDate) / (1000 * 60 * 60 * 24),
      );

      if (
        daysDifference <= 60 &&
        (!nearest || daysDifference < nearest.daysDifference)
      ) {
        return { ...point, daysDifference };
      }

      return nearest;
    },
    null,
  );

  const shouldShowCaret = !!nearestDecisionPoint;

  return (
    <div className='timeline-container'>
      <div className='timeline-line'></div>

      <div className='timeline-end-rule' style={{ left: `100%` }}></div>

      {decisionPointsData.data.map((point) => {
        const isPast = new Date(point.milestone_date) < currentDate;
        const hideLabel =
          shouldShowCaret && nearestDecisionPoint.id === point.id;

        return (
          <div
            key={point.id}
            className='timeline-checkpoint'
            style={{ left: calculatePosition(point.milestone_date) }}
          >
            <i
              className={`bi bi-signpost-split-fill ${isPast ? "grey-signpost" : "timeline-signpost"}`}
            ></i>
            <div className='timeline-dot'></div>
            <div className='timeline-tooltip'>
              <span className='timeline-date'>
                {formatDate(new Date(addOneDay(point.milestone_date)))}
              </span>
              <span className='timeline-title'>{point.title}</span>
            </div>
            {!hideLabel && <div className='timeline-label'>Decision Point</div>}
          </div>
        );
      })}

      <div
        className='timeline-current'
        style={{ left: calculatePosition(currentDate) }}
      >
        {shouldShowCaret ? (
          <i className='bi bi-caret-up timeline-caret-below'></i>
        ) : (
          <>
            <i className='bi bi-flag-fill timeline-flag'></i>
            <div className='timeline-dot current-dot'></div>
          </>
        )}
        <div className='timeline-tooltip'>
          <span className='timeline-date'>{formatDate(currentDate)}</span>
          <span className='timeline-title'>You Are Here</span>
        </div>
        {!shouldShowCaret && <div className='timeline-label'>You Are Here</div>}
      </div>

      <div
        className='timeline-end'
        style={{ left: calculatePosition(originalEnd) }}
      >
        <i className='bi bi-flag timeline-end-flag'></i>
        <div className='timeline-dot end-dot'></div>
        <div className='timeline-tooltip'>
          <span className='timeline-date'>{formatDate(originalEnd)}</span>
          <span className='timeline-title'>PoP End</span>
        </div>
        <div className='timeline-label'>PoP End</div>
      </div>
    </div>
  );
};

export default Timeline;
