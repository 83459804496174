import { apiSlice } from "app/api";

const organizationsSlice = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getOrganization: build.query({
			query: (OrgId) => `company/${OrgId}`,
			providesTags: ["Organization"],
		}),
		updateOrganization: build.mutation({
			query: ({ OrgId, body }) => ({
			  url: `/company/${OrgId}`,
			  method: "PATCH",
			  body,
			}),
			invalidatesTags: ["Organization"],
		  }),		  
	}),
	overrideExisting: false,
});

export const {
	useGetOrganizationQuery, useUpdateOrganizationMutation
} = organizationsSlice;
