import { useLocation } from "react-router-dom";
import { ProjectBreadCrumb, ProjectDetail } from "features/projects";
import { Task } from "common";
import {
  useUpdateTaskMutation,
  useGetProjectTasksQuery,
} from "features/tasks/tasksSlice";
import {
  useGetCommentsQuery,
  useAddCommentsMutation,
} from "common/comments/commentSlice";
import { Comment } from "common";
import { store } from "app/store";
import { skipToken } from "@reduxjs/toolkit/query";

function Project() {
  let tasks = [];
  const location = useLocation();
  const { projectId } = (location.state && location.state) || {};
  const numIncompleteTask = 5;
  const userId = store.getState().auth.userData.id;
  let projectComments = [];
  let type = "project";
  let parentId = projectId;

  const [updateTask] = useUpdateTaskMutation({
    refetchOnMountOrArgChange: true,
  });

  const { data, isSuccess } = useGetProjectTasksQuery(
    projectId ? projectId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (isSuccess) {
    tasks = data.data;
  }
  const { data: projectCommentData, isSuccess: isCommentSuccess } =
    useGetCommentsQuery(projectId ? { type, parentId } : skipToken, {
      refetchOnMountOrArgChange: true,
    });

  if (isCommentSuccess && projectCommentData.comments) {
    projectComments = projectCommentData.comments;
  }
  const [addProjectComments] = useAddCommentsMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleCommentSubmit = async (formData) => {
    formData.append("comment_type_id", parentId);
    formData.append("user_id", userId);
    formData.append("type", type);
    await addProjectComments(formData);
  };

  return (
    <>
      <div className='container g-4'>
        <div className='row'>
          <ProjectBreadCrumb />
        </div>
        <div id='project-detail' className='row'>
          <ProjectDetail />
          <div className='col-8'>
            <div className='row mb-4'>
              <div className='col'>
                <div id='project-tasks' className='card h-100'>
                  <div className='card-header d-flex justify-content-between'>
                    <h1 className='incompleteTask'>Tasks</h1>
                    <div className='btn-group' role='group'>
                      <div className='dropdown'>
                        <button
                          className='option-btn'
                          data-bs-toggle='dropdown'
                          type='button'
                        >
                          <i
                            className='bi bi-three-dots ms-2'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Options'
                          ></i>
                        </button>
                        <ul className='dropdown-menu options'>
                          <li>
                            <a className='dropdown-item' href='/'>
                              Filter
                            </a>
                          </li>
                          <li>
                            <a className='dropdown-item' href='/'>
                              Taskboard
                            </a>
                          </li>
                          <hr className='dropdown-divider'></hr>
                          <li>
                            <a className='dropdown-item' href='/'>
                              Style
                            </a>
                          </li>
                          <li>
                            <a className='dropdown-item' href='/'>
                              Reset
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    <Task
                      tasks={tasks}
                      completedSection={true}
                      updateTask={updateTask}
                      numIncompleteTask={numIncompleteTask}
                      breadcrumb='project'
                    />
                  </div>
                </div>
              </div>
            </div>
            <Comment
              comments={projectComments}
              handleCommentSubmit={handleCommentSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Project;
