import { Table } from "common";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import FormHandler from "../common/formHandler/FormHandler";
import { useDeleteTaskMutation } from "features/tasks/tasksSlice";
import {
  useGetConsultantTasksQuery,
  useGetAccountTasksQuery,
} from "features/tasks/tasksSlice";
import { store } from "app/store";
import { createColumnHelper } from "@tanstack/react-table";
import { useGetUserListQuery } from "features/user/userSlice";
import { formatDate } from "common/utils";
import { toast } from "react-toastify";

function Tasks() {
  let userList = [];
  let taskListByConsultant = [];
  let taskListByAccount = [];
  let consultantId;
  let accountId;
  const [model, setModel] = useState(false);
  const [taskId, setTaskId] = useState();
  const [deleteTaskName, setDeleteTaskName] = useState("");
  const [viewAll, setviewAll] = useState("MINE");
  const DELETE_DIALOG_TITLE = "Tasks";
  const DELETE_DIALOG_MESSAGE = `Are you sure you want to delete "${deleteTaskName}" this Task?`;

  if (store.getState().auth.userData)
    consultantId = store.getState().auth.userData.id;
  if (store.getState().auth.userData === null) accountId = 0;
  else accountId = store.getState().auth.userData.organization;

  const { data: taskByAccountInfo, isSuccess: isTaskByAccountSuccess } =
    useGetAccountTasksQuery(accountId, {
      refetchOnMountOrArgChange: true,
    });
  if (isTaskByAccountSuccess) {
    taskListByAccount = taskByAccountInfo.data;
  }

  const { data: taskByConsultantInfo, isSuccess: isTaskByConsultantSuccess } =
    useGetConsultantTasksQuery(consultantId, {
      refetchOnMountOrArgChange: true,
    });
  if (isTaskByConsultantSuccess) {
    taskListByConsultant = taskByConsultantInfo.data;
  }

  const [deleteTask] = useDeleteTaskMutation();

  const handleUserList = useGetUserListQuery({
    refetchOnMountOrArgChange: true,
  });

  if (handleUserList.isSuccess === true) {
    userList = handleUserList.data.data;
  }

  const deleteDialog = (taskId, taskName) => {
    setTaskId(taskId);
    setDeleteTaskName(taskName);
    return setModel(true);
  };

  const hideDialog = () => {
    setModel(false);
  };

  const deleteHandle = async () => {
    if (taskId) {
      const deleted = await deleteTask(taskId);
      if (deleted?.data?.status === "success")
        toast.success(`${deleteTaskName}  Deleted successfully.`);
      setTaskId(null);
      setDeleteTaskName("");
    }

    setModel(false);
  };

  const formatOwnerConsultant = (id) => {
    if (id) {
      const consultant = userList.find((user) => user.id === id);
      return consultant
        ? `${consultant.first_name} ${consultant.last_name}`
        : "";
    }
    return "";
  };

  let defaultData = [];

  if (viewAll === "ALL") {
    defaultData = taskListByAccount || [];
  }
  if (viewAll === "MINE") {
    defaultData = taskListByConsultant || [];
  }
  const columnHelper = createColumnHelper();

  const columnsAll = [
    columnHelper.accessor((row) => row.title, {
      id: "title",
      cell: (info) => (
        <Link
          to={`/tasks/${info.row.original.id}`}
          state={{ taskId: `${info.row.original.id}` }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor((row) => row.stage, {
      id: "stage",
      cell: (info) => (
        <>
          {info.row.original.stage === "Incomplete" ? (
            <span className='badge text-bg-danger'>
              {info.row.original.stage}
            </span>
          ) : (
            <span className='badge text-bg-success'>
              {info.row.original.stage}
            </span>
          )}
        </>
      ),
      header: () => <span>Stage</span>,
    }),
    columnHelper.accessor((row) => row.end_date, {
      id: "end_date",
      cell: (info) => formatDate(info.getValue()),
      header: () => <span>End Date</span>,
    }),

    columnHelper.accessor((row) => row.owner_consultant, {
      id: "owner_consultant",
      cell: (info) => formatOwnerConsultant(info.getValue()),
      header: () => <span>Owner Consultant</span>,
      show: viewAll === "ALL",
    }),

    columnHelper.accessor((row) => row.project_title, {
      id: "project_title",
      cell: (info) => (
        <Link
          to={`/projects/${info.row.original.project}`}
          state={{ projectId: info.row.original.project }}
          style={{ textDecoration: "none" }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Belongs To</span>,
    }),

    {
      id: "actions",
      cell: (info) => (
        <div>
          <span className='action-item'>
            <FormHandler
              formInputs={{
                formType: "Task",
                formAction: "Edit",
                itemDetails: {
                  id: info.row.original.id,
                },
              }}
            />
          </span>
          <span className='action-item'>
            <i
              className='bi bi-trash-fill'
              data-bs-placment='center'
              title='Delete'
              onClick={() =>
                deleteDialog(info.row.original.id, info.row.original.title)
              }
            ></i>
          </span>
        </div>
      ),
    },
  ];

  const columnsMine = [
    columnHelper.accessor((row) => row.title, {
      id: "title",
      cell: (info) => (
        <Link
          to={`/tasks/${info.row.original.id}`}
          state={{ taskId: `${info.row.original.id}` }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor((row) => row.stage, {
      id: "stage",
      cell: (info) => (
        <>
          {info.row.original.stage === "Incomplete" ? (
            <span className='badge text-bg-danger'>
              {info.row.original.stage}
            </span>
          ) : (
            <span className='badge text-bg-success'>
              {info.row.original.stage}
            </span>
          )}
        </>
      ),
      header: () => <span>Stage</span>,
    }),
    columnHelper.accessor((row) => row.end_date, {
      id: "end_date",
      cell: (info) => formatDate(info.getValue()),
      header: () => <span>End Date</span>,
    }),

    columnHelper.accessor((row) => row.project_title, {
      id: "project_title",
      cell: (info) => (
        <Link
          to={`/projects/${info.row.original.project}`}
          state={{ projectId: info.row.original.project }}
          style={{ textDecoration: "none" }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Belongs To</span>,
    }),

    {
      id: "actions",
      cell: (info) => (
        <div>
          <span className='action-item'>
            <FormHandler
              formInputs={{
                formType: "Task",
                formAction: "Edit",
                itemDetails: {
                  id: info.row.original.id,
                },
              }}
            />
          </span>
          <span className='action-item'>
            <i
              className='bi bi-trash-fill'
              data-bs-placment='center'
              title='Delete'
              onClick={() =>
                deleteDialog(info.row.original.id, info.row.original.title)
              }
            ></i>
          </span>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <h1 style={{ margin: "0" }}>Tasks</h1>
        </div>
        <div className='col-1 mt-2'>
          <Form>
            <Form.Check
              type='radio'
              id='custom-switch'
              label='View all'
              checked={viewAll === "ALL"}
              onChange={() => {
                setviewAll("ALL");
              }}
            />
          </Form>
        </div>
        <div className='col-2 mt-2'>
          <Form>
            <Form.Check
              type='radio'
              id='custom-switch'
              label='View mine'
              checked={viewAll === "MINE"}
              onChange={() => {
                setviewAll("MINE");
              }}
            />
          </Form>
        </div>
        <div className='col text-end pr-5'>
          <FormHandler
            formInputs={{
              formType: "Task",
              formAction: "Add",
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          {viewAll === "ALL" ? (
            <Table
              defaultData={defaultData}
              columns={columnsAll}
              DELETE_DIALOG_TITLE={DELETE_DIALOG_TITLE}
              DELETE_DIALOG_MESSAGE={DELETE_DIALOG_MESSAGE}
              model={model}
              hideDialog={hideDialog}
              onDelete={deleteHandle}
            />
          ) : (
            <Table
              defaultData={defaultData}
              columns={columnsMine}
              DELETE_DIALOG_TITLE={DELETE_DIALOG_TITLE}
              DELETE_DIALOG_MESSAGE={DELETE_DIALOG_MESSAGE}
              model={model}
              hideDialog={hideDialog}
              onDelete={deleteHandle}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Tasks;
