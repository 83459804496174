import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const countryApiSlice = createApi({
  reducerPath: "countryApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: "https://restcountries.com/v3.1/" }),
  endpoints: (builder) => ({
    getCountries: builder.query({ query: () => "all?fields=name" }),
    
  }),
});
export const { useGetCountriesQuery } = countryApiSlice;
