import Avatar from "react-avatar";
import { useGetSocialLinksQuery } from "features/social-links/socialLinksSlice";


function Member({ first_name, last_name, title, socialLinkId }) {
  let socialData = [];
  const { data: socialInfo, isSuccess: isSocialSuccess } = useGetSocialLinksQuery(
    socialLinkId,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (isSocialSuccess) {
    socialData = socialInfo;
  }
  return (
    <div className='card team-card shadow'>
      <div className='card-body '>
        <Avatar name={`${first_name} ${last_name}`} size='100' round={true} />
        <div className='member-name mt-2'>
          <span>{first_name}<br/></span>
          <span>{last_name}</span>
        </div>
        <p className='member-title'>{title}</p>
      </div>
      <div className='social-icons'>
        {socialData?.linkedin && (
          <a href={socialData.linkedin} className='social-link'>
            <i className='bi bi-linkedin active'></i>
          </a>
        )}
        {socialData?.instagram && (
          <a href={socialData.instagram} className='social-link'>
            <i className='bi bi-instagram active'></i>
          </a>
        )}
        {socialData?.facebook && (
          <a href={socialData.facebook} className='social-link'>
            <i className='bi bi-facebook active'></i>
          </a>
        )}
      </div>
    </div>
  );
};

export default Member;
