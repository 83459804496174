import {
  useGetImpactDataPointsByImpactIdQuery,
  useGetImpactQuery,
} from "features/impacts/impactsSlice";
import { Chart } from "features/impacts";
import { skipToken } from "@reduxjs/toolkit/query";

function BarGraph({ impactId }) {
  let impactData = [];
  let cumulativeValue = [];
  let overallTargetValue = [];
  let labels = [];
  let impactDataPointsData = [];

  const { data: impactDetails, isSuccess: isImpactDetailsSuccess } =
    useGetImpactQuery(impactId, {
      refetchOnMountOrArgChange: true,
    });

  if (isImpactDetailsSuccess && impactDetails.data) {
    impactData = impactDetails.data;
  }

  const { data, isSuccess } = useGetImpactDataPointsByImpactIdQuery(
    impactId ? impactId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const generateDataSet = () => {
    cumulativeValue = [];
    overallTargetValue = [];
    labels = [];

    const lastThreeDataPoints = impactDataPointsData.slice(-3);
    const sortedData = lastThreeDataPoints.sort((a, d) => d.id - a.id);
    sortedData.forEach((dataPoint, index) => {
      cumulativeValue[index] = parseFloat(dataPoint.cumulative_value);
      overallTargetValue[index] = parseFloat(impactData.overall_target_value);
      labels[index] = dataPoint.title;
    });

    return [cumulativeValue, overallTargetValue, labels];
  };

  if (isSuccess) {
    impactDataPointsData = [...data.data].sort((a, b) => a.id - b.id);
    if (impactDataPointsData.length > 0) {
      generateDataSet();
    }
  } else {
    console.log("Data fetch was unsuccessful or skipped.");
  }

  let title = impactData.title;

  return (
    <>
      <div className='container g-4'>
        <div className='row pb-2'>
          {impactDataPointsData.length > 0 ? (
            <Chart
              key={impactId}
              labels={labels}
              data1={cumulativeValue}
              data2={overallTargetValue}
              title={title}
            />
          ) : (
            <div>Please add data points to view the graph for impact <strong>{title}</strong></div>
          )}
        </div>
      </div>
    </>
  );
}

export default BarGraph;
