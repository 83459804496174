import { useDispatch } from "react-redux";
import { logOut } from "features/auth/authSlice";
import { useEffect, useState } from "react";

const ErrorHandler = ({ error, onClose }) => {
  const [showToast, setShowToast] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowToast(false);
      onClose();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  function getErrorMessage(errorResponse) {
    console.log("Error Response:", errorResponse); // Debugging line

    // Remove this conditional later if the APIs are updated to use objects
    if (typeof errorResponse === "string") {
      return errorResponse;
    }

    // This will probably need to be refactored when endpoints are updated
    if (errorResponse && typeof errorResponse === "object") {
      return (
        errorResponse.data?.message?.title ||
        errorResponse.data?.message?.name ||
        errorResponse.data?.message ||
        "An error has occurred."
      );
    }

    // Fallback for unexpected structure
    return "An unexpected error occurred.";
  }

  // handle status-specific messages or side effects
  let errorMessage = getErrorMessage(error);

  useEffect(() => {
    if (error?.error?.status === 401) {
      dispatch(logOut());
    }
  }, [error, dispatch]);

  // these need to be tested against each API
  switch (error?.error?.status) {
    case 401:
      errorMessage = "Unauthorized! Please log in and try again.";
      break;
    case 403:
      errorMessage = "You don't have permission to perform this action";
      break;
    case 404:
      errorMessage = "The requested resource cannot be found";
      break;
    case 500:
      errorMessage = "An internal server error has occurred. Please try again!";
      break;
    default:
      break; // keep original error message
  }

  return (
    <>
      {showToast && (
        <div
          className='toast show position-fixed top-0 start-50 translate-middle-x'
          role='alert'
          aria-live='assertive'
          aria-atomic='true'
          style={{ zIndex: 1100 }}
        >
          <div className='toast-header custom-toast-header'>
            <strong className='error-text'>Error</strong>
            <button
              type='button'
              className='close'
              data-dismiss='toast'
              aria-label='Close'
              onClick={() => {
                setShowToast(false);
                onClose();
              }}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='toast-body'>{errorMessage}</div>
        </div>
      )}
    </>
  );
};

export default ErrorHandler;
