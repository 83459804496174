import { createSlice } from "@reduxjs/toolkit";

// Initialize state from localStorage or use default values
const initialAuthState = JSON.parse(localStorage.getItem("auth")) || {
  message: null,
  token: null,
  userData: null,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setCredentials: (state, action) => {
      const { message, token, data } = action.payload;
      state.message = message;
      state.token = token;
      state.userData = data;
      // Save to localStorage
      localStorage.setItem("auth", JSON.stringify({ token, userData: data }));
    },
    logOut: (state) => {
      state.message = null;
      state.token = null;
      state.userData = null;
      // Remove from localStorage
      localStorage.removeItem("auth");
      localStorage.removeItem("persist:counter");
    },
    onboardUser: (state, { payload: { message, token, data } }) => {
      state.message = message;
      state.token = token;
      state.userData = data;
      // Save to localStorage
      localStorage.setItem("auth", JSON.stringify({ token, userData: data }));
    },
  },
});

// Action creators will be generated for each reducer function
export const { setCredentials, logOut, onboardUser } = AuthSlice.actions;

// Selector for accessing auth data in components
export const authData = (state) => state.auth;

// Export reducer
export default AuthSlice.reducer;
