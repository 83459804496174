import { useGetImpactDataPointsByImpactIdQuery } from "./impactsSlice";
import { Button } from "react-bootstrap";
import { useState } from "react";
import AddEditModal from "./AddEditModal";

const ImpactDataPoints = ({ impactId, impactData }) => {
  let impactDataPoints = [];
  let dpcount = 0;
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const { data, isSuccess } = useGetImpactDataPointsByImpactIdQuery(impactId, {
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess && data?.data) {
    impactDataPoints = [...data.data].sort((a, b) => a.id - b.id);
    dpcount = data.data.length;
  }

  return (
    <div>
      <>
        <div>
          <p className='inventory-item'>
            <span className='fw-bold'>Overall Target:</span>{" "}
            {impactData.overall_target_value}
          </p>
          <p className='inventory-item'>
            <span className='fw-bold'>Target Direction:</span>{" "}
            {impactData.target_direction}
          </p>
          {isSuccess && dpcount > 0 && (
            <p className='inventory-item'>
              <span className='fw-bold'>Cumulative Value:</span>{" "}
              {impactDataPoints[dpcount - 1]?.cumulative_value}
            </p>
          )}
        </div>
        <hr className='my-2' />
        {isSuccess && dpcount > 0 ? (
          <>
            <div className='row d-flex justify-content-between mw-100'>
              <div className='col-8 fw-bold sm'>Data Points</div>
              <div className='col-4 fw-bold text-end'>Actual</div>
            </div>
            {impactDataPoints.map((dataPoint, idx) => {
              const displayTitle =
                impactData.tracking_frequency === "Monthly" ||
                impactData.tracking_frequency === "Quarterly"
                  ? `${dataPoint.title} ${dataPoint.year}`
                  : dataPoint.title;

              return (
                <div
                  className='row d-flex justify-content-between mw-100'
                  key={idx}
                >
                  <div className='col-8 inventory-item'>{displayTitle}</div>
                  <div className='col-4 inventory-item text-end'>
                    {dataPoint.actual_value}
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <p>No data available</p>
        )}
      </>
      <div className='mt-2'>
        <Button variant='primary' className='btn-sm me-2' onClick={handleShow}>
          <i className='bi bi-plus-circle-fill'></i>
          <span className='ms-2'>Add/Edit Data</span>
        </Button>
      </div>
      <AddEditModal
        impactId={impactId}
        show={showModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default ImpactDataPoints;
