import { Table } from "common";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import FormHandler from "../common/formHandler/FormHandler";
import {
  useGetConsultantClientsQuery,
  useDeleteClientMutation,
  useGetVendorClientsQuery,
} from "features/clients/clientSlice";
import { store } from "app/store";
import { createColumnHelper } from "@tanstack/react-table";
import { toast } from "react-toastify";

function Clients() {
  let clientLists;
  let consultantId;
  let clientListByAccount;
  let accountId;
  const [model, setModel] = useState(false);
  const [clientId, setClientId] = useState();
  const [viewAll, setviewAll] = useState("MINE");
  const DELETE_DIALOG_TITLE = "Clients";
  const DELETE_DIALOG_MESSAGE = `Are you sure you want to delete this client?`;

  if (store.getState().auth.userData)
    consultantId = store.getState().auth.userData.id;
  if (store.getState().auth.userData === null) accountId = 0;
  else accountId = store.getState().auth.userData.organization;

  const { data: clientListData, isSuccess: clientListSuccess } =
    useGetConsultantClientsQuery(consultantId, {
      refetchOnMountOrArgChange: true,
    });

  const { data: accountClientListData, isSuccess: accountClientListSuccess } =
    useGetVendorClientsQuery(accountId, {
      skip: viewAll !== "ALL",
      refetchOnMountOrArgChange: true,
    });

  const [deleteClient] = useDeleteClientMutation();

  if (clientListSuccess && clientListData.data) {
    clientLists = clientListData.data;
  }

  if (accountClientListSuccess && accountClientListData.data) {
    clientListByAccount = accountClientListData.data;
  }

  const deleteDialog = (clientId) => {
    setClientId(clientId);
    return setModel(true);
  };

  const hideDialog = () => {
    setModel(false);
  };

  const deleteHandle = async () => {
    if (clientId) {
      const deleted = await deleteClient(clientId);
      if (deleted?.data?.status === "success")
        toast.success("Client deleted successfully.");
      setClientId(null);
    }
    setModel(false);
  };

  let defaultData = [];

  if (viewAll === "ALL") {
    defaultData = clientListByAccount || [];
  }
  if (viewAll === "MINE") {
    defaultData = clientLists || [];
  }
  const columnHelper = createColumnHelper();

  const columnsAll = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      cell: (info) => (
        <Link
          to={`/clients/${info.row.original.id}`}
          state={{ clientId: `${info.row.original.id}` }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Client Name</span>,
    }),
    columnHelper.accessor((row) => row.account_type, {
      id: "account_type",
      cell: (info) => info.getValue(),
      header: () => <span>Type</span>,
    }),
    columnHelper.accessor((row) => row.phoneNumber, {
      id: "phoneNumber",
      cell: (info) => info.getValue(),
      header: () => <span>Phone Number</span>,
    }),
    columnHelper.accessor((row) => row.website, {
      id: "website",
      cell: (info) => info.getValue(),
      header: () => <span>Website</span>,
    }),

    {
      id: "actions",
      cell: (info) => (
        <div>
          <span className='action-item'>
            <FormHandler
              formInputs={{
                formType: "Client",
                formAction: "Edit",
                itemDetails: {
                  id: info.row.original.id,
                },
              }}
            />
          </span>
          <span className='action-item'>
            <i
              className='bi bi-trash-fill'
              data-bs-placment='center'
              title='Delete'
              onClick={() => deleteDialog(info.row.original.id)}
            ></i>
          </span>
        </div>
      ),
    },
  ];

  const columnsMine = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      cell: (info) => (
        <Link
          to={`/clients/${info.row.original.id}`}
          state={{ clientId: `${info.row.original.id}` }}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => <span>Client Name</span>,
    }),
    columnHelper.accessor((row) => row.account_type, {
      id: "account_type",
      cell: (info) => info.getValue(),
      header: () => <span>Type</span>,
    }),
    columnHelper.accessor((row) => row.phoneNumber, {
      id: "phoneNumber",
      cell: (info) => info.getValue(),
      header: () => <span>Phone Number</span>,
    }),
    columnHelper.accessor((row) => row.website, {
      id: "website",
      cell: (info) => info.getValue(),
      header: () => <span>Website</span>,
    }),

    {
      id: "actions",
      cell: (info) => (
        <div>
          <span className='action-item'>
            <FormHandler
              formInputs={{
                formType: "Client",
                formAction: "Edit",
                itemDetails: {
                  id: info.row.original.id,
                },
              }}
            />
          </span>
          <span className='action-item'>
            <i
              className='bi bi-trash-fill'
              data-bs-placment='center'
              title='Delete'
              onClick={() => deleteDialog(info.row.original.id)}
            ></i>
          </span>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <h1 style={{ margin: "0", textAlign: "left" }}>Clients</h1>
        </div>
        <div className='col-1 mt-2'>
          <Form>
            <Form.Check
              type='radio'
              id='custom-switch'
              label='View all'
              checked={viewAll === "ALL"}
              onChange={() => {
                setviewAll("ALL");
              }}
            />
          </Form>
        </div>
        <div className='col-2 mt-2'>
          <Form>
            <Form.Check
              type='radio'
              id='custom-switch'
              label='View mine'
              checked={viewAll === "MINE"}
              onChange={() => {
                setviewAll("MINE");
              }}
            />
          </Form>
        </div>
        <div className='col text-end pr-5'>
          <FormHandler
            formInputs={{
              formType: "Client",
              formAction: "Add",
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          {viewAll === "ALL" ? (
            <Table
              defaultData={defaultData}
              columns={columnsAll}
              DELETE_DIALOG_TITLE={DELETE_DIALOG_TITLE}
              DELETE_DIALOG_MESSAGE={DELETE_DIALOG_MESSAGE}
              model={model}
              hideDialog={hideDialog}
              onDelete={deleteHandle}
            />
          ) : (
            <Table
              defaultData={defaultData}
              columns={columnsMine}
              DELETE_DIALOG_TITLE={DELETE_DIALOG_TITLE}
              DELETE_DIALOG_MESSAGE={DELETE_DIALOG_MESSAGE}
              model={model}
              hideDialog={hideDialog}
              onDelete={deleteHandle}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Clients;
