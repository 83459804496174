import { searchCategories } from "common/utils";
import { useGetGoalsFilterMutation } from "features/goals/goalsSlice";
import { useGetImpactsFilterMutation } from "features/impacts/impactsSlice";
import { useGetInitiativesFilterMutation } from "features/initiatives/initiativesSlice";
import { useGetProjectFilterMutation } from "features/projects/projectsSlice";
import { useGetTaskFilterMutation } from "features/tasks/tasksSlice";
import { useEffect, useRef, useState } from "react";
import { BsBullseye, BsSearch } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";

const DEFAULT_SEARCH_TYPE = "goals";

const getSearchPath = ({ searchType, pathName }) => {
  return searchType || pathName?.pathname?.split("/")[1] || DEFAULT_SEARCH_TYPE;
};

function SearchBar() {
  const pathName = useLocation();
  const [searchType, setSearchType] = useState(DEFAULT_SEARCH_TYPE);
  const [isFocused, setIsFocused] = useState(false);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const debounceTimeout = useRef(null);
  const containerRef = useRef(null);

  const [getGoals, { data: GoalsResults }] = useGetGoalsFilterMutation();
  const [getInitiatives, { data: InitiativesResults }] =
    useGetInitiativesFilterMutation();

  const [getImpacts, { data: ImpactsResults }] = useGetImpactsFilterMutation();
  const [getProjects, { data: ProjectsResults }] =
    useGetProjectFilterMutation();
  const [getTasks, { data: TasksResults }] = useGetTaskFilterMutation();

  const locationState = (id) => {
    const stateMapping = {
      goals: { goalId: id },
      initiatives: { initiativeId: id },
      projects: { projectId: id },
      tasks: { taskId: id },
      clients: { clientId: id },
    };
    return stateMapping[searchType] || { goalId: id };
  };

  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearch(input);

    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      if (input.trim() !== "") {
        if (searchType === "goals") {
          getGoals({ search: input });
        } else if (searchType === "initiatives") {
          getInitiatives({ search: input });
        } else if (searchType === "impacts") {
          getImpacts({ search: input });
        } else if (searchType === "projects") {
          getProjects({ search: input });
        } else if (searchType === "tasks") {
          getTasks({ search: input });
        }
      }
    }, 300);
  };

  useEffect(() => {
    if (searchType === "goals" && GoalsResults) {
      setSearchResults(GoalsResults);
    } else if (searchType === "initiatives" && InitiativesResults) {
      setSearchResults(InitiativesResults);
    } else if (searchType === "impacts" && ImpactsResults) {
      setSearchResults(ImpactsResults);
    } else if (searchType === "projects" && ProjectsResults) {
      setSearchResults(ProjectsResults);
    } else if (searchType === "tasks" && TasksResults) {
      setSearchResults(TasksResults);
    }
  }, [
    GoalsResults,
    ImpactsResults,
    ProjectsResults,
    InitiativesResults,
    TasksResults,
    searchType,
  ]);

  const updateRecentSearches = (clickedSearch) => {
    setRecentSearches((prev) => {
      const prevSearches = prev?.data || [];

      const updatedSearches = [
        clickedSearch,
        ...prevSearches.filter(
          (item) => item.result?.title !== clickedSearch?.result?.title, // Remove duplicates
        ),
      ];

      // Keep only the most recent 3 searches
      return {
        data: updatedSearches.slice(0, 3),
        searchType: clickedSearch?.searchType,
      };
    });
  };

  const handleRecentSearchClick = (hit) => {
    setIsFocused(false);
    updateRecentSearches(hit);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const searchPathName = getSearchPath({ searchType, pathName });
    setSearchType(searchPathName);
  }, [pathName, searchType]);

  const Hit = ({ hit }) => {
    return (
      <div>
        <Link
          to={`${window.location.origin}/${hit?.searchType || searchType}/${hit?.result?.id}`}
          state={locationState(hit?.result?.id)}
          onClick={() => handleRecentSearchClick(hit)}
        >
          <div className='wrape-content'>
            <i>
              <BsBullseye />
            </i>
            <div>
              <h3>{hit?.result?.title}</h3>
              <span>
                {hit?.result?.description && `${hit?.result?.description}`}
              </span>
            </div>
          </div>
        </Link>
      </div>
    );
  };
  return (
    <div
      className='input-group header-search'
      style={{
        maxWidth: "50rem",
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        width: "100%",
        top: "13px",
      }}
    >
      <select
        id='impact-select'
        className='form-select form-select-md'
        value={searchType}
        onChange={(e) => setSearchType(e.target.value)}
      >
        {searchCategories?.map((element, index) => (
          <option key={index} value={element.id}>
            {element.title}
          </option>
        ))}
      </select>

      <div className='ais-InstantSearch'>
        <div className='ais-SearchBox'>
          <form className='ais-SearchBox-form'>
            <input
              className='ais-SearchBox-input'
              aria-label='Search'
              autoComplete='off'
              placeholder='Search'
              value={search}
              type='search'
              onChange={handleSearchChange}
              onClick={() => setIsFocused(true)}
            />
            {isFocused && (
              <div className='search-content' ref={containerRef}>
                {search.trim() === "" ? (
                  recentSearches?.data?.length > 0 ? (
                    <div style={{ marginLeft: "10px" }}>
                      <h3 style={{ margin: "10px", fontSize: "20px" }}>
                        Recent Searches:
                      </h3>
                      {recentSearches?.data?.map((result, index) => (
                        <div key={index} className='content-wrap'>
                          <Hit
                            hit={{
                              result: result?.result,
                              searchType: result?.searchType,
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className='content-wrap'>
                      <h3>No recent searches</h3>
                    </div>
                  )
                ) : searchResults?.data?.length > 0 ? (
                  searchResults?.data?.map((result, index) => (
                    <div key={index} className='content-wrap'>
                      <Hit hit={{ result, searchType }} />
                    </div>
                  ))
                ) : (
                  <div className='content-wrap'>
                    <h3>No match found.</h3>
                  </div>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
      <button className='btn btn-primary' type='button'>
        <BsSearch className='search-icon' />
      </button>
    </div>
  );
}

export default SearchBar;
