import { skipToken } from "@reduxjs/toolkit/query";
import { Snapshot, parseResults } from "common";
import { useNavigate } from "react-router-dom";
import { useGetVendorTasksQuery, useGetGoalTasksQuery } from "./tasksSlice";

export const TASKTYPE = {
  GOAL: "GOAL",
  VENDOR: "VENDOR",
};

export const TaskByTypeSnapshot = ({ type, goalOrClientId }) => {
  let functionType;
  let navigationHandle = null;
  const navigate = useNavigate();

  const navigateToTableView = (event) => {
    navigate(`/tasks`);
  };

  if (type === TASKTYPE.VENDOR) {
    functionType = useGetVendorTasksQuery;
    navigationHandle = navigateToTableView;
  } else if (type === TASKTYPE.GOAL) {
    functionType = useGetGoalTasksQuery;
  } else {
    // type missing
  }

  let content = {
    labels: ["NO DATA"],
    data: [0.00001],
    quanity: 0,
  };

  const { data, isLoading, isError, isSuccess } = functionType(
    goalOrClientId ? goalOrClientId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (isLoading) {
    content = { data: [0.1], labels: ["Loading"] };
  } else if (isSuccess && !isError) {
    let results = parseResults(data.data);
    content.labels = results.map((a) => a.key);
    content.data = results.map((a) => a.value);
    content.quanity = content.data.reduce((a, b) => a + b, 0);
  }

  return (
    <>
      <Snapshot
        title='Tasks'
        quanity={content.quanity}
        data={content.data}
        labels={content.labels}
        onClick={navigationHandle}
      />
    </>
  );
};
