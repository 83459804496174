export function GetClassName(stage) {
  switch (stage) {
    case "Not-Started":
      return "danger text-bg-danger";
    case "In-Progress":
      return "warning text-bg-warning";
    case "Completed":
      return "success text-bg-success";
    default:
      return "secondary";
  }
}
