import { apiSlice } from "app/api";

const socialLinksSlice = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getSocialLinks: build.query({
			query: (socialId) => `social-links/${socialId}`,
			providesTags: ["Social"],
		}),
	}),
	overrideExisting: false,
});

export const {
	useGetSocialLinksQuery
} = socialLinksSlice;
