import Avatar from "react-avatar";
import { useState } from "react";
import { Member } from "features/user";
import {
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
} from "features/organization/organizationsSlice";
import { useGetSocialLinksQuery } from "features/social-links/socialLinksSlice";
import { useGetAllUsersQuery } from "features/user/userSlice";

function Organization() {
  let orgData = [];
  let socialData = [];
  let usersData = [];
  const orgId = ""; // hardcoding org_id as there is no login info and other data present. Please provide a company uuid.
  const [isEditing, setIsEditing] = useState(false);
  const [formState, setFormState] = useState({
    email: "",
    phone: "",
    companyName: "",
    address: "",
    city: "",
    state: "",
    zipcode: ""
  });

  function formatUrl(url) {
    if (!url) return "";
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  }

  const { data: orgInfo, isSuccess: isOrgSuccess } = useGetOrganizationQuery(
    orgId,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (isOrgSuccess) {
    orgData = orgInfo;
  }

  const { data: socialInfo, isSuccess: isSocialSuccess } =
    useGetSocialLinksQuery(orgData?.social_link_id, {
      refetchOnMountOrArgChange: true,
    });

  if (isSocialSuccess) {
    socialData = socialInfo;
  }

  const { data: usersInfo, isSuccess: isUsersSuccess } = useGetAllUsersQuery({
    refetchOnMountOrArgChange: true,
  });

  if (isUsersSuccess) {
    usersData = usersInfo || [];
  }

  const [updateOrganization] = useUpdateOrganizationMutation();

  const handleEditClick = () => {
    if (orgData) {
      setIsEditing(true);
      setFormState({
        email: orgData.email,
        phone: orgData.phone,
        companyName: orgData.company_name,
        address: orgData.address,
        city: orgData.city,
        state: orgData.state,
        zipcode: orgData.zip_code
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSaveClick = async () => {
    const formData = new FormData();
    for (const key in formState) {
      formData.append(key, formState[key]);
    }

    await updateOrganization({
      OrgId: orgId,
      body: formData,
    });

    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  return (
    <>
      <div className='container g-4'>
        <div className='row'>
          <div className='col-4'>
            <div className='card p-4' id='company-profile'>
              <div className='card-body d-flex align-items-center flex-column'>
                <div className='w-100 d-flex justify-content-end'>
                  {!isEditing && (
                    <i
                      className='bi bi-pencil-square'
                      onClick={handleEditClick}
                    ></i>
                  )}
                </div>
                {orgData.company_logo ? (
                  <Avatar
                    src={orgData.company_logo}
                    size='100'
                    round={true}
                    alt='Company Logo'
                  />
                ) : (
                  <div className='icon-placeholder'>
                    <i className='bi bi-buildings'></i>
                    <div className='overlay'>
                      <i className='bi bi-plus'></i>
                    </div>
                  </div>
                )}
                {isEditing ? (
                  <>
                    <div className='mt-3'>
                      <input
                        type='text'
                        name='companyName'
                        value={formState.companyName}
                        onChange={handleInputChange}
                        className='form-control mb-2'
                        placeholder='Company Name'
                      />
                      <input
                        type='email'
                        name='email'
                        value={formState.email}
                        onChange={handleInputChange}
                        className='form-control mb-2'
                        placeholder='Email'
                      />
                      <input
                        type='text'
                        name='phone'
                        value={formState.phone}
                        onChange={handleInputChange}
                        className='form-control mb-2'
                        placeholder='Phone Number'
                      />
                      <input
                        type='text'
                        name='address'
                        value={formState.address}
                        onChange={handleInputChange}
                        className='form-control mb-2'
                        placeholder='Address'
                      />
                      <input
                        type='text'
                        name='city'
                        value={formState.city}
                        onChange={handleInputChange}
                        className='form-control mb-2'
                        placeholder='City'
                      />
                      <input
                        type='text'
                        name='state'
                        value={formState.state}
                        onChange={handleInputChange}
                        className='form-control mb-2'
                        placeholder='State'
                      />
                      <input
                        type='text'
                        name='zipcode'
                        value={formState.zipcode}
                        onChange={handleInputChange}
                        className='form-control mb-2'
                        placeholder='Zip Code'
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <span className='company-display-name'>
                      {orgData.company_name}
                    </span>
                    <span className='company-email'>
                      Main email: {orgData.email}
                    </span>
                    <span className='company-contact'>
                      Phone Number: {orgData.phone}
                    </span>
                    <span className='company-address'>{orgData.address}</span>
                    <span className='company-city-state'>
                      {orgData.city && orgData.city}
                      {orgData.city &&
                        (orgData.state || orgData.zip_code) &&
                        ", "}
                      {orgData.state && orgData.state}
                      {orgData.state && orgData.zip_code && ", "}
                      {orgData.zip_code}
                    </span>
                  </>
                )}
                <div className='social-icons-group mt-4 mb-2'>
                  <a href={formatUrl(orgData.website_url)} className='mx-1'>
                    <i className='bi bi-globe active'></i>
                  </a>
                  <a href={formatUrl(socialData.linkedin)} className='mx-1'>
                    <i className='bi bi-linkedin active'></i>
                  </a>
                  <a href={formatUrl(socialData.instagram)} className='mx-1'>
                    <i className='bi bi-instagram active'></i>
                  </a>
                  <a href={formatUrl(socialData.facebook)} className='mx-1'>
                    <i className='bi bi-facebook active'></i>
                  </a>
                </div>
              </div>
              {isEditing && (
                <div className='d-flex justify-content-center mt-3'>
                  <button
                    onClick={handleSaveClick}
                    className='btn btn-primary me-2'
                  >
                    Save
                  </button>
                  <button
                    onClick={handleCancelClick}
                    className='btn btn-secondary'
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className='col-12 col-md-8'>
            <div className='card'>
              <div className='card-header team-header'>
                <i className='bi bi-people-fill pe-2'></i>Our Members
              </div>
              <div className='card-body team-body d-flex flex-wrap'>
                {usersData.map((member) => (
                  <Member
                    key={member.uuid}
                    first_name={member.first_name}
                    last_name={member.last_name}
                    title={member.title}
                    socialLinks={member.social_link_id}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Organization;
