import { apiSlice } from "app/api";

const initiativesSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getInitiative: build.query({
      query: (initiativeId) => `/initiatives/${initiativeId}`,
      providesTags: ["Initiative"],
    }),
    getConsultantInitiatives: build.query({
      query: (ownerConsultantId) =>
        `/initiatives/initiative_consultant/${ownerConsultantId}`,
      providesTags: ["Initiative"],
    }),
    getCustomerInitiatives: build.query({
      query: (ownerCustomerId) =>
        `/initiatives/initiative_customer/${ownerCustomerId}`,
      providesTags: ["Initiative"],
    }),
    getAccountInitiatives: build.query({
      query: (accountId) => `/initiatives/initiative_account/${accountId}`,
      providesTags: ["Initiative"],
    }),
    getVendorInitiatives: build.query({
      query: (vendorAccountId) =>
        `/initiatives/initiative_vendor/${vendorAccountId}`,
      providesTags: ["Initiative"],
    }),
    getGoalInitiatives: build.query({
      query: (goalId) => `/initiatives/initiative_goal/${goalId}`,
      providesTags: ["Initiative"],
    }),
    getInitiativesByStage: build.query({
      query: (userId) => `/intiatives/initiativeliststage/${userId}`,
      providesTags: ["Initiative"],
    }),
    deleteInitiative: build.mutation({
      query: (initiativeId) => ({
        url: `/initiatives/${initiativeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Initiative"],
    }),
    getInitiativeHierarchy: build.query({
      query: (initiativeId) => `/goals/hierarchybyinit/${initiativeId}`,
      providesTags: ["Initiative"],
    }),
    addInitiative: build.mutation({
      query: ({ ...body }) => ({
        url: `/initiatives/`,
        method: "POST",
        ...body,
      }),
      invalidatesTags: ["Initiative"],
    }),
    updateInitiative: build.mutation({
      query: ({ initiativeId, ...body }) => ({
        url: `/initiatives/${initiativeId}`,
        method: "PATCH",
        ...body,
      }),
      invalidatesTags: ["Initiative"],
    }),

    getInitiativeByAccount: build.mutation({
      query: (accountId) => ({
        url: `/initiatives/initiative_vendor/${accountId}`,
        method: "GET",
      }),
      providesTags: ["Initiative"],
    }),

    getInitiatives: build.mutation({
      query: (id) => {
        const url = id ? `/initiatives/${id}` : "/initiatives";
        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["Initiative"],
    }),

    getInitiativesFilter: build.mutation({
      query: ({ search = "" }) => {
        const params = new URLSearchParams();
        if (search) params.append("search", search);
        return {
          url: `/initiatives?${params.toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Initiative"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetInitiativeQuery,
  useGetConsultantInitiativesQuery,
  useGetCustomerInitiativesQuery,
  useGetAccountInitiativesQuery,
  useGetVendorInitiativesQuery,
  useGetInitiativesByStageQuery,
  useGetGoalInitiativesQuery,
  useDeleteInitiativeMutation,
  useGetInitiativeHierarchyQuery,
  useAddInitiativeMutation,
  useUpdateInitiativeMutation,
  useGetInitiativeByAccountMutation,
  useGetInitiativesMutation,
  useGetInitiativesFilterMutation,
} = initiativesSlice;
