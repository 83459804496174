import { apiSlice } from "app/api";

export const tasksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addTask: builder.mutation({
      query: ({ ...body }) => ({
        url: `/tasks/`,
        method: "POST",
        ...body,
      }),
      invalidatesTags: ["Task"],
    }),

    deleteTask: builder.mutation({
      query: (taskId) => ({
        url: `/tasks/${taskId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Task"],
    }),

    getCustomerTasks: builder.query({
      query: (customerId) => `/tasks/task_customer/${customerId}`,
      providesTags: ["Task"],
    }),
    getGoalTasks: builder.query({
      query: (goalId) => `/tasks/task_goal/${goalId}`,
      providesTags: ["Task"],
    }),
    getVendorTasks: builder.query({
      query: (vendorId) => `/tasks/task_vendor/${vendorId}`,
      providesTags: ["Task"],
    }),
    updateTask: builder.mutation({
      query: ({ taskId, ...body }) => ({
        url: `/tasks/${taskId}`,
        method: "PATCH",
        ...body,
      }),
      invalidatesTags: ["Task"],
    }),
    getTask: builder.query({
      query: (taskId) => `/tasks/${taskId}`,
      providesTags: ["Task"],
    }),
    getAccountTasks: builder.query({
      query: (accountId) => `/tasks/task_account/${accountId}`,
      providesTags: ["Task"],
    }),
    getMyTasks: builder.query({
      query: (userId) => `/tasks/assignedtasklist/${userId}`,
      providesTags: ["Task"],
    }),
    getInitiativeTasks: builder.query({
      query: (initiativeId) => `/tasks/task_initiative/${initiativeId}`,
      providesTags: ["Task"],
    }),
    getProjectTasks: builder.query({
      query: (projectId) => `/tasks/task_project/${projectId}`,
      providesTags: ["Task"],
    }),
    getConsultantTasks: builder.query({
      query: (consultantId) => `/tasks/task_consultant/${consultantId}`,
      providesTags: ["Task"],
    }),
    getTaskFilter: builder.mutation({
      query: ({ search = "" }) => {
        const params = new URLSearchParams();
        if (search) params.append("search", search);
        return {
          url: `/tasks?${params.toString()}`,
          method: "GET",
        };
      },
      invalidatesTags: ["Task"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useAddTaskMutation,
  useDeleteTaskMutation,
  useGetCustomerTasksQuery,
  useGetGoalTasksQuery,
  useGetVendorTasksQuery,
  useUpdateTaskMutation,
  useGetTaskQuery,
  useGetAccountTasksQuery,
  useGetMyTasksQuery,
  useGetProjectTasksQuery,
  useGetInitiativeTasksQuery,
  useGetConsultantTasksQuery,
  useGetTaskFilterMutation,
} = tasksApiSlice;
