import { apiSlice } from "app/api";

const goalsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    addGoal: build.mutation({
      query: (goal) => ({
        url: `/goals/`,
        method: "POST",
        body: goal,
      }),
      invalidatesTags: ["Goal"],
    }),
    updateGoal: build.mutation({
      query: ({ goalId, ...body }) => ({
        url: `/goals/${goalId}`,
        method: "PATCH",
        ...body,
      }),
      invalidatesTags: ["Goal"],
    }),
    deleteGoal: build.mutation({
      query: (goalId) => ({
        url: `/goals/${goalId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Goal"],
    }),
    getGoals: build.query({
      query: (goalId) => `/goals/${goalId}`,
      providesTags: ["Goal"],
    }),
    getGoalsFilter: build.mutation({
      query: ({ search = "" }) => {
        const params = new URLSearchParams();
        if (search) params.append("search", search);
        return {
          url: `/goals?${params.toString()}`,
          method: "GET",
        };
      },
      invalidatesTags: ["Goal"],
    }),

    getConsultantGoals: build.query({
      query: (ownerConsultantId) =>
        `/goals/goallist_consultant/${ownerConsultantId}`,
      providesTags: ["Goal"],
    }),
    getCustomerGoals: build.query({
      query: (ownerCustomerId) => `/goals/goallist_customer/${ownerCustomerId}`,
      providesTags: ["Goal"],
    }),
    getAccountGoals: build.query({
      query: (accountId) => `/goals/goallist_account/${accountId}`,
      providesTags: ["Goal"],
    }),
    getGoalsByVender: build.mutation({
      query: (accountId) => ({
        url: `/goals/goallist_vendor/${accountId}`,
        method: "GET",
      }),
      providesTags: ["Goal"],
    }),
    getVendorGoals: build.query({
      query: (vendorAccountId) => `/goals/goallist_vendor/${vendorAccountId}`,
      providesTags: ["Goal"],
    }),
    getGoalsByStage: build.query({
      query: (userId) => `/goals/goalstage/${userId}`,
      providesTags: ["Goal"],
    }),
    getGoalsHierarchy: build.query({
      query: (goalId) => `/goals/hierarchy/${goalId}`,
      providesTags: ["Goal"],
    }),
    getHierarchyByImpact: build.query({
      query: (impactId) => `/goals/hierarchybyimpact/${impactId}`,
      providesTags: ["Goal"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGoalsQuery,
  useGetConsultantGoalsQuery,
  useGetCustomerGoalsQuery,
  useGetAccountGoalsQuery,
  useGetVendorGoalsQuery,
  useGetGoalsByStateQuery,
  useAddGoalMutation,
  useUpdateGoalMutation,
  useDeleteGoalMutation,
  useGetGoalsHierarchyQuery,
  useGetHierarchyByImpactQuery,
  useGetGoalsByVenderMutation,
  useGetGoalsFilterMutation,
} = goalsSlice;
