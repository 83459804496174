import { store } from "app/store";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ClientDetail } from "features/clients";
import { useGetVendorImpactsQuery } from "features/impacts/impactsSlice";
import { Comment } from "common";
import { InitiativeDropDown } from "features/impacts";
import {
  useGetCommentsQuery,
  useAddCommentsMutation,
} from "common/comments/commentSlice";
import { useGetClientDetailsQuery } from "features/clients/clientSlice";
import { GoalByTypeSnapshot } from "features/goals";
import { InitiativeByTypeSnapshot } from "features/initiatives";
import { ProjectByTypeSnapshot } from "features/projects";
import { PROJECTTYPE } from "features/projects/ProjectByTypeSnapshot";

import { GOALTYPE } from "features/goals/GoalByTypeSnapshot";
import { INITIATIVETYPE } from "features/initiatives/InitiativeByTypeSnapshot";
import { TaskByTypeSnapshot } from "features/tasks";
import { TASKTYPE } from "features/tasks/TaskByTypeSnapshot";

function Client() {
  const location = useLocation();
  const userId = store.getState().auth.userData.id;
  let impactsByClientInfo = [];
  let clientInfo = [];
  const { clientId } = (location && location.state) || {};
  let accountComments = [];
  let type = "account";
  let parentId = clientId;

  const {
    data: clientDetailData,
    isSuccess: clientDetailSuccess,
    isError: clientDetailError,
  } = useGetClientDetailsQuery(clientId, {
    refetchOnMountOrArgChange: true,
  });

  const { data: impactsData, isSuccess: isImpactSuccess } =
    useGetVendorImpactsQuery(clientId, {
      refetchOnMountOrArgChange: true,
    });

  const { data: accountCommentData, isSuccess: isCommentSuccess } =
    useGetCommentsQuery(
      { type, parentId },
      {
        refetchOnMountOrArgChange: true,
      },
    );

  const [addAccountComments] = useAddCommentsMutation({
    refetchOnMountOrArgChange: true,
  });

  if (clientDetailSuccess) {
    clientInfo = clientDetailData.data;
  }

  if (clientDetailSuccess && !clientDetailData.data) {
    return null;
  }

  if (clientDetailError) {
    return null;
  }

  if (isImpactSuccess) {
    impactsByClientInfo = impactsData.data;
  }

  if (isCommentSuccess && accountCommentData.comments) {
    accountComments = accountCommentData.comments;
  }

  const handleCommentSubmit = async (formData) => {
    formData.append("comment_type_id", parentId);
    formData.append("user_id", userId);
    formData.append("type", type);
    await addAccountComments(formData);
  };

  const renderCrumbs = () => {
    return (
      <>
        <div className='row'>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to={`/`}>Dashboard</Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                Client Detail
              </li>
            </ol>
          </nav>
        </div>
      </>
    );
  };

  return (
    <>
      <div className='container g-4'>
        <div className='row align-items-center mb-3'>
          <div className='col'>{renderCrumbs()}</div>
          <div className='col text-end'>
            <Link
              to={`/client-brief/${clientId}`}
              state={{
                clientId: `${clientId}`,
              }}
              className='btn btn-primary'
            >
              Client Brief
            </Link>
          </div>
        </div>
        <div id='client-detail' className='row'>
          <div className='col-4'>
            <ClientDetail clientInfo={clientInfo} clientId={clientId} />
          </div>

          <div className='col-8'>
            <div className='row mb-4'>
              <div className='col-6'>
                <GoalByTypeSnapshot
                  type={GOALTYPE.VENDOR}
                  clientId={clientId}
                />
              </div>
              <div className='col-6'>
                <InitiativeByTypeSnapshot
                  id={clientId}
                  type={INITIATIVETYPE.VENDOR}
                />
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col-6'>
                {" "}
                <ProjectByTypeSnapshot
                  type={PROJECTTYPE.VENDOR}
                  goalOrInitOrClientId={clientId}
                />
              </div>
              <div className='col-6'>
                <TaskByTypeSnapshot
                  type={TASKTYPE.VENDOR}
                  goalOrClientId={clientId}
                />
              </div>
            </div>
            <div className='row mb-4'>
              <InitiativeDropDown impactsInfo={impactsByClientInfo} />
            </div>
            <Comment
              comments={accountComments}
              handleCommentSubmit={handleCommentSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Client;
