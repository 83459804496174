function ProjectItem({ title, stage, dueDate }) {
  const dateObj = new Date(dueDate);
  dateObj.setDate(dateObj.getDate() + 1);

  const formattedDate = `${(dateObj.getMonth() + 1).toString().padStart(2, "0")}/${dateObj.getDate().toString().padStart(2, "0")}/${dateObj.getFullYear()}`;

  return (
    <>
      <div className='list-group-item lgi-flush'>
        <i className='bi bi-briefcase'></i>
        <span className='list-project-title'>{title}</span>
        <div className='d-flex align-items-start'>
          <div className='flex-shrink-0 me-3'>
            {stage === "Not-Started" ? (
              <span className='badge bg-danger text-dark x-small'>{stage}</span>
            ) : stage === "In-Progress" ? (
              <span className='badge bg-warning text-dark x-small'>
                {stage}
              </span>
            ) : (
              <span className='badge bg-success text-dark x-small'>
                {stage}
              </span>
            )}
          </div>
          <div className='flex-grow-1'>
            <span className='text-muted small'>{formattedDate}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectItem;
