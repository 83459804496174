// Format Date in table layouts
export const formatDate = (inputDate) => {
  if (inputDate) {
    let [year, month, day] = inputDate.split("-").map(Number);
    let formatedDate = new Date(year, month - 1, day);
    return formatedDate.toDateString();
  }
};

// Format to mm/dd/yyyy
export const convertDate = (inputDate) => {
  if (!inputDate) return "Invalid date";
  const [year, month, day] = inputDate.split("-").map(Number);
  return `${month.toString().padStart(2, "0")}/${day.toString().padStart(2, "0")}/${year}`;
};

// Array of Year Options (1990 - 2029)
export const yearOptions = Array.from({ length: 40 }, (_, i) => 1990 + i);

// Array of Month Options
export const monthOptions = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const FORM_STAGES = [
  { title: "Not Started", value: "Not-Started" },
  { title: "In Progress", value: "In-Progress" },
  { title: "Completed", value: "Completed" },
];

export const FORM_STATUS = [
  { title: "Active", value: "Active" },
  { title: "Inactive", value: "In-Active" },
];

export const TASK_STAGES = [
  { title: "Incomplete", value: "Incomplete" },
  { title: "Completed", value: "Completed" },
];

export const getMonthName = (date) => {
  return monthOptions[new Date(date).getMonth()];
};

export const searchCategories = [
  { id: "goals", title: "Goals" },
  { id: "initiatives", title: "Initiatives" },
  { id: "impacts", title: "Impacts" },
  { id: "projects", title: "Projects" },
  { id: "tasks", title: "Tasks" },
];
