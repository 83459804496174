import loginbg from "features/ui/svgs/target-bkgd.svg";
import Onboarding from "features/user-onboarding/Onboarding";
function UserOnboarding() {
  return (
    <>
      <div
        id='login'
        style={{
          background: `url(${loginbg}) no-repeat center center`,
          backgroundColor: "lightslategray",
          backgroundSize: "cover",
        }}
      >
        <Onboarding />
      </div>
    </>
  );
}

export default UserOnboarding;
